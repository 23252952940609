import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Nav components

import Drawer from '../components/Drawer';

// Chart components

import TokensStaked from '../components/charts/TokensStaked';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
});

const map = {
  registry_delta: {
    name: 'Staked in Registry contract',
    color: '#50b7f0',
  },
  voting_delta: {
    name: 'Staked in Voting contract',
    color: '#FEBE6C',
  },
  signal_delta: {
    name: 'Staked in Signals contract',
    color: '#B1DFF9',
  },
};

class Tokens extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      tokensStakedData: [],
      tokensStakedPlotData: [],
      tokensStakedIsLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateTokenData();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  updateTokenData() {
    this.setState({
      tokensStakedIsLoading: true,
    });

    fetch(
      'https://api.blocklytics.org/foam/v0/stats/tokens/staked?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({
            tokensStakedData: data,
            tokensStakedIsLoading: false,
          });
        }
      });
  }

  plotDataFor(data, key) {
    var plotData = [];
    Object.entries(data).forEach((d, i) => {
      var startValue = i === 0 ? 0 : plotData[i - 1][1];
      var pushValue = startValue + parseFloat(d[1][key]);
      pushValue = pushValue === 0 ? null : pushValue;
      const datetime = new Date(d[1]['date']).getTime();
      plotData.push([datetime, pushValue]);
    });
    return plotData;
  }

  plotDataForSeries(data, map) {
    var seriesData = [];
    if (data.length === 0) {
      return seriesData;
    }

    Object.keys(data[0]).forEach((key, index) => {
      if (key !== 'date') {
        seriesData.push({
          name: map[key]['name'],
          type: 'area',
          data: this.plotDataFor(data, key),
          color: map[key]['color'],
        });
      }
    });
    return seriesData;
  }

  render() {
    const { classes } = this.props;

    const isLoading = this.state.tokensStakedIsLoading;
    const tokensStakedData = this.state.tokensStakedData;
    const tokensStakedPlotData = this.plotDataForSeries(tokensStakedData, map);
    const chartTitle = 'FOAM Token Staking';
    const chartSubtitle = 'Click and drag to zoom';

    return (
      <Drawer pageTitle="Tokens" selectedSection={2} selectedIndex={2}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <TokensStaked
                isLoading={isLoading}
                chartTitle={chartTitle}
                chartSubtitle={chartSubtitle}
                data={tokensStakedPlotData}
              />
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(Tokens));
