import { all, fork } from 'redux-saga/effects';

import blocklyticsApiSagas from 'core/blocklytics-api/sagas';
import contractSagas from 'core/contract/sagas';
import networkSagas from 'core/network/sagas';
import { drizzleSagas } from '@drizzle/store';

export default function* rootSaga() {
  //yield all([blocklyticsApiSagas, contractSagas, networkSagas, ...drizzleSagas].map(fork));

  const appSagas = [blocklyticsApiSagas, contractSagas, networkSagas];

  yield all([...appSagas].map(fork));
  yield all([...drizzleSagas].map(fork));
}
