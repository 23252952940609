import Onboard from 'bnc-onboard';
import Notify from 'bnc-notify';

const NETWORK_ID = 1;
const API_KEY = '4582cd22-a847-438b-bf8d-7abe563e4827';

export function initOnboard(subscriptions) {
  return Onboard({
    dappId: API_KEY,
    networkId: NETWORK_ID,
    subscriptions: subscriptions,
    walletSelect: {
      wallets: [
        {
          walletName: 'metamask',
        },
        {
          walletName: 'coinbase',
        },
        {
          walletName: 'trust',
        },
      ],
    },
  });
}

export function initNotify() {
  return Notify({
    dappId: API_KEY,
    networkId: NETWORK_ID,
    mobilePosition: 'bottom',
  });
}
