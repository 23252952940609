import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AddLocationIcon from '@material-ui/icons/AddLocation';
import ChallengeIcon from '@material-ui/icons/Announcement';
import BeenHereIcon from '@material-ui/icons/Beenhere';

import Drawer from '../components/Drawer';

const FOAM_COLOR_VERIFY = '#28AF60';
const FOAM_COLOR_PENDING = '#3082ED';
const FOAM_COLOR_CHALLENGE = '#F47F67';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  heroMedia: {
    width: '100%',
    height: '100%',
    minHeight: 145,
    textAlign: 'center',
  },
  heroIcon: {
    fontSize: 120,
    paddingTop: 24,
    color: '#FFFFFF',
  },
  paddingTop: {
    paddingTop: 90,
  },
  infoPanel: {
    padding: theme.spacing(2),
    width: '100%',
  },
  cardContentWrapper: {
    [theme.breakpoints.up('xs')]: {
      minHeight: 120,
    },
  },
});

const InfoCard = ({ classes, toPath, Icon, background, Content, Action }) => {
  return (
    <Grid item xs={12} sm={4}>
      <Card>
        <CardActionArea component={Link} to={{ pathname: toPath }}>
          <Grid
            container
            spacing={0}
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <CardMedia
                className={classes.heroMedia}
                style={{ background: background }}
              >
                {Icon}
              </CardMedia>
            </Grid>
            <Grid item xs={12}>
              <CardContent>
                <div className={classes.cardContentWrapper}>{Content}</div>
                {Action}
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

class Home extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Drawer pageTitle="Home" selectedSection={0} selectedIndex={0}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Explore and curate the FOAM Map
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="stretch"
            >
              <InfoCard
                classes={classes}
                toPath="/points"
                Icon={
                  <AddLocationIcon
                    color="primary"
                    className={classes.heroIcon}
                  />
                }
                background={FOAM_COLOR_PENDING}
                Content={
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      The FOAM Map is a list of crowdsourced places.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Anyone can add points to the list.
                    </Typography>
                  </React.Fragment>
                }
                Action={
                  <Button
                    color="primary"
                    component={Link}
                    to={{ pathname: '/points' }}
                  >
                    Explore all points
                  </Button>
                }
              />

              <InfoCard
                classes={classes}
                toPath="/challenges"
                Icon={<ChallengeIcon className={classes.heroIcon} />}
                background={FOAM_COLOR_CHALLENGE}
                Content={
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      The map is kept accurate and up-to-date through
                      challenges.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Anyone can attempt to remove a point from the list by
                      starting a challenge.
                    </Typography>
                  </React.Fragment>
                }
                Action={
                  <Button
                    color="primary"
                    component={Link}
                    to={{ pathname: '/challenges' }}
                  >
                    Explore recent challenges
                  </Button>
                }
              />

              <InfoCard
                classes={classes}
                toPath="/cartographers"
                Icon={
                  <BeenHereIcon color="primary" className={classes.heroIcon} />
                }
                background={FOAM_COLOR_VERIFY}
                Content={
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Challenges reward information verification.
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Anyone can earn FOAM tokens by participating on the map.
                    </Typography>
                  </React.Fragment>
                }
                Action={
                  <Button
                    color="primary"
                    component={Link}
                    to={{ pathname: '/cartographers' }}
                  >
                    Explore cartographer activity
                  </Button>
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Getting started
            </Typography>

            <Typography variant="body1" paragraph>
              FOAM Tools allow you to interact with the FOAM Map. You'll need a
              Web3 account and some FOAM tokens.
            </Typography>

            <Typography
              className="MuiLink-underlineHover"
              color="primary"
              variant="body1"
              component="a"
              href="https://metamask.io/"
              target="_blank"
              paragraph
              display="block"
            >
              Install MetaMask
            </Typography>

            <Typography
              className="MuiLink-underlineHover"
              color="primary"
              variant="body1"
              component="a"
              href="https://uniswap.exchange/swap/0x4946fcea7c692606e8908002e55a582af44ac121"
              target="_blank"
              paragraph
              display="block"
            >
              Buy FOAM Tokens
            </Typography>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(Home));
