import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import Registry from '../core/contract/FOAM-Registry.abi.json';
import FoamTCRVoting from '../core/contract/FOAM-TCR-Voting.abi.json';
import { connect } from 'react-redux';
import SwitchableProvider from '../core/network/switchable-provider';

class ContractLoader extends Component {
  constructor(props, { drizzle }) {
    super(props);

    let registryContractInstance = new (SwitchableProvider.create().eth.Contract)(
      Registry,
      '0x2832373dddad96bbfb44f1b7da11b6541026bf40'
    );

    const registryContractConfig = {
      contractName: 'Registry',
      web3Contract: registryContractInstance,
    };

    let tcrVotingInstance = new (SwitchableProvider.create().eth.Contract)(
      FoamTCRVoting,
      '0x66f27365d66c0e8ad4ef87db8cb4af63ce874b7b'
    );

    const tcrVotingContractConfig = {
      contractName: 'TCRVoting',
      web3Contract: tcrVotingInstance,
    };

    drizzle.addContract(registryContractConfig, []);
    drizzle.addContract(tcrVotingContractConfig, []);
  }

  render() {
    if (
      this.props.contracts.Registry &&
      this.props.contracts.Registry.initialized &&
      this.props.contracts.TCRVoting &&
      this.props.contracts.TCRVoting.initialized
    )
      return Children.only(this.props.children);

    return (
      // Display a loading indicator.
      <main>
        <h1>
          <span role="img">⚙</span>️
        </h1>
        <p>Loading contracts...</p>
      </main>
    );
  }
}

ContractLoader.contextTypes = {
  drizzle: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    contracts: state.contracts,
    drizzleStatus: state.drizzleStatus,
    web3: state.web3,
  };
};

export default connect(mapStateToProps)(ContractLoader);
