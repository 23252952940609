import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';

import Drawer from '../components/Drawer';
import VotingCard from '../components/cards/VotingCard';
import ResultCard from '../components/cards/ResultCard';
import RevealingCard from '../components/cards/RevealingCard';
import StatCard from '../components/cards/StatCard';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    //   -webkit-overflow-scrolling: 'touch'
  },
});

class Challenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      data: null,
      stats: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  sortData(data) {
    // This function should sort the data into three groups:
    //  Voting underway
    //  Reveal period
    //  Recently ended

    const now = new Date();
    var result = {};
    result['ended'] = [];
    result['voting'] = [];
    result['revealing'] = [];

    data.forEach((item, index) => {
      const revealEnds = new Date(item.revealEnds);
      const votingEnds = new Date(item.votingEnds);
      if (now > revealEnds) {
        result['ended'].push(item);
      } else if (now > votingEnds) {
        result['revealing'].push(item);
      } else {
        result['voting'].push(item);
      }
    });

    if (result['ended'].length > 1) {
      result['ended'].reverse();
    }

    return result;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    fetch(
      'https://api.blocklytics.org/foam/v0/challenges?daysBack=14&key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          Promise.reject();
        }
      })
      .then(data => {
        if (data !== undefined) {
          this.setState({
            data: this.sortData(data),
          });
        }
      });

    fetch(
      'https://api.blocklytics.org/foam/v0/challenges/stats?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          Promise.reject();
        }
      })
      .then(data => {
        if (data !== undefined) {
          this.setState({
            stats: data,
          });
        }
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { classes } = this.props;
    var width = this.state.width;

    const drawerWidth = 240;
    if (isWidthUp('sm', this.props.width)) {
      width -= drawerWidth;
    }

    var data_voting = this.state.data ? this.state.data['voting'] : null;
    var data_revealing = this.state.data ? this.state.data['revealing'] : null;
    var data_ended = this.state.data ? this.state.data['ended'] : null;

    const voting_children = data_voting ? data_voting.length : null;
    const revealing_children = data_revealing ? data_revealing.length : null;
    const ended_children = data_ended ? data_ended.length : null;

    var maxWidth = width;
    var childWidth = 240 + 16; // 240 (fixed width) + 16 (gutter)

    if (width > 960 - drawerWidth) {
      maxWidth = 960 - drawerWidth;
    }

    if (width > 1280 - drawerWidth) {
      maxWidth = 1280 - drawerWidth;
      //       childWidth = 240 * 1.5 + 16
    }

    if (width > 1920 - drawerWidth) {
      maxWidth = 1600;
      //       childWidth = 240 * 1.5 + 16
    }

    const cardMargin = (width - maxWidth) / 2;
    const cols = width / childWidth;

    const statTotalChallenges = this.state.stats
      ? this.state.stats[0]['challenges']
      : null;
    const statActiveChallenges =
      voting_children === null || revealing_children === null
        ? null
        : voting_children + revealing_children;
    const statChallengeSuccessRate = this.state.stats
      ? (
          (this.state.stats[0]['challenges_succeeded'] /
            this.state.stats[0]['challenges_finalized']) *
          100
        ).toFixed(0)
      : null;

    return (
      <Drawer pageTitle="Challenges" selectedSection={1} selectedIndex={1}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Stats
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={4}>
                <StatCard
                  label="Total"
                  note="&nbsp;"
                  stat={statTotalChallenges}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatCard
                  label="Active"
                  note="&nbsp;"
                  stat={statActiveChallenges}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatCard
                  label="Success Rate"
                  note="&nbsp;"
                  stat={statChallengeSuccessRate}
                  suffix="%"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {voting_children === 0 ? null : (
          <React.Fragment>
            <Grid
              container
              className={classes.content}
              spacing={2}
              alignItems="stretch"
              direction="column"
              justify="flex-start"
            >
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Voting underway{' '}
                  {voting_children ? '(' + voting_children + ')' : null}
                </Typography>
              </Grid>
            </Grid>

            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                style={{ width }}
                cols={cols}
                cellHeight="auto"
                spacing={0}
              >
                {data_voting ? (
                  data_voting.map((item, index) => (
                    <GridListTile
                      style={{ marginLeft: index === 0 ? cardMargin : 0 }}
                      key={item.listingHash}
                    >
                      <VotingCard
                        listingHash={item.listingHash}
                        endTime={item.votingEnds}
                        stake={item.deposit}
                        votes={item.votes ? item.votes : 0}
                        voters={item.voters ? item.voters : 0}
                        id={item.id}
                        width={childWidth}
                      />
                    </GridListTile>
                  ))
                ) : (
                  <GridListTile style={{ marginLeft: cardMargin }}>
                    <VotingCard />
                  </GridListTile>
                )}
              </GridList>
            </div>
          </React.Fragment>
        )}

        {revealing_children === 0 ? null : (
          <React.Fragment>
            <Grid
              container
              className={classes.content}
              spacing={2}
              alignItems="stretch"
              direction="column"
              justify="flex-start"
            >
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Reveal period{' '}
                  {revealing_children ? '(' + revealing_children + ')' : null}
                </Typography>
              </Grid>
            </Grid>

            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                style={{ width }}
                cols={cols}
                cellHeight="auto"
                spacing={0}
              >
                {data_revealing ? (
                  data_revealing.map((item, index) => (
                    <GridListTile
                      style={{ marginLeft: index === 0 ? cardMargin : 0 }}
                      key={item.listingHash}
                    >
                      <RevealingCard
                        listingHash={item.listingHash}
                        endTime={item.revealEnds}
                        stake={item.deposit}
                        id={item.id}
                        votes={item.votes}
                        votesFor={item.votesAgainst}
                        votesAgainst={item.votesFor}
                      />
                    </GridListTile>
                  ))
                ) : (
                  <GridListTile style={{ marginLeft: cardMargin }}>
                    <RevealingCard />
                  </GridListTile>
                )}
              </GridList>
            </div>
          </React.Fragment>
        )}

        {ended_children === 0 ? null : (
          <React.Fragment>
            <Grid
              container
              className={classes.content}
              spacing={2}
              alignItems="stretch"
              direction="column"
              justify="flex-start"
            >
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Ended recently{' '}
                  {ended_children ? '(' + ended_children + ')' : null}
                </Typography>
              </Grid>
            </Grid>

            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                style={{ width }}
                cols={cols}
                cellHeight="auto"
                spacing={0}
              >
                {data_ended ? (
                  data_ended.map((item, index) => (
                    <GridListTile
                      style={{ marginLeft: index === 0 ? cardMargin : 0 }}
                      key={item.listingHash}
                    >
                      <ResultCard
                        listingHash={item.listingHash}
                        endTime={item.revealEnds}
                        stake={item.deposit}
                        id={item.id}
                        result={item.result}
                      />
                    </GridListTile>
                  ))
                ) : (
                  <GridListTile style={{ marginLeft: cardMargin }}>
                    <ResultCard />
                  </GridListTile>
                )}
              </GridList>
            </div>
          </React.Fragment>
        )}
      </Drawer>
    );
  }
}

export default compose(withRouter, withStyles(styles), withWidth())(Challenges);
