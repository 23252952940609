import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  padded: {
    padding: '8px 16px 8px 16px',
    wordWrap: 'break-word',
    position: 'relative',
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
});

class ListCard extends Component {
  render() {
    const { classes } = this.props;
    const label = this.props.label || '';

    return (
      <Paper className={classes.padded}>
        <Typography variant="overline">{label}</Typography>
        {this.props.children}
      </Paper>
    );
  }
}

export default withStyles(styles)(ListCard);
