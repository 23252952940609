import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import OpenIcon from '@material-ui/icons/OpenInNew';

import spamList from '../../utils/spam-registry.js';

var Geohash = require('latlon-geohash');

const FOAM_COLOR_VERIFY = '#28AF60';
const FOAM_COLOR_REMOVE = '#FF0000';

const styles = theme => ({
  card: {
    width: 240,
    marginRight: 16,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 24,
  },
  media: {
    height: 140,
    background: '#CAD2D3',
  },
  marker: {
    position: 'absolute',
    top: 65,
    left: 115,
    height: 10,
    width: 10,
    backgroundColor: '#f47f67',
    borderRadius: 999,
  },
  markerHalo: {
    position: 'absolute',
    top: 50, // height of media - height / 2
    left: 100, // width of media - width / 2
    height: 40,
    width: 40,
    backgroundColor: '#f47f67',
    opacity: 0.25,
    borderRadius: 999,
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  keepText: {
    color: FOAM_COLOR_VERIFY,
  },
  removeText: {
    color: FOAM_COLOR_REMOVE,
  },
  linearColorPrimary: {
    backgroundColor: FOAM_COLOR_REMOVE, // Background
  },
  linearBarColorPrimary: {
    backgroundColor: FOAM_COLOR_VERIFY, // Progress bar
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
  },
  iconSmall: {
    fontSize: 16,
  },
});

// const nbsp = '\u00A0'

class RevealingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  isValidNumber(number) {
    if (
      isFinite(number) &&
      number !== null &&
      number !== undefined &&
      !isNaN(number)
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    const listingHash = this.props.listingHash;

    if (listingHash) {
      fetch('https://map-api-direct.foam.space/poi/details/' + listingHash)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw new Error('Point not found');
          }
        })
        .then(data => {
          if (data !== undefined) {
            this.setState({ data: data });
          }
        })
        .catch(error =>
          fetch(
            'https://map-api-direct.foam.space/poi/removed/details/' +
              listingHash
          )
            .then(response => {
              if (response.status >= 200 && response.status < 300) {
                return response.json();
              } else {
                Promise.reject();
              }
            })
            .then(data => {
              if (data !== undefined) {
                this.setState({ data: data });
              }
            })
        );
    }
  }

  render() {
    const { classes } = this.props;

    const listingHash = this.props.listingHash;

    const endTime = this.props.endTime || null;
    const endTimeDate = endTime ? new Date(endTime) : null;

    //     const endTime = new Date(this.props.endTime) || new Date()
    //     const now = new Date()
    const subtitle = endTimeDate
      ? 'Reveal ends ' +
        endTimeDate.toLocaleDateString([], { weekday: 'long' }) +
        ' at ' +
        endTimeDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : null;

    const stake = this.props.stake || null;
    const rewardPool = stake ? stake * 0.4 : null;
    const votes = this.props.votes || null;
    const votesFor = this.props.votesFor || 0;
    const votesAgainst = this.props.votesAgainst || 0;
    const id = this.props.id || null;

    const data = this.state.data;
    var title = data
      ? data['data']['name'].length > 44
        ? data['data']['name'].substring(0, 44) + '...'
        : data['data']['name']
      : null;

    if (spamList.includes(listingHash)) {
      title = <span style={{ fontStyle: 'oblique' }}>Marked as spam</span>;
    }

    const geohash = data ? data['data']['geohash'] : null;
    var lat,
      long_ = null;
    var imageSource = null;

    if (geohash !== null) {
      const decoded_geohash = Geohash.decode(geohash);
      lat = decoded_geohash['lat'];
      long_ = decoded_geohash['lon'];
      const zoom = 3;
      imageSource =
        'https://api.mapbox.com/styles/v1/mapbox/light-v9/static/' +
        long_ +
        ',' +
        lat +
        ',' +
        zoom +
        ',0,0/240x140/?access_token=pk.eyJ1IjoiY2FsZWJzaGVyaWRhbiIsImEiOiJjanVlaGhzdHIwM2N2NDNwZDk2ajl1aDFrIn0.m-l0wrUzRhWOxRnTkJYEwQ&attribution=false&logo=false';
    }

    const stakeString = rewardPool
      ? rewardPool.toLocaleString([], { maximumFractionDigits: 0 }) +
        ' FOAM reward pool'
      : null;
    const votesRevealed =
      votesFor !== null && votesAgainst !== null ? votesFor + votesAgainst : 0;
    const revealString =
      votesRevealed === 0
        ? 'No votes revealed'
        : ((votesRevealed / votes) * 100).toLocaleString([], {
            maximumFractionDigits: 0,
          }) + '% of votes revealed';
    const votesAgainstValue = (votesAgainst / votesRevealed) * 100;

    const primaryHref =
      listingHash && long_ && lat
        ? 'https://map.foam.space/#/dashboard/' +
          listingHash +
          '/?lng=' +
          long_ +
          '&lat=' +
          lat +
          '&zoom=15'
        : null;
    const secondaryHref = id ? '/challenges/' + id : null;

    return (
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={{ pathname: secondaryHref, state: { data: data } }}
          disabled={secondaryHref ? false : true}
        >
          <CardMedia className={classes.media} image={imageSource} />
          {imageSource ? (
            <React.Fragment>
              <div className={classes.marker} />
              <div className={classes.markerHalo} />
            </React.Fragment>
          ) : null}
          <CardContent>
            <Typography variant="h5" component="h2">
              {title || <Skeleton />}
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              {subtitle || <Skeleton />}
            </Typography>

            {votesRevealed === 0 ? (
              <React.Fragment>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.mediumEmphasis}
                >
                  {stakeString || <Skeleton />}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.lowEmphasis}
                >
                  {stakeString && revealString ? revealString : <Skeleton />}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.mediumEmphasis}
                  paragraph
                >
                  {stakeString || <Skeleton />}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.mediumEmphasis}
                >
                  {revealString || <Skeleton />}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={votesAgainstValue}
                  classes={{
                    colorPrimary: classes.linearColorPrimary,
                    barColorPrimary: classes.linearBarColorPrimary,
                  }}
                />

                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.keepText}
                      inline
                    >
                      &#9679;&nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.keepText}
                      inline
                    >
                      Keep point
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.lowEmphasis}
                    >
                      {votesAgainst !== null ? (
                        votesAgainst.toLocaleString([], {
                          maximumFractionDigits: 0,
                        })
                      ) : (
                        <Skeleton />
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.removeText}
                      inline
                    >
                      &#9679;&nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.removeText}
                      inline
                    >
                      Remove point
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.lowEmphasis}
                    >
                      {votesFor !== null ? (
                        votesFor.toLocaleString([], {
                          maximumFractionDigits: 0,
                        })
                      ) : (
                        <Skeleton />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </CardContent>
        </CardActionArea>
        <Divider light={true} />
        <CardActions>
          <Button
            color="primary"
            href={primaryHref}
            target="_blank"
            disabled={primaryHref ? false : true}
          >
            Reveal
            <OpenIcon
              className={classNames(classes.rightIcon, classes.iconSmall)}
            />
          </Button>
          <Button
            color="primary"
            component={Link}
            to={{ pathname: secondaryHref, state: { data: data } }}
            disabled={secondaryHref ? false : true}
          >
            More
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(RevealingCard);
