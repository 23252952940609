import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

// Material UI

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Components

import ListCard from './ListCard';

// Utils

import { isNil } from 'ramda';
import HelpTooltip from '../shared/HelpTooltip';

const styles = theme => ({
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
});

class StatCard extends Component {
  isValidNumber(number) {
    if (isFinite(number) && !isNil(number) && !isNaN(number)) {
      return true;
    } else {
      console.log(number);
      return false;
    }
  }

  render() {
    const { classes, helpText } = this.props;
    const label = this.props.label || '';
    const prefix = this.props.prefix || '';
    const suffix = this.props.suffix || '';
    const decimals = this.props.decimals || 0;
    const isStatValid = this.isValidNumber(this.props.stat);
    let stat = !isStatValid
      ? null
      : prefix +
        parseFloat(this.props.stat).toLocaleString([], {
          maximumFractionDigits: decimals,
        }) +
        suffix;
    if (this.props.stat === 0) {
      stat = '-';
    }
    const note = !isStatValid ? null : this.props.note;

    let skeletonWidth = 90;
    if (prefix) {
      skeletonWidth += 45;
    }
    if (suffix) {
      skeletonWidth += 60;
    }

    return (
      <ListCard label={label}>
        {helpText && (
          <HelpTooltip
            text={helpText}
            style={{ position: 'absolute', top: 14, right: 14 }}
          />
        )}
        <Typography variant="h4" component="p">
          {isStatValid ? stat : <Skeleton width={skeletonWidth} />}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.lowEmphasis}
        >
          {isStatValid ? note : <Skeleton />}
        </Typography>
      </ListCard>
    );
  }
}

export default withStyles(styles)(StatCard);
