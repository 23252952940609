import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Material UI

import ClaimRewardIcon from '@material-ui/icons/Check';
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Redux
import { connect } from 'react-redux';
import { claimRewardDispatchRequest } from 'core/contract/actions';
import { claimRewardTxForChallengeIdSelector } from 'core/contract/selectors';

// Utils

import { isNil, pathOr } from 'ramda';

const FOAM_COLOR_VERIFY = '#28AF60';

const styles = theme => ({
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
    textDecoration: 'none',
  },
  padded: {
    padding: theme.spacing(2),
  },
  claimRewardAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_VERIFY,
  },
});

class CartographerRewardCard extends Component {
  handleClaimReward = () => {
    const { challengeData } = this.props;
    this.props.onClaimReward(challengeData);
  };

  isValidNumber(number) {
    if (isFinite(number) && !isNil(number) && !isNaN(number)) {
      return true;
    } else {
      return false;
    }
  }

  displayTxStatus(claimRewardTxData) {
    if (isNil(claimRewardTxData)) return null;

    let message;
    if (claimRewardTxData.status === 'tx-create') {
      message = 'Creating transaction...';
    } else if (claimRewardTxData.status === 'tx-sent') {
      message = (
        <>
          <a
            href={`https://etherscan.io/tx/${claimRewardTxData.rawTx.transactionHash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Transaction created ↗︎
          </a>
        </>
      );
    } else if (claimRewardTxData.status === 'tx-success') {
      // TODO: think we want to filter this out.. this is where the blocklytics-api will now be
      // "stale", but somehow we might want to show that the claim was complete. Toast notification?

      // RESPONSE:
      // 1 - token balances: these will be fine.
      // 2 - claim rewards: these cards will be out of date...
      //       we should not display the card if there was a successful transaction

      message = 'Claim complete';
    } else if (claimRewardTxData.status === 'tx-error') {
      {
        console.log(claimRewardTxData.error.message);
      }
      message = (
        <>
          <span style={{ color: '#FF6666' }}>Transaction failed</span>
        </>
      );
    } else {
      message = 'unknown status';
    }

    return (
      <Typography variant="caption" className="lowEmphasis">
        {message}
      </Typography>
    );
  }

  displayClaimRewardButton(challengeData, claimRewardTxData) {
    // Possible states:
    //   1 - Enabled
    //   2 - Loading
    //   3 - Disabled

    const {
      challenge_id: challengeId,
      reveal_period_ended: revealPeriodEnded,
      voter_reward: voterReward,
    } = challengeData;

    const isLoadingIndicatorDisplayed =
      claimRewardTxData &&
      (claimRewardTxData.status === 'tx-create' || // Creating transaction
        claimRewardTxData.status === 'tx-sent'); // Transaction created, waiting to be mined
    const isClaimButtonDisabled = !revealPeriodEnded;

    const buttonText =
      claimRewardTxData && claimRewardTxData.status === 'tx-error'
        ? 'Try again'
        : 'Claim reward';

    return (
      <Grid item style={{ minWidth: 140, textAlign: 'center' }}>
        {isLoadingIndicatorDisplayed ? (
          <CircularProgress size={24} />
        ) : (
          <React.Fragment>
            <Button
              disabled={isClaimButtonDisabled}
              color="primary"
              variant="outlined"
              onClick={this.handleClaimReward}
            >
              {buttonText}
            </Button>
            {/*
	// TODO: Add note from props
	// 				<Typography variant="caption" align="center">
	// 					{note}
	// 				</Typography>
	*/}
          </React.Fragment>
        )}
      </Grid>
    );
  }

  render() {
    const {
      challengeData = {},
      claimRewardTxData,
      classes,
      handleClaimReward,
      note,
    } = this.props;

    const {
      challenge_id: challengeId,
      reveal_period_ended: revealPeriodEnded,
      voter_reward: voterReward,
    } = challengeData;

    const isVoterRewardValid = this.isValidNumber(voterReward);
    const rewardText = !isVoterRewardValid
      ? null
      : parseFloat(voterReward).toLocaleString([], {
          maximumFractionDigits: 2,
        }) + ' FOAM';

    const challengeText = challengeId ? (
      <Link to={'/challenges/' + challengeId} className={classes.lowEmphasis}>
        Earned in Challenge #{challengeId}
      </Link>
    ) : null;

    return (
      <Paper className={classes.padded}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                {isVoterRewardValid ? (
                  <Avatar className={classes.claimRewardAvatar}>
                    <ClaimRewardIcon />
                  </Avatar>
                ) : (
                  <Avatar />
                )}
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Typography variant="body1">
                    {rewardText ? rewardText : <Skeleton width={90} />}
                  </Typography>
                  <Typography variant="body2" className={classes.lowEmphasis}>
                    {challengeText ? challengeText : <Skeleton width={180} />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="flex-end"
              justify="flex-start"
            >
              {isVoterRewardValid
                ? this.displayClaimRewardButton(
                    challengeData,
                    claimRewardTxData
                  )
                : null}
              {this.displayTxStatus(claimRewardTxData)}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  claimRewardTxData: claimRewardTxForChallengeIdSelector(
    state,
    pathOr(null, ['challengeData', 'challenge_id'], ownProps)
  ),
});

const mapDispatchToProps = {
  onClaimReward: claimRewardDispatchRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CartographerRewardCard));
