// Redux
import { initialState } from './reducer';

// Utils
import { compose, path, pathOr } from 'ramda';

const networkSelector = pathOr(initialState, ['network']);

export const activeAddressSelector = compose(
  path(['activeAddress']),
  networkSelector
);

export const metamaskEnabledSelector = compose(
  path(['metamaskEnabled']),
  networkSelector
);

export const metamaskInstalledSelector = compose(
  path(['metamaskInstalled']),
  networkSelector
);

export const networkConnectedSelector = compose(
  path(['networkConnected']),
  networkSelector
);
