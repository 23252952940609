// Redux
import {
  defaultCartographerRewards,
  defaultCartographerBalances,
} from './reducer';
import { activeAddressSelector } from 'core/network/selectors';

// Utils
import { isNil, pathOr } from 'ramda';
import { createSelector } from 'reselect';

// --- cartographerRewards

export const cartographerRewardsSelector = pathOr(defaultCartographerRewards, [
  'blocklyticsApi',
  'cartographerRewards',
]);

export const activeAddressCartographerRewardsSelector = state => {
  const cartographerRewards = cartographerRewardsSelector(state);
  const activeAddress = activeAddressSelector(state);

  return cartographerRewards[activeAddress];
};

export const activeAddressCartographerRewardsUnclaimedSelector = createSelector(
  [activeAddressCartographerRewardsSelector],
  rewardList => {
    if (isNil(rewardList)) return null;

    return rewardList.filter(x => isNil(x.claimed_reward));
  }
);

// --- cartographerBalances

export const cartographerBalancesSelector = pathOr(
  defaultCartographerBalances,
  ['blocklyticsApi', 'cartographerBalances']
);

export const x = state => {
  const cartographerBalances = cartographerBalancesSelector(state);
  const activeAddress = activeAddressSelector(state);

  return cartographerBalances[activeAddress];
};

export const activeAddressCartographerBalancesSelector = createSelector(
  [x],
  balanceList => {
    if (isNil(balanceList)) return null;

    return balanceList;
  }
);
