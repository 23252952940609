import React from 'react';
import Skeleton from 'react-loading-skeleton';

// Components
import ListCard from '../../../components/cards/ListCard';

// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Helpers
import { displayTokensFromInt } from '../../../utils/ui-helpers';
import HelpTooltip from '../../../components/shared/HelpTooltip';

const PointStakeCard = ({
  classes,
  pointStake,
  challengeStake,
  showStakeCTA,
  handleChallenge,
  helpText,
}) => {
  // const totalStake = challengeStake ? pointStake + challengeStake : pointStake;

  return (
    <ListCard label="Point stake">
      <HelpTooltip
        text={helpText}
        style={{ position: 'absolute', top: 14, right: 14 }}
      />
      <React.Fragment>
        <Typography variant="h5">
          {pointStake ? displayTokensFromInt(pointStake) : <Skeleton />}
        </Typography>

        {showStakeCTA && (
          <Button
            className={classes.primaryActionButton}
            onClick={handleChallenge}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            Challenge
          </Button>
        )}

        <Typography variant="body2" className={classes.challenged}>
          {challengeStake
            ? `Challenged for ${displayTokensFromInt(challengeStake)}`
            : null}
        </Typography>
      </React.Fragment>
    </ListCard>
  );
};

export default PointStakeCard;
