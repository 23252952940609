import FoamTokenContractAbi from './FOAM-Token.abi.json';
import FoamRegistryContractAbi from './FOAM-Registry.abi.json';
import FoamTCRVotingAbi from './FOAM-TCR-Voting.abi.json';

const contractConfigMap = {
  registry: {
    abi: FoamRegistryContractAbi,
    deployedAddress: '0x2832373dddad96bbfb44f1b7da11b6541026bf40',
  },
  token: {
    abi: FoamTokenContractAbi,
    deployedAddress: '0x4946fcea7c692606e8908002e55a582af44ac121',
    tokenDecimals: 18,
  },
  tcrVoting: {
    abi: FoamTCRVotingAbi,
    deployedAddress: '0x66f27365d66c0e8ad4ef87db8cb4af63ce874b7b',
  },
};

export default contractConfigMap;
