import React, { Component } from 'react';

// Material UI

import withStyles from '@material-ui/core/styles/withStyles';

// Highcharts

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Export from 'highcharts/modules/exporting';
import Data from 'highcharts/modules/export-data';
import Offline from 'highcharts/modules/offline-exporting';

Export(Highcharts);
Offline(Highcharts);
Data(Highcharts);

const PADDING_CHART = 16;
const PADDING_CREDITS = 24;
const HEIGHT_CHART = 200;

const styles = theme => ({});

/**
 * Override the reset function, we don't need to hide the tooltips and
 * crosshairs.
 */

Highcharts.Pointer.prototype.reset = function() {
  return undefined;
};

/**
 * Highlight a point by showing tooltip, setting hover state and draw crosshair
 */

Highcharts.Point.prototype.highlight = function(event) {
  event = this.series.chart.pointer.normalize(event);
  this.onMouseOver(); // Show the hover marker
  this.series.chart.tooltip.refresh(this); // Show the tooltip
  this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
};

class PointsAddedRemoved extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
  }

  syncExtremes(e) {
    var thisChart = this.chart;

    if (e.trigger !== 'syncExtremes') {
      // Prevent feedback loop
      Highcharts.each(Highcharts.charts, function(chart) {
        if (chart && chart !== thisChart) {
          if (chart.xAxis[0].setExtremes) {
            // It is null while updating
            chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
              trigger: 'syncExtremes',
            });
          }
        }
      });
    }
  }

  syncCrosshairs(e) {
    if (e.trigger !== 'syncCrosshairs') {
      // Prevent feedback loop

      const syncCharts = Highcharts.charts.filter(
        chart =>
          chart && chart.renderTo && chart.renderTo.className === 'syncCharts'
      );
      Highcharts.each(syncCharts, function(chart) {
        var point, event;

        if (chart.pointer) {
          event = chart.pointer.normalize(e);

          if (event && chart.series[0]) {
            point = chart.series[0].searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          }
        }
      });
    }
  }

  render() {
    //     const { classes } = this.props;

    const data = this.props.data || [];
    const isLoading = this.props.isLoading || false;
    const exportingEnabled = this.props.exportingEnabled || false;
    const chartTitle = this.props.chartTitle || '';
    //     const chartSubtitle = this.props.chartSubtitle || ""
    const creditsEnabled = this.props.creditsEnabled;

    const chartHeight = creditsEnabled
      ? HEIGHT_CHART
      : HEIGHT_CHART - PADDING_CREDITS;
    const spacing = creditsEnabled
      ? [
          PADDING_CHART,
          PADDING_CHART,
          PADDING_CHART + PADDING_CREDITS,
          PADDING_CHART,
        ]
      : [PADDING_CHART, PADDING_CHART, PADDING_CHART, PADDING_CHART];

    // Loading logic

    if (this.chart && this.chart.current && this.chart.current.chart) {
      const chartObj = this.chart.current.chart;
      if (isLoading) {
        chartObj.showLoading();
      } else {
        chartObj.hideLoading();
      }
    }

    // Plot Options

    const plotOptions = {
      lang: { decimalPoint: '.', thousandsSep: ',' },
      loading: {
        labelStyle: {
          cursor: 'default',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
        style: { backgroundColor: 'transparent' },
      },
      credits: {
        enabled: creditsEnabled,
        text: 'Source: Blocklytics',
        position: { align: 'left', x: PADDING_CHART, y: -PADDING_CHART },
        href: null,
        style: {
          cursor: 'default',
          fontSize: '0.75rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
      },
      exporting: {
        enabled: exportingEnabled,
        buttons: {
          contextButton: { menuItems: ['downloadPNG', 'downloadCSV'] },
        },
      },
      chart: {
        events: {
          load: function() {
            this.xAxis[0].setExtremes(
              new Date().getTime() - 24 * 60 * 60 * 1000 * 90,
              null
            );
            //             this.showResetZoom();
          },
        },
        height: chartHeight,
        backgroundColor: 'transparent',
        spacing: spacing,
        zoomType: 'x',
        resetZoomButton: {
          position: {
            align: 'left',
            x: 24,
          },
        },
        style: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      },
      title: {
        text: chartTitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.5rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.33',
          letterSpacing: '0em',
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        events: {
          setExtremes: this.syncExtremes,
        },
      },
      yAxis: { min: 0, title: null },
      tooltip: {
        positioner: function() {
          return {
            // right aligned
            x: this.chart.chartWidth - this.label.width - 8,
            y: 4, // align to title
          };
        },
        animation: false,
        backgroundColor: 'transparent',
        borderWidth: 0,
        shadow: false,
        style: {
          fontSize: '1.5rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '0.7',
          letterSpacing: '0em',
        },
        useHTML: true,
        headerFormat: '<table>',
        pointFormat:
          '<tr><td align="right"><span style="color:{point.color}">\u25CF</span> {point.y:,.0f}</td></tr>',
        footerFormat:
          '<tr><td><span style="font-size: 0.75rem">{point.key}</span></td></tr></table>',
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          // 	        stacking: 'normal',
          // 	        connectNulls: true,
          marker: { radius: 0.5 },
          lineWidth: 2,
          fillOpacity: 1.0,
          // 	        threshold: null
        },
        series: {
          states: { hover: { lineWidthPlus: 0 } },
        },
      },
      series: data,
    };

    return (
      <div
        onMouseMove={this.syncCrosshairs.bind(this)}
        onTouchStart={this.syncCrosshairs.bind(this)}
        onTouchMove={this.syncCrosshairs.bind(this)}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={plotOptions}
          containerProps={{ className: 'syncCharts' }}
          ref={this.chart}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PointsAddedRemoved);
