// React
import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Redux
import { connect } from 'react-redux';
import { activeAddressSelector } from 'core/network/selectors';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Components
import Drawer from '../components/Drawer';
import ListCard from '../components/cards/ListCard';
import ProfileCard from '../components/cards/ProfileCard';
import StatCard from '../components/cards/StatCard';

// Icons
import AddIcon from '@material-ui/icons/Add';
import AddPointIcon from '@material-ui/icons/AddLocation';
import BoostIcon from '@material-ui/icons/AccountBalance';
import ChallengeIcon from '@material-ui/icons/Announcement';
import RemovePointIcon from '@material-ui/icons/LocationOff';
import VoteForPointIcon from '@material-ui/icons/ThumbUp';
import VoteAgainstPointIcon from '@material-ui/icons/ThumbDown';
import CommitVoteIcon from '@material-ui/icons/HowToVote';
import GlobeIcon from '@material-ui/icons/Public';

import spamList from '../utils/spam-registry.js';

const Box = require('3box');

function ListItemLink(props) {
  return <ListItem button component={Link} to={props.href} {...props} />;
}

const FOAM_COLOR_VERIFY = '#28AF60';
const FOAM_COLOR_REMOVE = '#FF0000';
const FOAM_COLOR_PENDING = '#3082ED';
const FOAM_COLOR_CHALLENGE = '#F47F67';
const FOAM_COLOR_UNREVEALED = '#AEAEAE';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  historySection: {
    [theme.breakpoints.up('lg')]: {
      // breakpoint matches grid
      marginTop: '50px', // margin matches avatar
    },
  },
  mediumEmphasis: {
    color: 'rgba(0,0,0,0.6)',
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  addPointAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_PENDING,
  },
  removePointAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_REMOVE,
  },
  voteForPointAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_VERIFY,
  },
  voteAgainstPointAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_REMOVE,
  },
  commitVoteAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_UNREVEALED,
  },
  challengeAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_CHALLENGE,
  },
  nftItemEmpty: {
    width: '100%',
    paddingTop: '10%',
    paddingBottom: '10%',
    position: 'relative',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: '#DEDEDE',
    backgroundColor: '#FFFFFF',
  },
  nftItem: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    borderWidth: theme.spacing(3),
    borderStyle: 'solid',
    borderColor: '#DEDEDE',
    backgroundColor: '#FFFFFF',
    transition: 'transform 0.1s, box-shadow 0.1s',
    '&:hover': {
      transform: 'scale(1.1)',
      zIndex: 1199, // zIndex of drawer is 1200
      boxShadow:
        '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)',
    },
  },
  nftImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
});

class Cartographer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartographersData: null,
      cartographerHistoryData: null,
      cartographerNftData: null,
      cartographerNftDataIsLoading: true,
      profile: null,
      accounts: null,
    };
  }

  componentDidMount() {
    const passedState = this.props.location.state;

    if (passedState && passedState['cartographersData']) {
      this.setState({ cartographersData: passedState['cartographersData'] });
    } else {
      this.updateCartographerData();
    }

    this.updateCartographerHistoryData();
    this.updateCartographerNftData();

    if (passedState && passedState['profile']) {
      this.setState({ profile: passedState['profile'] });
    } else {
      this.updateProfile();
    }
  }

  updateCartographerData() {
    fetch(
      'https://api.blocklytics.org/foam/v0/cartographers?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          cartographersData: data,
        });
      })
      .catch(error => console.log(error));
  }

  updateProfile() {
    const address = this.props.address;

    this.setState({
      profile: null,
    });

    // Load the profile
    Box.getProfile(address).then(profile => {
      // If profile loaded successfully => Request verified accounts (twitter, etc)
      // Update everything at once to avoid unnecessary rendering
      if (profile && profile.status !== 'error') {
        Box.getVerifiedAccounts(profile).then(accounts => {
          this.setState({
            profile: profile,
            accounts: accounts,
          });
        });
      } else {
        // Otherwise just update profile
        this.setState({
          profile: profile,
        });
      }
    });
  }

  optimisticUpdateProfile = profile =>
    this.setState({
      profile: profile,
    });

  updateCartographerNftData() {
    this.setState({ cartographerNftDataIsLoading: true });

    const address = this.props.address;
    fetch(
      //       'http://127.0.0.1:8080/v0/cartographers/' +
      'https://api.blocklytics.org/foam/v0/cartographers/' +
        address +
        '/nfts?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          cartographerNftData: data,
          cartographerNftDataIsLoading: false,
        });
      })
      .catch(error => console.log(error));
  }

  updateCartographerHistoryData() {
    const address = this.props.address;
    fetch(
      'https://api.blocklytics.org/foam/v0/cartographers/' +
        address +
        '/history?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          cartographerHistoryData: data,
        });
      })
      .catch(error => console.log(error));
  }

  getEtherscanForItem(item) {
    return 'https://etherscan.io/tx/' + item.transaction_hash;
  }

  hrefForItem(item) {
    if (item.challenge_id) {
      return '/challenges/' + item.challenge_id;
    } else if (item.listingHash) {
      return '/points/' + item.listingHash;
    } else {
      return '';
    }
  }

  actionForItem(item) {
    if (spamList.includes(item.listingHash)) {
      item['name'] = null;
    }

    const pointName = item.name || 'point';

    if (item.action === 'reveal') {
      if (item.choice === 0) {
        return 'Voted to remove point in challenge #' + item.challenge_id;
      } else {
        return 'Voted to keep point in challenge #' + item.challenge_id;
      }
    } else if (item.action === 'commit') {
      return 'Committed vote in challenge #' + item.challenge_id;
    } else if (item.action === 'removedPoint') {
      return 'Removed ' + pointName;
    } else if (item.action === 'challenge') {
      return 'Started challenge #' + item.challenge_id;
    } else if (item.action === 'newPoint') {
      return 'Added ' + pointName;
    } else if (item.action === 'pointDeposit') {
      if (item.amount >= 0) {
        return 'Boosted stake in ' + pointName;
      } else if (item.amount < 0) {
        return 'Withdrew stake from ' + pointName;
      }
    }
  }

  avatarForItem(item) {
    const { classes } = this.props;

    if (item.action === 'reveal') {
      if (item.choice === 0) {
        return (
          <Avatar className={classes.voteAgainstPointAvatar}>
            <VoteAgainstPointIcon />
          </Avatar>
        );
      } else {
        return (
          <Avatar className={classes.voteForPointAvatar}>
            <VoteForPointIcon />
          </Avatar>
        );
      }
    } else if (item.action === 'commit') {
      return (
        <Avatar className={classes.commitVoteAvatar}>
          <CommitVoteIcon />
        </Avatar>
      );
    } else if (item.action === 'removedPoint') {
      return (
        <Avatar className={classes.removePointAvatar}>
          <RemovePointIcon />
        </Avatar>
      );
    } else if (item.action === 'challenge') {
      return (
        <Avatar className={classes.challengeAvatar}>
          <ChallengeIcon />
        </Avatar>
      );
    } else if (item.action === 'newPoint') {
      return (
        <Avatar className={classes.addPointAvatar}>
          <AddPointIcon />
        </Avatar>
      );
    } else if (item.action === 'pointDeposit') {
      return (
        <Avatar className={classes.boostPointAvatar}>
          <BoostIcon />
        </Avatar>
      );
    }
  }

  dateForItem(item) {
    const date = new Date(item.timestamp);
    return date.toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  amountForItem(item) {
    if (item.amount) {
      return (
        item.amount.toLocaleString([], { maximumFractionDigits: 0 }) + ' FOAM'
      );
    } else {
      return '';
    }
  }

  isValidNumber(number) {
    if (
      isFinite(number) &&
      number !== null &&
      number !== undefined &&
      !isNaN(number)
    ) {
      return true;
    } else {
      return false;
    }
  }

  numberForStat(stat) {
    stat = !this.isValidNumber(stat)
      ? null
      : parseFloat(stat).toLocaleString([], {
          maximumFractionDigits: 0,
        });

    if (stat === 0) {
      stat = '-';
    }

    return stat;
  }

  componentDidUpdate = prevProps => {
    if (this.props.address !== prevProps.address) {
      // reset state
      this.setState({
        cartographersData: null,
        cartographerHistoryData: null,
        cartographerNftData: null,
        cartographerNftDataIsLoading: true,
        profile: null,
        accounts: null,
      });

      this.updateCartographerData();
      this.updateCartographerHistoryData();
      this.updateCartographerNftData();
      this.updateProfile();
    }
  };

  render() {
    const { classes, address, activeAddress } = this.props;

    const {
      cartographersData,
      cartographerHistoryData,
      cartographerNftData,
      cartographerNftDataIsLoading,
      profile,
      accounts,
    } = this.state;

    var pointsStat, votesStat, challengesStat, rewardsStat;
    if (cartographersData) {
      const cartographer = cartographersData.find(
        users => users.user === address
      );
      pointsStat = cartographer
        ? this.numberForStat(cartographer.points_on_map)
        : 0;
      votesStat = cartographer
        ? this.numberForStat(cartographer.votes_revealed)
        : 0;
      challengesStat = cartographer
        ? this.numberForStat(cartographer.challenges_started)
        : 0;
      rewardsStat = cartographer
        ? this.numberForStat(cartographer.map_rewards) +
          String.fromCharCode(160) +
          'FOAM'
        : null;
    }

    var nftDisplay;
    if (cartographerNftDataIsLoading) {
      nftDisplay = [1, 2, 3].map((item, id) => {
        return (
          <Grid item xs={4} md={3} key={id}>
            <Paper
              square={true}
              className={classes.nftItem}
              style={{ background: '#FFFFFF', borderColor: '#FFFFFF' }}
            ></Paper>
          </Grid>
        );
      });
    } else {
      nftDisplay =
        cartographerNftData && cartographerNftData.length > 0 ? (
          cartographerNftData.map(nft => {
            return (
              <Grid item xs={4} md={3}>
                <a
                  href={nft.external_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Paper
                    square={true}
                    className={classes.nftItem}
                    style={{
                      background: nft.background_color,
                      borderColor: nft.background_color,
                    }}
                  >
                    {nft.image ? (
                      <img
                        src={nft.image}
                        alt={nft.name}
                        className={classes.nftImage}
                      />
                    ) : null}
                  </Paper>
                </a>
              </Grid>
            );
          })
        ) : (
          <React.Fragment>
            <Grid item xs={6} sm={4} md={3} lg={4} xl={3}>
              <Paper
                square={true}
                className={classes.nftItemEmpty}
                elevation={0}
              >
                <Typography
                  variant="body1"
                  align="center"
                  style={{ padding: 8 }}
                  className={classes.mediumEmphasis}
                >
                  <AddIcon
                    style={{ fontSize: 72 }}
                    className={classes.lowEmphasis}
                  />
                  <br />
                  Cartographer Badges
                </Typography>
                <Typography align="center">
                  <Button
                    color="primary"
                    href="https://discourse.foam.space/t/cartographer-badge-test-release/697"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </Button>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={4} xl={3}>
              <Paper
                square={true}
                className={classes.nftItemEmpty}
                elevation={0}
              >
                <Typography
                  variant="body1"
                  align="center"
                  style={{ padding: 8 }}
                  className={classes.mediumEmphasis}
                >
                  <AddIcon
                    style={{ fontSize: 72 }}
                    className={classes.lowEmphasis}
                  />
                  <br />
                  BlockCities Collectibles
                </Typography>
                <Typography align="center">
                  <Button
                    color="primary"
                    href="http://blockcities.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </Button>
                </Typography>
              </Paper>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={6} sm={4} md={3} lg={4} xl={3}>
                <Paper
                  square={true}
                  className={classes.nftItemEmpty}
                  elevation={0}
                >
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ padding: 8 }}
                    className={classes.mediumEmphasis}
                  >
                    <AddIcon
                      style={{ fontSize: 72 }}
                      className={classes.lowEmphasis}
                    />
                    <br />
                    FOAM Map Treasure Hunts
                  </Typography>
                  <Typography align="center">
                    <Button
                      color="primary"
                      href="https://blog.foam.space/how-to-participate-in-blockcities-x-foam-map-scavenger-hunt-451894366d88"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </Button>
                  </Typography>
                </Paper>
              </Grid>
            </Hidden>
          </React.Fragment>
        );
    }

    return (
      <Drawer pageTitle="Cartographers" selectedSection={1} selectedIndex={2}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProfileCard
                  address={address}
                  editable={
                    activeAddress &&
                    address.toLowerCase() === activeAddress.toLowerCase()
                  }
                  profile={profile}
                  accounts={accounts}
                  cartographersData={cartographersData}
                  updateProfile={() => this.updateProfile()}
                  optimisticUpdateProfile={this.optimisticUpdateProfile}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={4}>
                    <StatCard label="Points" note="&nbsp;" stat={pointsStat} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StatCard
                      label="Challenges"
                      note="&nbsp;"
                      stat={challengesStat}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StatCard label="Votes" note="&nbsp;" stat={votesStat} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ListCard label="Collectibles">
                      <Grid container spacing={1} alignItems="stretch">
                        {nftDisplay}
                      </Grid>
                    </ListCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6} className={classes.historySection}>
            <Grid item xs={12}>
              <ListCard label="History">
                <List style={{ marginLeft: -16, marginRight: -16 }}>
                  {cartographerHistoryData ? (
                    cartographerHistoryData.map((item, index) => (
                      <ListItemLink key={index} href={this.hrefForItem(item)}>
                        <ListItemAvatar>
                          {this.avatarForItem(item)}
                        </ListItemAvatar>
                        <ListItemText
                          primary={this.actionForItem(item)}
                          secondary={this.dateForItem(item)}
                        />
                        <ListItemSecondaryAction
                          style={{ marginRight: 16, textAlign: 'right' }}
                        >
                          {item.amount
                            ? item.amount.toLocaleString([], {
                                maximumFractionDigits: 0,
                              }) + ' FOAM'
                            : ''}
                          <br />
                          <IconButton
                            color="default"
                            size="small"
                            aria-label="Etherscan"
                            href={this.getEtherscanForItem(item)}
                            target="_blank"
                          >
                            <GlobeIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemLink>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>
                      <ListItemText
                        primary=<Skeleton />
                        secondary=<Skeleton />
                      />
                    </ListItem>
                  )}
                </List>
              </ListCard>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({
  activeAddress: activeAddressSelector(state),
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Cartographer))
);
