import React, { useMemo } from 'react';

// Components
import PointStateCard from './PointStateCard';
import ListCard from '../../../components/cards/ListCard';

// Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PointStakeCard from './PointStakeCard';

const SideListCards = ({
  classes,
  account,
  listing,
  handleVerify,
  handleChallenge,
  handleRemove,
  primaryHref,
  pollMapData,
  pointStake,
  challengeStake,
  challengeReason,
}) => {
  const isOwner = useMemo(() => {
    return (
      account &&
      listing &&
      account.toLowerCase() === listing.owner.toLowerCase()
    );
  }, [account, listing]);

  const listingInfo = useMemo(() => {
    if (!listing) return { loading: true };

    const now = Math.floor(Date.now() / 1000);
    const applicationExpiry = Number.parseInt(listing.applicationExpiry);
    const challengeID = Number.parseInt(listing.challengeID);

    const removed = applicationExpiry === 0;
    const challengeExists = !!challengeID;
    const whitelisted = listing && listing.whitelisted;
    const pending =
      !whitelisted && applicationExpiry > 0 && applicationExpiry > now;
    const canBeWhitelisted =
      !whitelisted && applicationExpiry > 0 && applicationExpiry <= now;
    const verified = whitelisted && applicationExpiry > 0;

    let info = {
      loading: false,
      removed,
      challengeExists,
      challengeID,
      whitelisted,
      pending,
      canBeWhitelisted,
      verified,
    };

    if (challengeExists && pollMapData) {
      const {
        commitEndDate: strCommitEndDate,
        revealEndDate: strRevealEndDate,
      } = pollMapData;

      const commitEndDateInt = Number.parseInt(strCommitEndDate);
      const revealEndDateInt = Number.parseInt(strRevealEndDate);

      const votingUnderway = commitEndDateInt > now && revealEndDateInt > now;
      const revealUnderway = commitEndDateInt <= now && revealEndDateInt > now;
      const waitingFinalization =
        commitEndDateInt <= now && revealEndDateInt <= now;

      info = { ...info, votingUnderway, revealUnderway, waitingFinalization };
    }

    return info;
  }, [listing, pollMapData]);

  const showStakeCTA = useMemo(() => {
    const {
      canBeWhitelisted,
      verified,
      whitelisted,
      challengeExists,
    } = listingInfo;

    return (
      account &&
      !isOwner &&
      !challengeExists &&
      (canBeWhitelisted || !(canBeWhitelisted || whitelisted) || verified)
    );
  }, [account, listingInfo, isOwner]);

  const showStakeCard = !listingInfo.removed;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PointStateCard
          classes={classes}
          account={account}
          isOwner={isOwner}
          listing={listing}
          listingInfo={listingInfo}
          handleVerify={handleVerify}
          handleRemove={handleRemove}
          mapHref={primaryHref}
        />
      </Grid>

      {showStakeCard && (
        <Grid item xs={12}>
          <PointStakeCard
            classes={classes}
            pointStake={pointStake}
            challengeStake={challengeStake}
            showStakeCTA={showStakeCTA}
            handleChallenge={handleChallenge}
            helpText="The stake attests to the quality of information provided by the point owner. Anyone can challenge this point and try to remove it from the map."
          />
        </Grid>
      )}

      {challengeReason ? (
        <Grid item xs={12}>
          <ListCard label="Challenge reason">
            <Typography variant="body1">{challengeReason}</Typography>
          </ListCard>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default SideListCards;
