// React
import React from 'react';
import { Button } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import {
  metamaskEnabledSelector,
  metamaskInstalledSelector,
} from 'core/network/selectors';
import { metamaskEnabledDispatchRequest } from 'core/network/actions';

// Utils
import { isNil } from 'ramda';

// Styles
import { styled } from '@material-ui/core/styles';

const OrangeButton = styled(Button)({
  background: '#FA9E43',
  color: '#FFF',
  '&:hover': {
    background: '#AF6E2E',
  },
  lineHeight: 1.25,
  minHeight: 44,
  textTransform: 'none',
});

const MetaMaskUnlocked = Component => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    ({
      metamaskEnabled,
      metamaskInstalled,
      metamaskEnabledDispatchRequest,
    }) => {
      // when we are still bootstrapping, show nothing
      if (isNil(metamaskEnabled) || isNil(metamaskInstalled)) {
        return null;
      }

      // if (!metamaskInstalled) {
      //   return (
      //     <OrangeButton
      //       variant="contained"
      //       color="secondary"
      //       href="https://metamask.io/"
      //       target="_blank"
      //     >
      //       Install MetaMask
      //     </OrangeButton>
      //   );
      // }

      if (!metamaskEnabled) {
        return (
          <OrangeButton
            variant="contained"
            color="primary"
            onClick={metamaskEnabledDispatchRequest}
            disabled={!metamaskInstalled}
          >
            Connect
          </OrangeButton>
        );
      }

      return <Component />;
    }
  );
};

const mapStateToProps = state => ({
  metamaskEnabled: metamaskEnabledSelector(state),
  metamaskInstalled: metamaskInstalledSelector(state),
});

const mapDispatchToProps = {
  metamaskEnabledDispatchRequest,
};

export default MetaMaskUnlocked;
