// Redux
import { networkState } from 'core/network/sagas';

// Utils
import contractConfigMap from 'core/contract/config';
import { ethers, Contract, constants as EtherConstants } from 'ethers';
import { isNil } from 'ramda';

// NOTE: ethers does not allow the signer address to be changed, so we must create a new
// instance when the sender address will change
export const makeContractForAddress = (address, contractName) => {
  const contractConfig = contractConfigMap[contractName];
  if (isNil(contractConfig)) {
    throw new Error(`unknown contractName ${contractName}`);
  }

  const ethersProvider = new ethers.providers.Web3Provider(
    networkState.provider
  );

  return new Contract(
    contractConfig.deployedAddress,
    contractConfig.abi,
    ethersProvider.getSigner(address)
  );
};

export const makeReadOnlyContract = contractName =>
  makeContractForAddress(EtherConstants.AddressZero, contractName);
