import blocklyticsApi from './blocklytics-api/reducer';
import contract from './contract/reducer';
import network from './network/reducer';
import { drizzleReducers } from '@drizzle/store';

const reducers = {
  blocklyticsApi,
  contract,
  network,
  ...drizzleReducers,
};

export default reducers;
