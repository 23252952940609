// React
import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import spamList from '../utils/spam-registry.js';

const styles = theme => ({
  mediumEmphasis: {
    color: 'rgba(0,0,0,0.6)',
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
});

const PointRow = ({
  classes,
  listingHash,
  name: propName,
  address: propAddress,
}) => {
  const markedAsSpam = useMemo(() => {
    return spamList.includes(listingHash);
  }, [listingHash]);

  const [name, setName] = useState(propName);
  const [address, setAddress] = useState(propAddress);
  const [pointMetaData, setPointMetadata] = useState(null);

  useEffect(() => {
    setName(propName);
    setAddress(propAddress);
  }, [propName, propAddress]);

  useEffect(() => {
    if (markedAsSpam) return;

    fetch(`https://map-api-direct.foam.space/poi/details/${listingHash}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          throw new Error('Point not found');
        }
      })
      .then(data => {
        if (data !== undefined) setPointMetadata(data);
      })
      .catch(error =>
        fetch(
          `https://map-api-direct.foam.space/poi/removed/details/${listingHash}`
        )
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            }
          })
          .then(data => {
            if (data !== undefined) setPointMetadata(data);
          })
      );
  }, [markedAsSpam, listingHash]);

  useEffect(() => {
    if (markedAsSpam) {
      setName(<span style={{ fontStyle: 'oblique' }}>Marked as spam</span>);
      setAddress('\u00a0');
    } else if (pointMetaData) {
      if (pointMetaData['data']) {
        !name && setName(pointMetaData['data']['name']);
        !address && setAddress(pointMetaData['data']['address']);
      } else if (pointMetaData['doc'] && pointMetaData['doc']['data']) {
        !name && setName(pointMetaData['doc']['data']['name']);
        !address && setAddress(pointMetaData['doc']['data']['address']);
      }
    }
  }, [markedAsSpam, pointMetaData, name, address]);

  return (
    <Link
      to={{
        pathname: '/points/' + listingHash,
        state: {
          data: pointMetaData,
        },
      }}
      style={{ textDecoration: 'none' }}
    >
      <Typography variant="body2" component="p" color="textPrimary" noWrap>
        {name || <Skeleton />}
      </Typography>
      <Typography
        variant="body2"
        component="p"
        className={classes.lowEmphasis}
        noWrap
      >
        {address || <Skeleton />}
      </Typography>
    </Link>
  );
};

export default withStyles(styles)(PointRow);
