// React
import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Components
import StatCard from 'components/cards/StatCard';

// Redux
import { connect } from 'react-redux';
import { activeAddressSelector } from 'core/network/selectors';
import { activeAddressBalanceOfSelector } from 'core/contract/selectors';
import { activeAddressCartographerBalancesSelector } from 'core/blocklytics-api/selectors';

// Utils
import { displayTokensInt } from '../utils/ui-helpers';
import { isNil } from 'ramda';

const CartographerBalances = ({
  activeAddress,
  activeAddressBalanceOf,
  cartographerBalances,
}) => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        FOAM Balances
      </Typography>
    </Grid>

    {isNil(cartographerBalances) ? (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <StatCard
              label="Wallet"
              note="&nbsp;"
              stat={`${displayTokensInt(activeAddressBalanceOf)}`}
            />
          </Grid>

          <Grid item xs={6} sm={3}>
            <StatCard label="Registry" note="&nbsp;" stat={null} />
          </Grid>

          <Grid item xs={6} sm={3}>
            <StatCard label="Voting" note="&nbsp;" stat={null} />
          </Grid>

          <Grid item xs={6} sm={3}>
            <StatCard label="Signals" note="&nbsp;" stat={null} />
          </Grid>
        </Grid>
      </Grid>
    ) : cartographerBalances.length === 0 ? (
      <Grid item xs={12}>
        No balances to show
      </Grid>
    ) : (
      cartographerBalances.map(({ account, points, voting, signals }) => (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <StatCard
                label="Wallet"
                note="&nbsp;"
                stat={`${displayTokensInt(activeAddressBalanceOf)}`}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <StatCard label="Registry" note="&nbsp;" stat={points} />
            </Grid>

            <Grid item xs={6} sm={3}>
              <StatCard label="Voting" note="&nbsp;" stat={voting} />
            </Grid>

            <Grid item xs={6} sm={3}>
              <StatCard label="Signals" note="&nbsp;" stat={signals} />
            </Grid>
          </Grid>
        </Grid>
      ))
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  activeAddress: activeAddressSelector(state),
  activeAddressBalanceOf: activeAddressBalanceOfSelector(state),
  cartographerBalances: activeAddressCartographerBalancesSelector(state),
});

export default connect(mapStateToProps)(CartographerBalances);
