import React, { Component } from 'react';

// Material UI

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

// Images

import builtOnEth from '../BuiltOnEth.png';
import blocktopus from '../Blocktopus.png';

const styles = theme => ({
  footer: {
    marginTop: '3rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    top: 'auto',
    bottom: 0,
  },
  maxWidth: {
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  button: {
    margin: theme.spacing(1),
  },
});

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AppBar
          position="static"
          color="default"
          className={classes.footer}
          elevation={0}
        >
          <Toolbar className={classes.maxWidth}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  FOAM Tools
                </Typography>
                <Typography variant="caption" paragraph>
                  FOAM Tools is a third-party application for interacting with
                  the FOAM Map.
                </Typography>
                <Typography variant="caption" paragraph>
                  <img src={builtOnEth} alt="Built on Ethereum" width={150} />
                </Typography>
                <Typography variant="caption" paragraph>
                  FOAM Tools uses map data provided by{' '}
                  <Link
                    href="https://f-o-a-m.github.io/foam.developer/"
                    target="_blank"
                  >
                    FOAM API
                  </Link>
                  {' and '}
                  <Link
                    href="https://docs.blocklytics.org/apis/foam-map-api"
                    target="_blank"
                  >
                    Blocklytics API
                  </Link>
                  . Profile data provided by{' '}
                  <Link href="https://3box.io/" target="_blank">
                    3box.io
                  </Link>
                  {' and '}
                  <Link href="https://blockcities.co/" target="_blank">
                    BlockCities
                  </Link>
                  . Pricing data provided by Nomics.com{' '}
                  <Link
                    href="https://p.nomics.com/cryptocurrency-bitcoin-api"
                    target="_blank"
                  >
                    Cryptocurrency Market Data API
                  </Link>
                  .
                </Typography>
                <Typography variant="caption">
                  Map images ©{' '}
                  <Link
                    href="https://www.mapbox.com/about/maps/"
                    target="_blank"
                  >
                    Mapbox
                  </Link>{' '}
                  ©{' '}
                  <Link
                    href="http://www.openstreetmap.org/copyright"
                    target="_blank"
                  >
                    OpenStreetMap
                  </Link>
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Built by Blocklytics
                </Typography>
                <Typography variant="caption" paragraph>
                  Blocklytics Ltd is a UK company specializing in crypto data.{' '}
                  <Link href="https://blocklytics.org/" target="_blank">
                    Learn more on our website.
                  </Link>
                </Typography>
                <Typography variant="caption" paragraph>
                  <Link href="https://blocklytics.org/" target="_blank">
                    <img src={blocktopus} alt="Blocklytics" width={72} />
                  </Link>
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Contact
                </Typography>
                <Typography variant="caption" paragraph component={'div'}>
                  <ul>
                    <li>
                      <Link
                        href="https://discourse.foam.space/t/cartographer-tools-released-at-foam-tools/852"
                        target="_blank"
                      >
                        Discourse
                      </Link>
                    </li>
                    <li>
                      <Link href="mailto:hello@blocklytics.org" target="_blank">
                        Email
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://twitter.com/blocklytics"
                        target="_blank"
                      >
                        Twitter
                      </Link>
                    </li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Footer);
