import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

// Components
import Button from '@material-ui/core/Button';
import ListCard from '../../../components/cards/ListCard';

// Material UI
import Typography from '@material-ui/core/Typography';

// Icons
import OpenIcon from '@material-ui/icons/OpenInNew';

const ActionButtons = ({
  classes,
  account,
  isOwner,
  mapHref,
  handleVerify,
  handleRemove,
  listingInfo,
}) => {
  const primaryActionString = useMemo(() => {
    if (!listingInfo) return null;

    const {
      verified,
      removed,
      pending,
      whitelisted,
      canBeWhitelisted,
      challengeExists,
    } = listingInfo;

    if (isOwner) {
      if (verified) return 'Remove';
    }

    if (challengeExists) {
      // Waiting to be finalized
      if (listingInfo.waitingFinalization) return 'Finalize';
      else return 'Go to challenge';
    }
    if (canBeWhitelisted) return 'Verify';
    if (verified) return null;
    if (removed) return null;
    if (pending) {
      // Application period
      if (!(canBeWhitelisted || whitelisted || challengeExists)) return null;
      // Waiting to be verified
      else return 'Verify';
    }
  }, [listingInfo]);

  const onClickPropObj = useMemo(() => {
    if (!listingInfo) return null;

    const {
      pending, // Application period
      whitelisted, // Verified
      canBeWhitelisted, // Waiting to be verified
      challengeExists, // Challenged
      challengeID,
      verified,
      removed,
    } = listingInfo;

    // Insert logic for action, should return
    // an object like {onClick: function() {}}
    // or { href: mapHref } if action not available
    // // if (!account) return { href: mapHref, target: '_blank' };
    if (isOwner) {
      // Challenge: pending to be merged from other branch
      if (verified) return { onClick: handleRemove };
    }

    if (challengeExists) {
      // Waiting to be finalized
      if (listingInfo.waitingFinalization) return { onClick: handleVerify };
      else return { href: `/#/challenges/${challengeID}` };
    }
    if (canBeWhitelisted) return { onClick: handleVerify };
    if (verified) return null;
    if (removed) return null;
    if (pending) {
      // Application period
      if (!(canBeWhitelisted || whitelisted || challengeExists)) return null;
      // Waiting to be verified
      else return { onClick: handleVerify };
    }
  }, [handleVerify, listingInfo, isOwner]);

  return listingInfo ? (
    <React.Fragment>
      {/* Primary Action Button */}
      {(account || primaryActionString === 'Go to challenge') &&
        primaryActionString && (
          <Button
            className={classes.primaryActionButton}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            {...onClickPropObj}
          >
            {primaryActionString}
          </Button>
        )}

      {/* Secondary Action Button */}
      <Button
        className={classes.primaryActionButton}
        variant="text"
        size="large"
        color="primary"
        href={mapHref}
        target="_blank"
        fullWidth
      >
        View on FOAM Map <OpenIcon className={classes.rightIcon} />
      </Button>
    </React.Fragment>
  ) : (
    '\u00A0'
  );
};

const PointStateCard = ({
  classes,
  account,
  isOwner,
  listing,
  listingInfo,
  handleVerify,
  handleChallenge,
  handleRemove,
  mapHref,
  pollMapData,
}) => {
  const formattedApplicationExpiry = useMemo(() => {
    if (!listing) return null;

    const { applicationExpiry } = listing;

    return (
      applicationExpiry &&
      new Date(applicationExpiry * 1000).toLocaleDateString('default', {
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  }, [listing]);

  const stateString = useMemo(() => {
    if (listingInfo.loading) return null;

    const { removed, challengeExists, verified } = listingInfo;

    if (removed) return 'Removed';
    else if (challengeExists) return 'Challenged';
    else if (verified) return 'Verified';
    else return 'Pending';
  }, [listingInfo]);

  const subStateString = useMemo(() => {
    if (!listingInfo || listingInfo.loading) return null;

    const {
      removed,
      challengeExists,
      whitelisted,
      canBeWhitelisted,
    } = listingInfo;

    if (removed) return null;
    if (challengeExists) {
      const {
        votingUnderway,
        revealUnderway,
        waitingFinalization,
      } = listingInfo;

      if (votingUnderway) return 'Voting underway';
      if (revealUnderway) return 'Reveal underway';
      if (waitingFinalization) return 'Waiting to be finalized';
    }
    if (canBeWhitelisted) return 'Waiting to be Verified';
    if (whitelisted) return null;
    if (!(canBeWhitelisted || whitelisted || challengeExists))
      return `Application period ends ${formattedApplicationExpiry}`;
  }, [listingInfo, formattedApplicationExpiry]);

  return (
    <ListCard label="Point state">
      <Typography variant="h5">{stateString || <Skeleton />}</Typography>
      <Typography variant="body2" gutterBottom>
        {stateString ? subStateString : <Skeleton />}
      </Typography>

      <ActionButtons
        classes={classes}
        account={account}
        isOwner={isOwner}
        mapHref={mapHref}
        handleVerify={handleVerify}
        handleChallenge={handleChallenge}
        handleRemove={handleRemove}
        listing={listing}
        state={stateString}
        canBeWhitelisted={listingInfo && listingInfo.canBeWhitelisted}
        listingInfo={listingInfo}
      />
    </ListCard>
  );
};

export default PointStateCard;
