// React
import React from 'react';
import { Link } from 'react-router-dom';

// Material UI

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { makeStyles, useTheme } from '@material-ui/core/styles';

// Material Icons

import HomeIcon from '@material-ui/icons/Home';
import PointsIcon from '@material-ui/icons/Place';
import ChallengesIcon from '@material-ui/icons/Announcement';
import CartographersIcon from '@material-ui/icons/DirectionsRun';
import TokensIcon from '@material-ui/icons/Toll';
import ActivityIcon from '@material-ui/icons/ShowChart';
import OutcomesIcon from '@material-ui/icons/ScatterPlot';
import LinkIcon from '@material-ui/icons/OpenInNew';

// Components

import Footer from 'components/Footer';
import AccountTokenBalance from 'components/AccountTokenBalance';

const drawerWidth = 240;
const showLogin = true;

function ListItemLink(props) {
  if (props.href === undefined) {
    return <ListItem button component={Link} to={props.to} {...props} />;
  } else {
    return (
      <ListItem
        button
        component="a"
        href={props.href}
        target="_blank"
        {...props}
      />
    );
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    //     marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    paddingTop: theme.spacing(3),
    marginLeft: -theme.spacing(1),
  },
  navBarLogo: {
    backgroundImage:
      'url(https://raw.githubusercontent.com/blocklytics/brand-resources/master/emoji/cartographer.png)',
    height: 32,
    width: 32,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: -12,
      marginRight: 8,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: -4,
      marginRight: 6,
    },
  },
}));

const nav = [
  [
    { text: 'Home', to: '/', icon: <HomeIcon /> },
    //     { text: 'My Account', to: '/account', icon: <AccountIcon /> },
  ],
  [
    { text: 'Points', to: '/points', icon: <PointsIcon /> },
    { text: 'Challenges', to: '/challenges', icon: <ChallengesIcon /> },
    {
      text: 'Cartographers',
      to: '/cartographers',
      icon: <CartographersIcon />,
    },
  ],
  [
    { text: 'Activity', to: '/stats/activity', icon: <ActivityIcon /> },
    { text: 'Outcomes', to: '/stats/outcomes', icon: <OutcomesIcon /> },
    { text: 'Tokens', to: '/stats/tokens', icon: <TokensIcon /> },
  ],
  [
    {
      text: 'Daily Digest',
      href:
        'https://us19.campaign-archive.com/home/?u=e88f92fc087c0abfb94d292eb&id=b81a90cf65',
      icon: <LinkIcon />,
    },
    {
      text: 'FOAM Discourse',
      href: 'https://discourse.foam.space/',
      icon: <LinkIcon />,
    },
    { text: 'FOAM Map', href: 'https://map.foam.space/', icon: <LinkIcon /> },
  ],
];

const nav_headings = ['', 'EXPLORE', 'STATS', 'MORE'];

export default function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const pageTitle = props.pageTitle;
  const selectedSection = props.selectedSection;
  const selectedIndex = props.selectedIndex;

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Toolbar>
          <div className={classes.navBarLogo} />
          <Typography variant="h6" noWrap>
            FOAM Tools
          </Typography>
        </Toolbar>
      </div>
      <Divider />
      {nav.map((section, sectionIndex) => (
        <List
          key={'section-' + sectionIndex}
          aria-labelledby="list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="list-subheader"
              key={'section-subheader-' + sectionIndex}
            >
              {nav_headings[sectionIndex]}
            </ListSubheader>
          }
        >
          {section.map((navItem, navItemIndex) => (
            <ListItemLink
              key={'section-' + sectionIndex + '-item-' + navItemIndex}
              href={navItem.href}
              to={navItem.to}
              selected={
                selectedSection === sectionIndex &&
                selectedIndex === navItemIndex
              }
            >
              <ListItemIcon
                key={'img-section-' + sectionIndex + '-item-' + navItemIndex}
              >
                {navItem.icon}
              </ListItemIcon>
              <ListItemText
                key={'txt-section-' + sectionIndex + '-item-' + navItemIndex}
                primary={navItem.text}
              />
            </ListItemLink>
          ))}
        </List>
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open menu"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={5} sm={6}>
              <Typography variant="h6" noWrap>
                {pageTitle}
              </Typography>
            </Grid>
            <Grid item xs={7} sm={6} container justify="flex-end">
              {showLogin ? <AccountTokenBalance /> : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
        <Footer />
      </main>
    </div>
  );
}
