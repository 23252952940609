// React
import React from 'react';
import Routes from './routes';

// Redux
import { Provider } from 'react-redux';
import drizzleOptions from 'core/drizzle-options';
import DrizzleProvider from 'DrizzleProvider';
import configureStore from 'core/configure-store';

// Styles
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ContractLoader from './components/ContractLoader';
import DrizzleLoader from './components/DrizzleLoader';

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  palette: {
    secondary: {
      //       main: '#ffea00',
      main: '#303f9f',
    },
    primary: {
      main: '#303f9f',
      light: '#ffffff',
    },
  },
  overrides: {
    MuiToolBar: {
      gutters: {
        paddingLeft: defaultTheme.spacing(1),
        paddingRight: defaultTheme.spacing(1),
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2),
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
  },
});

const store = configureStore();

window.store = store;

const App = () => (
  <Provider store={store}>
    <DrizzleProvider options={drizzleOptions} store={store}>
      <MuiThemeProvider theme={theme}>
        <DrizzleLoader>
          <ContractLoader>
            <Routes />
          </ContractLoader>
        </DrizzleLoader>
      </MuiThemeProvider>
    </DrizzleProvider>
  </Provider>
);

export default App;
