// Redux
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { activeAddressSelector } from 'core/network/selectors';
import * as networkActions from 'core/network/actions';
import * as contractActions from 'core/contract/actions';

// Utils
import BN from 'bn.js';
import logError from 'utils/log-error';
import { makeContractForAddress } from 'utils/contract-helpers';
import { isNil } from 'ramda';

// --- State

const contractState = {
  lastActiveAddressLoaded: null,
};

// --- Contract functions

function* loadDataForActiveAddress() {
  try {
    const activeAddress = yield select(activeAddressSelector);

    // we only refresh if a change happened to prevent unwanted flashes of "empty" states
    if (contractState.lastActiveAddressLoaded !== activeAddress) {
      yield put({ type: contractActions.activeAddressDataActionClear });

      contractState.lastActiveAddressLoaded = activeAddress;
    }

    if (isNil(activeAddress)) return;

    const contract = yield call(makeContractForAddress, activeAddress, 'token');
    const balanceOf = yield call(contract.balanceOf, activeAddress);

    yield put({
      type: contractActions.activeAddressDataActionSet,
      payload: { balanceOf },
    });
  } catch (error) {
    logError(error, 'loadDataForActiveAddress::ERROR');
  }
}

function* claimReward(action) {
  const { challenge_id: challengeId, salt: saltHex } = action.payload;
  const salt = new BN(saltHex, 16).toString(10);

  try {
    yield put({
      type: contractActions.claimRewardActionTxCreate,
      payload: {
        challengeId,
      },
    });

    const activeAddress = yield select(activeAddressSelector);

    const contract = yield call(
      makeContractForAddress,
      activeAddress,
      'registry'
    );

    const rawTx = yield call(contract.claimReward, challengeId, salt);

    yield put({
      type: contractActions.claimRewardActionTxSent,
      payload: {
        challengeId,
        rawTx,
      },
    });

    const txReceipt = yield call(rawTx.wait);

    yield put({
      type: contractActions.claimRewardActionSuccess,
      payload: {
        challengeId,
        txReceipt,
      },
    });

    // reload balanceOf for active address
    yield call(loadDataForActiveAddress);
  } catch (error) {
    logError(error, 'claimReward::ERROR');

    yield put({
      type: contractActions.claimRewardActionError,
      payload: {
        challengeId,
        error,
      },
    });
  }
}

export default function* rootFoamContractSaga() {
  yield takeLatest(
    networkActions.activeAddressChangedAction,
    loadDataForActiveAddress
  );

  yield takeLatest(contractActions.claimRewardActionRequest, claimReward);
}
