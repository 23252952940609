// --- cartographerRewards

export const cartographerRewardsActionClear =
  'blocklytics-api/cartographer-rewards/clear';
export const cartographerRewardsActionSet =
  'blocklytics-api/cartographer-rewards/set';

// --- cartographerBalances

export const cartographerBalancesActionClear =
  'blocklytics-api/cartographer-balances/clear';
export const cartographerBalancesActionSet =
  'blocklytics-api/cartographer-balances/set';
