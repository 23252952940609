import React, { Component } from 'react';

// Material UI

import withStyles from '@material-ui/core/styles/withStyles';

// Highcharts

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Sankey from 'highcharts/modules/sankey';
import Export from 'highcharts/modules/exporting';
import Data from 'highcharts/modules/export-data';
import Offline from 'highcharts/modules/offline-exporting';
import Annotations from 'highcharts/modules/annotations';

Sankey(Highcharts);
Export(Highcharts);
Offline(Highcharts);
Data(Highcharts);
Annotations(Highcharts);

const styles = theme => ({});

class TokensStaked extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
  }

  render() {
    //     const { classes } = this.props;

    const data = this.props.data || [];
    const isLoading = this.props.isLoading || false;
    const exportingEnabled = this.props.exportingEnabled || false;
    const chartTitle = this.props.chartTitle || '';
    const chartSubtitle = this.props.chartSubtitle || '';

    // Loading logic

    if (this.chart && this.chart.current && this.chart.current.chart) {
      const chartObj = this.chart.current.chart;
      if (isLoading) {
        chartObj.showLoading();
      } else {
        chartObj.hideLoading();
      }
    }

    // Plot Options

    const annotations = [
      {
        point: {
          xAxis: 0,
          yAxis: 0,
          x: new Date(2018, 9 - 1, 11).getTime(), // note: 0-index months
          y: 0,
        },
        text: 'Map launch',
      },
      {
        point: {
          xAxis: 0,
          yAxis: 0,
          x: new Date(2018, 12 - 1, 10).getTime(), // note: 0-index months
          y: 0,
        },
        text: 'Proof of Use period ends',
      },
      {
        point: {
          xAxis: 0,
          yAxis: 0,
          x: new Date(2019, 4 - 1, 22).getTime(), // note: 0-index months
          y: 0,
        },
        text: 'Contentious challenges identified',
      },
    ];

    const plotOptions = {
      lang: { decimalPoint: '.', thousandsSep: ',' },
      loading: {
        labelStyle: {
          cursor: 'default',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
        style: { backgroundColor: 'transparent' },
      },
      credits: {
        text: 'Source: Blocklytics',
        position: { align: 'left', x: 16, y: -16 },
        href: null,
        style: {
          cursor: 'default',
          fontSize: '0.75rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
      },
      exporting: {
        enabled: exportingEnabled,
        buttons: {
          contextButton: { menuItems: ['downloadPNG', 'downloadCSV'] },
          // 	        contextButton: {
          //                   menuItems: null,
          //                   symbol: 'download',
          //                   onclick: function () {
          //                     this.exportChart();
          //                   }
          //                 }
        },
      },
      chart: {
        // 	      events: {
        // 	        load: function() {
        // // 	          this.xAxis[0].setExtremes(new Date(2018, 9, 7).getTime(), null);
        // //                   this.showResetZoom();
        // 	        }
        // 	      },
        // 	      height: 750,
        backgroundColor: 'transparent',
        spacing: [16, 16, 40, 16], //[0, 0, 24, 0],
        zoomType: 'x',
        resetZoomButton: {
          position: {
            align: 'left',
            x: 16,
          },
        },
        style: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      },
      title: {
        text: chartTitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.5rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.33',
          letterSpacing: '0em',
        },
      },
      subtitle: {
        text: chartSubtitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '0.875rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.46429em',
        },
      },
      xAxis: { type: 'datetime' },
      yAxis: { min: 0, title: null, opposite: true },
      tooltip: {
        shared: true,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderWidth: 1,
        shadow: false,
        useHTML: true,
        headerFormat: '<small>{point.key}</small><table>',
        pointFormat:
          '<tr><td><span style="color:{point.color}">\u25CF</span> {series.name}: </td>' +
          '<td style="text-align: right"><b>{point.y:,.0f}</b></td></tr>',
        footerFormat: '</table>',
      },
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        layout: 'horizontal',
        x: -8,
        y: -8,
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          marker: { radius: 0.5 },
          lineWidth: 2,
          fillOpacity: 1.0,
          threshold: null,
        },
        series: {
          states: { hover: { lineWidthPlus: 0 } },
        },
      },
      series: data,
      annotations: [
        {
          draggable: '',
          labelOptions: {
            crop: false,
            y: -7,
            allowOverlap: true,
            borderRadius: 5,
            backgroundColor: 'rgba(252, 255, 197, 1.0)',
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.25)',
          },
          labels: annotations,
        },
      ],
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={plotOptions}
        containerProps={{ className: 'chart' }}
        ref={this.chart}
      />
    );
  }
}

export default withStyles(styles)(TokensStaked);
