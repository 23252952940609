import React, { Component } from 'react';

// Material UI

import withStyles from '@material-ui/core/styles/withStyles';

// Highcharts

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Sankey from 'highcharts/modules/sankey';
import Export from 'highcharts/modules/exporting';
import Data from 'highcharts/modules/export-data';
import Offline from 'highcharts/modules/offline-exporting';
import Annotations from 'highcharts/modules/annotations';

Sankey(Highcharts);
Export(Highcharts);
Offline(Highcharts);
Data(Highcharts);
Annotations(Highcharts);

const styles = theme => ({});

class PointOutcomes extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
  }

  render() {
    //     const { classes } = this.props;

    const data = this.props.data || [];
    const isLoading = this.props.isLoading || false;
    const exportingEnabled = this.props.exportingEnabled || false;
    const chartTitle = this.props.chartTitle || '';
    const chartSubtitle = this.props.chartSubtitle || '';

    // Loading logic

    if (this.chart && this.chart.current && this.chart.current.chart) {
      const chartObj = this.chart.current.chart;
      if (isLoading) {
        chartObj.showLoading();
      } else {
        chartObj.hideLoading();
      }
    }

    // Plot Options

    const plotOptions = {
      lang: { decimalPoint: '.', thousandsSep: ',' },
      loading: {
        labelStyle: {
          cursor: 'default',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
        style: { backgroundColor: 'transparent' },
      },
      credits: {
        text: 'Source: Blocklytics',
        position: { align: 'left', x: 16, y: -16 },
        href: null,
        style: {
          cursor: 'default',
          fontSize: '0.75rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
      },
      exporting: {
        enabled: exportingEnabled,
        buttons: {
          contextButton: { menuItems: ['downloadPNG', 'downloadCSV'] },
          // 	        contextButton: {
          //                   menuItems: null,
          //                   symbol: 'download',
          //                   onclick: function () {
          //                     this.exportChart();
          //                   }
          //                 }
        },
      },
      chart: {
        type: 'sankey',
        // 				width: chartWidth,
        // 				height: chartHeight
        backgroundColor: 'transparent',
        spacing: [16, 16, 40, 16],
        style: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      },
      title: {
        text: chartTitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.5rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.33',
          letterSpacing: '0em',
        },
      },
      subtitle: {
        text: chartSubtitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '0.875rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.46429em',
        },
      },
      tooltip: {
        // 	      shared: true,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderWidth: 1,
        shadow: false,
        // 				useHTML: true,
        // 				headerFormat: '<small>{point.key}</small><table>',
        // 				pointFormat: '<tr><td><span style="color:{point.color}">\u25CF</span> {series.name}: </td>' +
        // 					'<td style="text-align: right"><b>{point.y:,.0f}</b></td></tr>',
        // 				footerFormat: '</table>',
        nodeFormat: '{point.name}: <b>{point.sum:,.0f}</b><br/>',
        pointFormat:
          '{point.fromNode.name} → {point.toNode.name}: <b>{point.weight:,.0f}</b><br/>',
      },
      series: [
        {
          name: chartTitle,
          curveFactor: 0.5,
          // 				minLinkWidth: 1,
          nodePadding: 20,
          nodeWidth: 45,
          keys: ['from', 'to', 'weight'],
          data: data,
          nodes: [
            {
              id: 'New points',
              color: '#3EB0EF',
              dataLabels: { align: 'left', x: 48 },
            },
            { id: 'Verified', color: '#3EB0EF' },
            { id: 'Currently on map', color: '#3EB0EF' },
            { id: 'Pending', color: '#3EB0EF' },
            { id: 'Removed, by owner', color: '#F37080' },
            { id: 'Removed, by vote', color: '#F37080' },
            { id: 'Challenged', color: '#FEB75D' },
            { id: 'Voting underway', color: '#FEB75D' },
          ],
          dataLabels: [
            {
              align: 'right',
              x: -48,
              padding: 0,
              style: {
                color: '#000000',
                fontSize: '1rem',
                fontWeight: '700',
                textOutline: false,
              },
              nodeFormat: '{point.id}: {point.sum:,.0f}',
            },
          ],
        },
      ],
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={plotOptions}
        containerProps={{ className: 'chart' }}
        ref={this.chart}
      />
    );
  }
}

export default withStyles(styles)(PointOutcomes);
