// --- activeAddressData

export const activeAddressDataActionClear =
  'foam-contract/active-address-data/clear';
export const activeAddressDataActionSet =
  'foam-contract/active-address-data/set';

// --- claimReward

export const claimRewardActionRequest = 'foam-contract/claim-reward/request';
export const claimRewardDispatchRequest = challengeData => ({
  type: claimRewardActionRequest,
  payload: challengeData,
});

export const claimRewardActionTxCreate = 'foam-contract/claim-reward/tx-create';
export const claimRewardActionTxSent = 'foam-contract/claim-reward/tx-sent';
export const claimRewardActionSuccess = 'foam-contract/claim-reward/success';
export const claimRewardActionError = 'foam-contract/claim-reward/error';
