// React
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

// Components
import CartographerBalances from 'components/CartographerBalances';
import CartographerRewards from 'components/CartographerRewards';
import Drawer from 'components/Drawer';
import MetaMaskUnlocked from 'components/MetaMaskUnlocked';

// Styles
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600,
    },
  },
});

const AccountLayout = MetaMaskUnlocked(() => (
  <React.Fragment>
    {' '}
    <Grid item xs={12}>
      <Typography
        variant="h6"
        align="center"
        style={{ background: '#FF6666', color: '#FFF', borderRadius: 2 }}
      >
        ⚠︎ This section is in development. Use at your own risk!
      </Typography>
    </Grid>
    <CartographerBalances />
    <CartographerRewards />{' '}
  </React.Fragment>
));

class AccountPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Drawer pageTitle="My Account" selectedSection={0} selectedIndex={1}>
        <Grid container className={classes.content} spacing={2}>
          <AccountLayout />
        </Grid>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(AccountPage));
