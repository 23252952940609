// Redux
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { activeAddressSelector } from 'core/network/selectors';
import * as networkActions from 'core/network/actions';
import * as blocklyticsApiActions from 'core/blocklytics-api/actions';

// Utils
import logError from 'utils/log-error';
import { isNil } from 'ramda';
import {
  getCartographerRewards,
  getCartographerBalances,
} from 'core/blocklytics-api/api';

function* getCartographerRewardsForActiveAddress() {
  try {
    const activeAddress = yield select(activeAddressSelector);

    if (isNil(activeAddress)) return;

    const rewardList = yield call(
      getCartographerRewards,
      '0x8175c0ffa6891a2bd149a9c86e1e034cb39c5cc1'
    );

    yield put({
      type: blocklyticsApiActions.cartographerRewardsActionSet,
      payload: { ethAddress: activeAddress, rewardList },
    });
  } catch (error) {
    logError(error, 'getCartographerRewardsForActiveAddress::ERROR');
  }
}

function* getCartographerBalancesForActiveAddress() {
  try {
    const activeAddress = yield select(activeAddressSelector);

    if (isNil(activeAddress)) return;

    const balanceList = yield call(getCartographerBalances, activeAddress);

    yield put({
      type: blocklyticsApiActions.cartographerBalancesActionSet,
      payload: { ethAddress: activeAddress, balanceList },
    });
  } catch (error) {
    logError(error, 'getCartographerBalancesForActiveAddress::ERROR');
  }
}

export default function* rootFoamContractSaga() {
  yield takeLatest(
    networkActions.activeAddressChangedAction,
    getCartographerRewardsForActiveAddress
  );

  yield takeLatest(
    networkActions.activeAddressChangedAction,
    getCartographerBalancesForActiveAddress
  );
}
