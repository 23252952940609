import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

// const { profileGraphQL, getProfile, getProfiles, getVerifiedAccounts } = require('3box/lib/api')
const Box = require('3box');

const styles = theme => ({
  padded: {
    padding: '8px 32px 8px 16px',
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  avatar: {
    background: '#BDBDBD',
  },
  bigAvatar: {
    background: '#BDBDBD',
    height: 160,
    width: 160,
    fontSize: 72,
    marginTop: -50, // should match ProfileCard .card marginTop
    boxShadow:
      '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)',
  },
});

class EthereumAvatar extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      profile: this.props.profile || null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.state.profile === null) {
      this.updateProfile();
    }
  }

  componentDidUpdate = prevProps => {
    const { address, profile } = this.props;
    if (address !== prevProps.address) {
      this.updateProfile();
    }

    if (profile && profile !== prevProps.profile) {
      this.setState({
        profile: profile,
      });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  updateProfile() {
    const address = this.props.address;

    // Reset the state
    if (this._isMounted) {
      this.setState({
        profile: null,
      });
    }

    // Load the profile
    Box.getProfile(address).then(profile => {
      if (this._isMounted) {
        this.setState({
          profile: profile,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const address = this.props.address || null;
    const isBig = this.props.isBig || false;

    const backgroundColor = address ? '#' + address.slice(2, 8) : '#BDBDBD';

    var imageSource = null;
    const profile = this.state.profile;
    if (
      profile &&
      profile['image'] &&
      profile['image'][0] &&
      profile['image'][0]['contentUrl'] &&
      profile['image'][0]['contentUrl']['/']
    ) {
      imageSource =
        'https://ipfs.infura.io:5001/api/v0/cat?arg=' +
        profile['image'][0]['contentUrl']['/'];
    }

    return (
      <Avatar
        component={Link}
        to={{
          pathname: '/cartographers/' + address,
          state: {
            profile: profile,
          },
        }}
        alt={address}
        src={imageSource}
        className={isBig ? classes.bigAvatar : classes.avatar}
        style={
          imageSource
            ? null
            : { background: backgroundColor, textDecoration: 'none' }
        }
      >
        0x
      </Avatar>
    );
  }
}

export default withStyles(styles)(EthereumAvatar);

//         opts={{ seed: address, spotcolor: "#888", color: "#EEE", bgcolor: "#AAA" }}
