import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import OpenIcon from '@material-ui/icons/OpenInNew';

import ListCard from './ListCard';
import HelpTooltip from '../shared/HelpTooltip';

const useStyles = makeStyles(theme => ({
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  primaryActionButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const PrimaryActionButton = ({ account, pollMapInfo, mapHref }) => {
  const classes = useStyles();

  const label = useMemo(() => {
    if (pollMapInfo.loading) return null;

    if (pollMapInfo.votingUnderway) return 'Vote';
    if (pollMapInfo.revealUnderway) return 'Reveal';
    return null;
  }, [pollMapInfo]);

  return (
    label && (
      <Button
        className={classes.primaryActionButton}
        variant="contained"
        size="large"
        color="primary"
        href={mapHref}
        target="_blank"
        disabled={mapHref ? false : true}
        fullWidth
      >
        {label}
      </Button>
    )
  );
};

const SecondaryActionButton = ({ account, pollMapInfo, mapHref }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.primaryActionButton}
      variant="text"
      size="large"
      color="primary"
      href={mapHref}
      target="_blank"
      fullWidth
    >
      View on FOAM Map <OpenIcon className={classes.rightIcon} />
    </Button>
  );
};

const ChallengeStateCard = ({
  account,
  listingHash,
  pollMapData,
  helpText,
}) => {
  const pollMapInfo = useMemo(() => {
    if (!pollMapData) return { loading: true };

    const {
      commitEndDate: strCommitEndDate,
      revealEndDate: strRevealEndDate,
      voteQuorum: strVoteQuorum,
      votesAgainst: strVotesAgainst,
      votesFor: strVotesFor,
    } = pollMapData;

    const now = Math.floor(Date.now() / 1000);
    const commitEndDateInt = Number.parseInt(strCommitEndDate);
    const revealEndDateInt = Number.parseInt(strRevealEndDate);
    const voteQuorum = Number.parseInt(strVoteQuorum);
    const votesAgainst = Number.parseInt(strVotesAgainst);
    const votesFor = Number.parseInt(strVotesFor);
    const totalVotes = votesFor + votesAgainst;
    const quorumPassed = (votesFor / totalVotes) * 100 > voteQuorum;
    const formattedRevealEndDate = new Date(
      revealEndDateInt * 1000
    ).toLocaleDateString('default', {
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    });

    const votingUnderway = commitEndDateInt > now;
    const revealUnderway = commitEndDateInt <= now && revealEndDateInt > now;
    const failed = revealEndDateInt <= now && !quorumPassed;
    const succeeded = revealEndDateInt <= now && quorumPassed;

    return {
      loading: false,
      votingUnderway,
      revealUnderway,
      failed,
      succeeded,
      formattedRevealEndDate,
    };
  }, [pollMapData]);

  const stateString = useMemo(() => {
    if (pollMapInfo.loading) return null;

    if (pollMapInfo.votingUnderway) return 'Voting underway';
    if (pollMapInfo.revealUnderway) return 'Reveal underway';
    if (pollMapInfo.failed) return 'Failed';
    if (pollMapInfo.succeeded) return 'Succeeded';
  }, [pollMapInfo]);

  const endTimeString = useMemo(() => {
    if (pollMapInfo.loading) return null;

    if (pollMapInfo.failed || pollMapInfo.succeeded)
      return `Challenge settled ${pollMapInfo.formattedRevealEndDate}`;
    if (pollMapInfo.votingUnderway)
      return `Voting ends ${pollMapInfo.formattedRevealEndDate}`;
    if (pollMapInfo.revealUnderway)
      return `Reveal ends ${pollMapInfo.formattedRevealEndDate}`;
    return pollMapInfo.formattedRevealEndDate;
  }, [pollMapInfo]);

  const primaryHref = useMemo(() => {
    if (!listingHash) return null;

    return `https://map.foam.space/#/dashboard/${listingHash}`;
  }, [listingHash]);

  // const commitEndDate = commitEnd ? new Date(commitEnd) : null;
  // const revealEndDate = revealEnd ? new Date(revealEnd) : null;
  // const finalizedAtDate = finalizedAt ? new Date(finalizedAt) : null;

  // const now = new Date();

  // var stateString,
  //   endTimeString,
  //   buttonString,
  //   primaryHref = null;

  // primaryHref = listingHash
  //   ? 'https://map.foam.space/#/dashboard/' + listingHash
  //   : null;
  // if (primaryHref && result === 'removed') {
  //   primaryHref += '/removed/';
  // }

  // if (result === 'failed') {
  //   stateString = 'Failed';
  //   endTimeString = finalizedAtDate
  //     ? 'Challenge settled ' +
  //       finalizedAtDate.toLocaleDateString([], {
  //         year: 'numeric',
  //         month: 'long',
  //         day: 'numeric',
  //         hour: '2-digit',
  //         minute: '2-digit',
  //       })
  //     : null;
  //   buttonString = 'Open map';
  // } else if (result === 'succeeded') {
  //   stateString = 'Succeeded';
  //   endTimeString = finalizedAtDate
  //     ? 'Challenge settled ' +
  //       finalizedAtDate.toLocaleDateString([], {
  //         year: 'numeric',
  //         month: 'long',
  //         day: 'numeric',
  //         hour: '2-digit',
  //         minute: '2-digit',
  //       })
  //     : null;
  //   buttonString = 'Open map';
  // } else if (result === 'pending' && revealEndDate > now) {
  //   if (now > commitEndDate) {
  //     stateString = 'Reveal period';
  //     endTimeString =
  //       'Reveal ends ' +
  //       revealEndDate.toLocaleDateString([], { weekday: 'long' }) +
  //       ' at ' +
  //       revealEndDate.toLocaleTimeString([], {
  //         hour: '2-digit',
  //         minute: '2-digit',
  //       });
  //     buttonString = 'Reveal now';
  //   } else {
  //     stateString = 'Voting underway';
  //     endTimeString =
  //       'Voting ends ' +
  //       commitEndDate.toLocaleDateString([], { weekday: 'long' }) +
  //       ' at ' +
  //       commitEndDate.toLocaleTimeString([], {
  //         hour: '2-digit',
  //         minute: '2-digit',
  //       });
  //     buttonString = 'Vote now';
  //   }
  // } else if (result === 'pending') {
  //   stateString = 'Waiting to be finalized';
  //   endTimeString =
  //     'Reveal ended ' +
  //     revealEndDate.toLocaleDateString([], {
  //       year: 'numeric',
  //       month: 'long',
  //       day: 'numeric',
  //       hour: '2-digit',
  //       minute: '2-digit',
  //     });
  //   buttonString = 'Finalize now';
  // }

  return (
    <ListCard label="Challenge state">
      <HelpTooltip
        text={helpText}
        style={{ position: 'absolute', top: 14, right: 14 }}
      />
      <Typography variant="h5">{stateString || <Skeleton />}</Typography>
      <Typography variant="body2">{endTimeString || <Skeleton />}</Typography>

      <PrimaryActionButton
        account={account}
        pollMapInfo={pollMapInfo}
        mapHref={primaryHref}
      />

      <SecondaryActionButton
        account={account}
        pollMapInfo={pollMapInfo}
        mapHref={primaryHref}
      />
    </ListCard>
  );
};

export default ChallengeStateCard;
