import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { generateContractsInitialState } from '@drizzle/store';
import drizzleOptions from './drizzle-options';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { createLogger } from 'redux-logger';

import allReducers from './all-reducers';
import allSagas from './all-sagas';
import logError from 'utils/log-error';

export default function configureStore() {
  // config sagas
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, x) => logError(error, `Saga Error: ${x}`),
  });

  const logger = createLogger({
    collapsed: true,
    duration: true,
  });

  // config redux
  const rootReducer = combineReducers(allReducers);
  const middlewares = applyMiddleware(logger, sagaMiddleware);
  const enhancers = composeWithDevTools(middlewares);

  // create store
  const initialState = {
    contracts: generateContractsInitialState(drizzleOptions),
  };

  const store = createStore(rootReducer, initialState, enhancers);

  // kick off sagas
  sagaMiddleware.run(allSagas);

  return store;
}
