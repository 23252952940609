import React, { Component } from 'react';

// Material UI

import withStyles from '@material-ui/core/styles/withStyles';

// Highcharts

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Export from 'highcharts/modules/exporting';
import Data from 'highcharts/modules/export-data';
import Offline from 'highcharts/modules/offline-exporting';

Export(Highcharts);
Offline(Highcharts);
Data(Highcharts);

const styles = theme => ({});

class ChallengeTimes extends Component {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
  }

  render() {
    //     const { classes } = this.props;

    const data = this.props.data || [];
    const isLoading = this.props.isLoading || false;
    const exportingEnabled = this.props.exportingEnabled || false;
    const chartTitle = this.props.chartTitle || '';
    const chartSubtitle = this.props.chartSubtitle || '';

    // Loading logic

    if (this.chart && this.chart.current && this.chart.current.chart) {
      const chartObj = this.chart.current.chart;
      if (isLoading) {
        chartObj.showLoading();
      } else {
        chartObj.hideLoading();
      }
    }

    // Plot Options

    const plotOptions = {
      lang: { decimalPoint: '.', thousandsSep: ',' },
      loading: {
        labelStyle: {
          cursor: 'default',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
        style: { backgroundColor: 'transparent' },
      },
      credits: {
        text: 'Source: Blocklytics',
        position: { align: 'left', x: 16, y: -16 },
        href: null,
        style: {
          cursor: 'default',
          fontSize: '0.75rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
      },
      exporting: {
        enabled: exportingEnabled,
        buttons: {
          contextButton: { menuItems: ['downloadPNG', 'downloadCSV'] },
          // 	        contextButton: {
          //                   menuItems: null,
          //                   symbol: 'download',
          //                   onclick: function () {
          //                     this.exportChart();
          //                   }
          //                 }
        },
      },
      chart: {
        // 	      events: {
        // 	        load: function() {
        // // 	          this.xAxis[0].setExtremes(new Date(2018, 9, 7).getTime(), null);
        // //                   this.showResetZoom();
        // 	        }
        // 	      },
        // 	      height: 750,
        backgroundColor: 'transparent',
        spacing: [16, 16, 40, 16], //[0, 0, 24, 0],
        type: 'scatter',
        zoomType: 'xy',
        resetZoomButton: {
          position: {
            align: 'right',
            x: -16,
          },
        },
        style: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
      },
      title: {
        text: chartTitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.5rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.33',
          letterSpacing: '0em',
        },
      },
      subtitle: {
        text: chartSubtitle,
        align: 'left',
        x: 0,
        style: {
          fontSize: '0.875rem',
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: '400',
          lineHeight: '1.46429em',
        },
      },
      xAxis: {
        min: 0,
        title: {
          text: 'Time on map (days)',
        },
      },
      yAxis: {
        // 	      min: 50,
        title: {
          text: 'Challenge stake (log)',
        },
        type: 'logarithmic',
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: 'rgba(0, 0, 0, 0.25)',
        borderWidth: 1,
        shadow: false,
        formatter: function() {
          function formatTime(x) {
            var formatted_x;
            if (x < 1) {
              // Less than 1 day
              if (x < 1 / 24) {
                // Less than 1 hour
                formatted_x = (x * 24 * 60).toLocaleString([], {
                  maximumFractionDigits: 0,
                });
                return formatted_x === '1'
                  ? '1 minute'
                  : formatted_x + ' minutes';
              } else {
                formatted_x = (x * 24).toLocaleString([], {
                  maximumFractionDigits: 0,
                });
                return formatted_x === '1' ? '1 hour' : formatted_x + ' hours';
              }
            } else {
              formatted_x = x.toLocaleString([], { maximumFractionDigits: 0 });
              return formatted_x === '1' ? '1 day' : formatted_x + ' days';
            }
          }

          const formatString =
            '<small><span style="color:' +
            this.point.color +
            '">\u25CF</span> Challenge #' +
            this.point.challenge_id +
            '</small><table>' +
            '<tr><td>Challenge stake: </td>' +
            '<td style="text-align: right"><b>' +
            this.point.y.toLocaleString([], { maximumFractionDigits: 0 }) +
            ' FOAM</b></td></tr>' +
            '<tr><td>Time on map: </td>' +
            '<td style="text-align: right"><b>' +
            formatTime(this.point.x) +
            '</b></td></tr>' +
            '</table>';

          return formatString;
        },
        useHTML: true,
        // 				headerFormat: '<small>{point.key}, {this.point.challenge_id}</small><table>',
        // 				pointFormat: '<tr><td><span style="color:{point.color}">\u25CF</span> Challenge stake: </td>' +
        // 					'<td style="text-align: right"><b>{point.y:,.0f} FOAM</b></td></tr>' +
        // 					'<tr><td><span style="color:{point.color}">\u25CF</span> Time on map: </td>' +
        // 					'<td style="text-align: right"><b>{point.x:,.0f} {#plural(point.x:,.0f, day, days}</b></td></tr>',
        // 				footerFormat: '</table>',
      },
      legend: {
        enabled: false,
        align: 'left',
        verticalAlign: 'top',
        layout: 'horizontal',
        x: -8,
        y: -8,
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
          },
        },
        series: {
          states: { hover: { lineWidthPlus: 0 } },
        },
      },
      series: [
        {
          type: 'scatter',
          name: 'Challenges',
          color: 'rgba(254, 190, 108, 0.25)', // converted #FEBE6C
          data: data,
        },
      ],
    };

    console.log(data);

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={plotOptions}
        containerProps={{ className: 'chart' }}
        ref={this.chart}
      />
    );
  }
}

export default withStyles(styles)(ChallengeTimes);
