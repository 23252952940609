// React
import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';

// Material UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Components
import EthereumAvatar from '../components/EthereumAvatar';
import Drawer from '../components/Drawer';
import PointRow from '../components/PointRow';

const useStyles = makeStyles(theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  textField: {
    width: '100%',
  },
  paper: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
}));

const DebouncedSearchInput = ({ classes, handleSearchChange }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearchChange(value);
    }, 200);

    return () => {
      clearTimeout(handler);
    };
  }, [value, handleSearchChange]);

  return (
    <TextField
      id="search-points"
      placeholder="Search Points"
      variant="outlined"
      className={classes.textField}
      value={value}
      onChange={e => {
        e.preventDefault();
        setValue(e.target.value);
      }}
      margin="normal"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

const Points = ({ activeAddress }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);

    fetch(
      'https://api.blocklytics.org/foam/v0/points?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          Promise.reject();
        }
      })
      .then(data => {
        if (data !== undefined) {
          const aggregatedData = data.map(row => ({
            ...row,
            searchString: `${row.name}.${row.address}`.toLowerCase(),
          }));

          setData(aggregatedData);
          setLoading(false);
          // pages: 100, //todo
        }
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  useEffect(() => {
    if (searchQuery !== '')
      setFilteredData(
        data.filter(row => {
          if (row.name === null && row.address === null) return false;

          const searchTerm = searchQuery.toLowerCase();
          return row.searchString.includes(searchTerm);
        })
      );
    else setFilteredData(data);
  }, [data, searchQuery]);

  // Filter data (default is "all")
  // Set headerTextForTimestampRow
  const [selectedValue, setSelectedValue] = useState('all');
  const now = useMemo(() => new Date(), []);
  const [headerTextForTimestampRow, setHeaderTextForTimestampRow] = useState(
    'Added'
  );
  const [accessorForTimestampRow, setAccessorForTimestampRow] = useState(
    'createdAt'
  );

  const tableData = useMemo(() => {
    let headerTextForTimestampRow = 'Added';
    let accessorForTimestampRow = 'createdAt';
    let tableData = filteredData;

    if (selectedValue === 'pending') {
      tableData = filteredData.filter(
        row => row.verifiedAt === null && row.removedAt === null
      );
    } else if (selectedValue === 'verified') {
      headerTextForTimestampRow = 'Verified';
      accessorForTimestampRow = 'verifiedAt';
      tableData = filteredData.filter(
        row => row.verifiedAt !== null && row.removedAt === null
      );
    } else if (selectedValue === 'challenged') {
      headerTextForTimestampRow = 'Reveal Ends';
      accessorForTimestampRow = 'revealEnds';
      tableData = filteredData.filter(row => {
        return (
          (row.removedAt === null &&
            row.revealEnds !== null &&
            new Date(row.revealEnds) < now &&
            row.finalizedAt === null) ||
          (row.removedAt === null &&
            row.revealEnds !== null &&
            new Date(row.revealEnds) >= now)
        );
      });
    } else if (selectedValue === 'removed') {
      headerTextForTimestampRow = 'Removed';
      accessorForTimestampRow = 'removedAt';
      tableData = filteredData.filter(row => row.removedAt !== null);
    } else if (selectedValue === 'myPoints') {
      tableData = filteredData.filter(
        row => row.owner.toLowerCase() === activeAddress.toLowerCase()
      );
    }

    setHeaderTextForTimestampRow(headerTextForTimestampRow);
    setAccessorForTimestampRow(accessorForTimestampRow);

    return tableData;
  }, [selectedValue, filteredData, now, activeAddress]);

  const columns = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'owner',
        sortable: false,
        width: 60,
        headerClassName: 'center',
        Cell: props => {
          return <EthereumAvatar address={props.value} />;
        },
      },
      {
        Header: 'Point',
        accessor: 'listingHash',
        sortable: false,
        minWidth: 200,
        headerClassName: 'center',
        Cell: props => {
          return (
            <PointRow
              name={props.original.name}
              listingHash={props.value}
              address={props.original.address}
            />
          );
        },
      },
      {
        Header: 'Stake',
        accessor: 'stake',
        style: { textAlign: 'right' },
        width: 120,
        Cell: props => {
          return (
            <React.Fragment>
              <Typography variant="body2" component="p">
                {props.value
                  ? props.value.toLocaleString([], {
                      maximumFractionDigits: 0,
                    }) + ' FOAM'
                  : '-'}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.lowEmphasis}
              >
                &nbsp;
              </Typography>
            </React.Fragment>
          );
        },
      },
      {
        Header: headerTextForTimestampRow,
        accessor: accessorForTimestampRow,
        style: { textAlign: 'right' },
        width: 160,
        Cell: props => {
          const date = new Date(props.value);
          const date_options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          const time_options = { hour: '2-digit', minute: '2-digit' };

          return (
            <React.Fragment>
              <Typography variant="body2" component="p">
                {date.toLocaleDateString('default', date_options)}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.lowEmphasis}
              >
                {date.toLocaleTimeString('default', time_options)}
              </Typography>
            </React.Fragment>
          );
        },
      },
      {
        Header: 'State',
        accessor: 'listingHash',
        sortable: false,
        style: { textAlign: 'left' },
        width: 160,
        headerClassName: 'center',
        Cell: props => {
          const row = props.row._original;
          var state = null;
          var substate = null;
          if (row.removedAt !== null) {
            state = 'Removed';
          } else if (new Date(row.votingEnds) >= now) {
            state = 'Challenged';
            substate = 'Voting underway';
          } else if (new Date(row.revealEnds) >= now) {
            state = 'Challenged';
            substate = 'Reveal period';
          } else if (
            row.revealEnds !== null &&
            new Date(row.revealEnds) < now &&
            row.finalizedAt === null
          ) {
            state = 'Challenged';
            substate = 'Waiting to be finalized';
          } else if (row.verifiedAt !== null) {
            state = 'Verified';
          } else if (new Date(row.applicationEnds) < now) {
            state = 'Pending';
            substate = 'Waiting to be verified';
          } else {
            state = 'Pending';
          }

          return (
            <React.Fragment>
              <Typography variant="body2" component="p">
                {state}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.lowEmphasis}
              >
                {substate || '\u00A0'}
              </Typography>
            </React.Fragment>
          );
        },
      },
    ];
  }, [classes, now, headerTextForTimestampRow, accessorForTimestampRow]);

  return (
    <Drawer pageTitle="Points" selectedSection={1} selectedIndex={0}>
      <Grid container className={classes.content} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Points table
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Filter by state</FormLabel>
            <RadioGroup
              row
              aria-label="Filter"
              name="filter"
              className={classes.group}
              value={selectedValue}
              onChange={e => {
                e.preventDefault();
                setSelectedValue(e.target.value);
              }}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="default" />}
                label="All"
              />
              <FormControlLabel
                value="pending"
                control={<Radio color="default" />}
                label="Pending"
              />
              <FormControlLabel
                value="verified"
                control={<Radio color="default" />}
                label="Verified"
              />
              <FormControlLabel
                value="challenged"
                control={<Radio color="default" />}
                label="Challenged"
              />
              <FormControlLabel
                value="removed"
                control={<Radio color="default" />}
                label="Removed"
              />
              {activeAddress && (
                <FormControlLabel
                  value="myPoints"
                  control={<Radio color="default" />}
                  label="My Points"
                />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <DebouncedSearchInput
              classes={classes}
              handleSearchChange={setSearchQuery}
            />
          </form>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <ReactTable
              columns={columns}
              data={tableData}
              resizable={false}
              defaultSorted={[{ id: accessorForTimestampRow, desc: true }]}
              defaultSortDesc={true}
              showPageSizeOptions={false}
              defaultPageSize={10}
              loading={loading}
              className="-highlight"
            />
          </Paper>
        </Grid>
      </Grid>
    </Drawer>
  );
};

const mapStateToProps = ({ accounts }) => ({ activeAddress: accounts[0] });

export default connect(mapStateToProps)(withRouter(Points));
