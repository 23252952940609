import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Material UI
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// Icons
import PhoneIcon from '@material-ui/icons/Phone';
import LinkIcon from '@material-ui/icons/Link';
import OpenIcon from '@material-ui/icons/OpenInNew';

import spamList from '../../utils/spam-registry.js';

var Geohash = require('latlon-geohash');

const POINT_MARKER_VERIFIED = 'rgba(40,175,96,1)';
const POINT_HALO_VERIFIED = 'rgba(40,175,96,0.25)';
const POINT_MARKER_REMOVED = 'rgba(255,0,0,1)';
const POINT_HALO_REMOVED = 'rgba(255,0,0,0.25)';
const POINT_MARKER_PENDING = 'rgba(48,130,237,1)';
const POINT_HALO_PENDING = 'rgba(48,130,237,0.25)';
const POINT_MARKER_CHALLENGED = 'rgba(244,127,103,1)';
const POINT_HALO_CHALLENGED = 'rgba(244,127,103,0.25)';
// const FOAM_COLOR_UNREVEALED = "#AEAEAE"

const styles = theme => ({
  media: {
    height: 140,
    background: '#CAD2D3',
    backgroundSize: 'cover',
    //     margin: 'auto',
    backgroundPosition: 'center',
  },
  markerWrapper: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    //     lineHeight: 140,
    verticalAlign: 'middle',
    top: 0,
    left: 0,
  },
  marker: {
    display: 'inline-block',
    margin: '15px auto',
    height: 10,
    width: 10,
    backgroundColor: POINT_MARKER_CHALLENGED,
    borderRadius: 999,
    opacity: 1.0,
  },
  markerHalo: {
    margin: '50px auto',
    height: 40,
    width: 40,
    backgroundColor: POINT_HALO_CHALLENGED,
    borderRadius: 999,
  },
  mediumEmphasis: {
    color: 'rgba(0,0,0,0.6)',
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
  },
  iconSmall: {
    fontSize: 16,
  },
});

class PointCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpam: false,
    };
  }

  render() {
    const { classes } = this.props;
    const { data, listingHash } = this.props;
    const { showSpam } = this.state;

    var pointName,
      pointAddress,
      pointDescription,
      pointGeohash,
      pointPhone,
      pointTags,
      pointWeb,
      pointState;
    if (data) {
      if (data['data']) {
        pointName = data['data']['name'];
        pointAddress = data['data']['address'];
        pointDescription = data['data']['description'];
        pointGeohash = data['data']['geohash'];
        pointPhone = data['data']['phone'];
        pointTags = data['data']['tags'];
        pointWeb = data['data']['web'];
      } else if (data['doc']) {
        pointName = data['doc']['data']['name'];
        pointAddress = data['doc']['data']['address'];
        pointDescription = data['doc']['data']['description'];
        pointGeohash = data['doc']['data']['geohash'];
        pointPhone = data['doc']['data']['phone'];
        pointTags = data['doc']['data']['tags'];
        pointWeb = data['doc']['data']['web'];
      }

      if (data['state']) {
        if (data['state']['status'] && data['state']['status']['type']) {
          pointState = data['state']['status']['type'];
        } else {
          pointState = 'removed';
        }
      }
    }

    var isSmall;
    this.props.variant === 'small' ? (isSmall = true) : (isSmall = false);

    if (listingHash === null) {
      pointName = null;
      pointAddress = null;
      pointDescription = null;
    } else if (spamList.includes(listingHash) && !showSpam) {
      pointName = <span style={{ fontStyle: 'oblique' }}>Marked as spam</span>;
      pointDescription = isSmall ? (
        '\u00a0'
      ) : (
        <Button
          color="primary"
          size="small"
          onClick={() => this.setState({ showSpam: true })}
        >
          Show content
        </Button>
      );
      pointAddress = '\u00a0';
      pointPhone = null;
      pointTags = null;
      pointWeb = null;
    }

    var imageSource, primaryHref;
    if (pointGeohash) {
      const decoded_geohash = Geohash.decode(pointGeohash);
      const lat = decoded_geohash['lat'];
      const long_ = decoded_geohash['lon'];
      const zoom = isSmall ? 3 : 12;
      imageSource =
        'https://api.mapbox.com/styles/v1/mapbox/light-v9/static/' +
        long_ +
        ',' +
        lat +
        ',' +
        zoom +
        '/600x140?access_token=pk.eyJ1IjoiY2FsZWJzaGVyaWRhbiIsImEiOiJjanVlaGhzdHIwM2N2NDNwZDk2ajl1aDFrIn0.m-l0wrUzRhWOxRnTkJYEwQ&attribution=false&logo=false';
      primaryHref = 'https://map.foam.space/#/dashboard/' + listingHash;
      if (pointState === 'removed') {
        primaryHref += '/removed/';
      }
      //         primaryHref += "/?lng=" + long_ + "&lat=" + lat + "&zoom=15"
    }

    const secondaryHref = listingHash ? '/points/' + listingHash : null;

    var markerColor, markerHaloColor;
    if (pointState === 'applied') {
      markerColor = POINT_MARKER_PENDING;
      markerHaloColor = POINT_HALO_PENDING;
    } else if (pointState === 'listing') {
      markerColor = POINT_MARKER_VERIFIED;
      markerHaloColor = POINT_HALO_VERIFIED;
    } else if (pointState === 'removed') {
      markerColor = POINT_MARKER_REMOVED;
      markerHaloColor = POINT_HALO_REMOVED;
    } else {
      markerColor = POINT_MARKER_CHALLENGED;
      markerHaloColor = POINT_HALO_CHALLENGED;
    }

    //     const imagePath = "/images/cards/" + id + ".png"

    return (
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={{ pathname: secondaryHref }}
          disabled={secondaryHref ? false : true}
        >
          <CardMedia className={classes.media} image={imageSource} />
          {pointState ? (
            <div className={classes.markerWrapper}>
              <div
                className={classes.markerHalo}
                style={{ backgroundColor: markerHaloColor }}
              >
                <div
                  className={classes.marker}
                  style={{ backgroundColor: markerColor }}
                />
              </div>
            </div>
          ) : null}
        </CardActionArea>

        <CardHeader
          title={pointName || <Skeleton />}
          subheader={pointAddress || <Skeleton />}
        />
        <CardContent>
          <Typography variant="body1" component="p" paragraph>
            {pointDescription || <Skeleton count={3} />}
          </Typography>
          {isSmall ? null : (
            <React.Fragment>
              {pointTags
                ? pointTags.map((item, index) => (
                    <Chip
                      component="span"
                      className={classes.mediumEmphasis}
                      style={{ marginLeft: index === 0 ? 0 : 8 }}
                      label={item}
                      variant="outlined"
                      key={index}
                    />
                  ))
                : null}
            </React.Fragment>
          )}
        </CardContent>
        {isSmall ? <Divider light={true} /> : null}
        <CardActions>
          {isSmall ? (
            <React.Fragment>
              <Button
                color="primary"
                href={primaryHref}
                target="_blank"
                disabled={primaryHref ? false : true}
              >
                Open Map
                <OpenIcon
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                />
              </Button>
              <Button
                color="primary"
                component={Link}
                to={secondaryHref}
                disabled={secondaryHref ? false : true}
              >
                More
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {pointWeb ? (
                <IconButton
                  color="primary"
                  aria-label="Website"
                  href={pointWeb}
                  target="_blank"
                >
                  <LinkIcon />
                </IconButton>
              ) : null}
              {pointPhone ? (
                <IconButton
                  color="primary"
                  aria-label="Phone"
                  href={'tel:' + pointPhone}
                >
                  <PhoneIcon />
                </IconButton>
              ) : null}
            </React.Fragment>
          )}
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(PointCard);
