import SwitchableProvider, {
  readOnlyProviderProtocol,
  readOnlyProviderURL,
} from './network/switchable-provider';

const switchableProvider = SwitchableProvider.create();

const drizzleOptions = {
  contracts: [],
  web3: {
    block: false,
    fallback: {
      type: readOnlyProviderProtocol,
      url: readOnlyProviderURL,
    },
    customProvider: switchableProvider,
    web3: {
      block: false,
      fallback: {
        type: readOnlyProviderProtocol,
        url: readOnlyProviderURL,
      },
      customProvider: switchableProvider,
    },
  },
  syncAlways: true,
  polls: {
    accounts: 1500,
  },
};

export default drizzleOptions;
