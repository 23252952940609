// Redux
import * as contractActions from './actions';

// Utils
import { mergeWithTopLevelProp } from 'utils/reducer';

// --- Initial State
export const defaultActiveAddressData = Object.freeze({
  balanceOf: null,
});

export const defaultClaimRewardsTxs = Object.freeze({});

const initialState = Object.freeze({
  activeAddressData: defaultActiveAddressData,
  claimRewardTxs: defaultClaimRewardsTxs,
});

// --- Reducer

export default function contractReducer(state = initialState, action = {}) {
  switch (action.type) {
    // --- activeAddressData

    case contractActions.activeAddressDataActionClear: {
      return mergeWithTopLevelProp(
        'activeAddressData',
        defaultActiveAddressData,
        state
      );
    }
    case contractActions.activeAddressDataActionSet: {
      return mergeWithTopLevelProp('activeAddressData', action.payload, state);
    }

    // --- claimReward

    case contractActions.claimRewardActionTxCreate: {
      const { challengeId } = action.payload;

      return mergeWithTopLevelProp(
        'claimRewardTxs',
        {
          [challengeId]: { status: 'tx-create' },
        },
        state
      );
    }

    case contractActions.claimRewardActionTxSent: {
      const { challengeId, rawTx } = action.payload;

      return mergeWithTopLevelProp(
        'claimRewardTxs',
        {
          [challengeId]: { status: 'tx-sent', rawTx },
        },
        state
      );
    }

    case contractActions.claimRewardActionSuccess: {
      const { challengeId, txReceipt } = action.payload;

      return mergeWithTopLevelProp(
        'claimRewardTxs',
        {
          [challengeId]: { status: 'tx-success', txReceipt },
        },
        state
      );
    }

    case contractActions.claimRewardActionError: {
      const { challengeId, error } = action.payload;

      return mergeWithTopLevelProp(
        'claimRewardTxs',
        {
          [challengeId]: { status: 'tx-error', error },
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}
