import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Nav components

import Drawer from '../components/Drawer';

// Chart components

import PointOutcomes from '../components/charts/PointOutcomes';
import ChallengeTimes from '../components/charts/ChallengeTimes';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
});

class Outcomes extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pointOutcomesData: [],
      pointOutcomesIsLoading: false,
      challengeTimesData: [],
      challengeTimesIsLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updatePointOutcomesData();
    this.updateChallengeTimesData();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  updatePointOutcomesData() {
    this.setState({
      pointOutcomesIsLoading: true,
    });

    fetch(
      'https://api.blocklytics.org/foam/v0/stats/activity/point-outcomes?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({
            pointOutcomesData: data,
            pointOutcomesIsLoading: false,
          });
        }
      });
  }

  plotDataForChallengeTimes(data) {
    var seriesData = [];
    if (data.length === 0) {
      return seriesData;
    }

    data.forEach(row => {
      seriesData.push({
        x: row.challenge_time_mins / (60 * 24), // convert to days
        y: row.challenge_deposit,
        challenge_id: row.challenge_id,
      });
    });

    return seriesData;
  }

  updateChallengeTimesData() {
    this.setState({
      pointOutcomesIsLoading: true,
    });

    fetch(
      'https://api.blocklytics.org/foam/v0/stats/outcomes/challenge-times?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      //     fetch("http://127.0.0.1:8080/v0/stats/outcomes/challenge-times")
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({
            challengeTimesData: this.plotDataForChallengeTimes(data),
            challengeTimesIsLoading: false,
          });
        }
      });
  }

  render() {
    const { classes } = this.props;

    const pointOutcomes = {
      isLoading: this.state.pointOutcomesIsLoading,
      data: this.state.pointOutcomesData,
      exportingEnabled: false,
      chartTitle: 'FOAM Point Outcomes',
      chartSubtitle:
        'Through ' +
        new Date().toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
    };

    const challengeTimes = {
      isLoading: this.state.challengeTimesIsLoading,
      data: this.state.challengeTimesData,
      exportingEnabled: false,
      chartTitle: 'FOAM Challenge Times',
      chartSubtitle: 'Time on map vs Tokens staked',
    };

    return (
      <Drawer pageTitle="Outcomes" selectedSection={2} selectedIndex={1}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <PointOutcomes
                isLoading={pointOutcomes.isLoading}
                data={pointOutcomes.data}
                exportingEnabled={pointOutcomes.exportingEnabled}
                chartTitle={pointOutcomes.chartTitle}
                chartSubtitle={pointOutcomes.chartSubtitle}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <ChallengeTimes
                isLoading={challengeTimes.isLoading}
                data={challengeTimes.data}
                exportingEnabled={challengeTimes.exportingEnabled}
                chartTitle={challengeTimes.chartTitle}
                chartSubtitle={challengeTimes.chartSubtitle}
              />
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(Outcomes));
