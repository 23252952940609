import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import OpenIcon from '@material-ui/icons/OpenInNew';

import spamList from '../../utils/spam-registry.js';

var Geohash = require('latlon-geohash');

const FOAM_COLOR_VERIFY = '#28AF60';
const FOAM_COLOR_REMOVE = '#FF0000';

const styles = theme => ({
  card: {
    width: 240,
    marginRight: 16,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 24,
    transition: 'transform 0.1s, box-shadow 0.1s',
    '&:hover': {
      //       transform: 'scale(1.1)',
      zIndex: 1199, // zIndex of drawer is 1200
      boxShadow:
        '0px 1px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 3px 3px -2px rgba(0,0,0,0.12)',
    },
  },
  media: {
    height: 140,
    background: '#CAD2D3',
  },
  marker: {
    position: 'absolute',
    top: 65,
    left: 115,
    height: 10,
    width: 10,
    backgroundColor: '#f47f67',
    borderRadius: 999,
  },
  markerHalo: {
    position: 'absolute',
    top: 50, // height of media - height / 2
    left: 100, // width of media - width / 2
    height: 40,
    width: 40,
    backgroundColor: '#f47f67',
    opacity: 0.25,
    borderRadius: 999,
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  keepText: {
    color: FOAM_COLOR_VERIFY,
  },
  removeText: {
    color: FOAM_COLOR_REMOVE,
  },
  linearColorPrimary: {
    backgroundColor: FOAM_COLOR_REMOVE, // Background
  },
  linearBarColorPrimary: {
    backgroundColor: FOAM_COLOR_VERIFY, // Progress bar
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
  },
  iconSmall: {
    fontSize: 16,
  },
});

class ResultCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  isValidNumber(number) {
    if (
      isFinite(number) &&
      number !== null &&
      number !== undefined &&
      !isNaN(number)
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    const listingHash = this.props.listingHash;

    if (listingHash) {
      fetch('https://map-api-direct.foam.space/poi/details/' + listingHash)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw Error('Point not found');
          }
        })
        .then(data => {
          if (data !== undefined) {
            this.setState({ data: data });
          }
        })
        .catch(error =>
          fetch(
            'https://map-api-direct.foam.space/poi/removed/details/' +
              listingHash
          )
            .then(response => {
              if (response.status >= 200 && response.status < 300) {
                return response.json();
              } else {
                Promise.reject();
              }
            })
            .then(data => {
              if (data !== undefined) {
                this.setState({ data: data });
              }
            })
        );
    }
  }

  render() {
    const { classes } = this.props;

    const listingHash = this.props.listingHash || null;

    const endTime = this.props.endTime || null;
    const endTimeDate = endTime ? new Date(endTime) : null;

    const subtitle = endTimeDate
      ? 'Reveal ended on ' +
        endTimeDate.toLocaleDateString([], { weekday: 'long' })
      : null;

    const stake = this.props.stake || null;
    const rewardPool = stake ? stake * 0.4 : null;
    const result = this.props.result || null;
    const id = this.props.id || 0;

    const data = this.state.data;

    var pointName, pointGeohash;
    if (data && data['data']) {
      pointName = data['data']['name'];
      pointGeohash = data['data']['geohash'];
    } else if (data && data['doc']) {
      pointName = data['doc']['data']['name'];
      pointGeohash = data['doc']['data']['geohash'];
    }

    pointName = pointName
      ? pointName.length > 44
        ? pointName.substring(0, 44) + '...'
        : pointName
      : null;

    if (spamList.includes(listingHash)) {
      pointName = <span style={{ fontStyle: 'oblique' }}>Marked as spam</span>;
    }

    const geohash = pointGeohash ? pointGeohash : null;
    var lat,
      long_ = null;
    var imageSource = null;

    if (geohash !== null) {
      const decoded_geohash = Geohash.decode(geohash);
      lat = decoded_geohash['lat'];
      long_ = decoded_geohash['lon'];
      const zoom = 3;
      imageSource =
        'https://api.mapbox.com/styles/v1/mapbox/light-v9/static/' +
        long_ +
        ',' +
        lat +
        ',' +
        zoom +
        ',0,0/240x140/?access_token=pk.eyJ1IjoiY2FsZWJzaGVyaWRhbiIsImEiOiJjanVlaGhzdHIwM2N2NDNwZDk2ajl1aDFrIn0.m-l0wrUzRhWOxRnTkJYEwQ&attribution=false&logo=false';
    }

    const stakeString = rewardPool
      ? rewardPool.toLocaleString([], { maximumFractionDigits: 0 }) +
        ' FOAM reward pool'
      : null;

    var resultString;
    var markerColor;
    if (result === 'failed') {
      resultString = 'Remains on map';
      markerColor = FOAM_COLOR_VERIFY;
    } else if (result === 'succeeded') {
      resultString = 'Removed from map';
      markerColor = FOAM_COLOR_REMOVE;
    } else {
      resultString = 'Waiting to be finalized';
    }

    const primaryHref =
      listingHash && long_ && lat
        ? 'https://map.foam.space/#/dashboard/' +
          listingHash +
          '/?lng=' +
          long_ +
          '&lat=' +
          lat +
          '&zoom=15'
        : null;
    const secondaryHref = id ? '/challenges/' + id : null;

    return (
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={{ pathname: secondaryHref, state: { data: data } }}
          disabled={secondaryHref ? false : true}
        >
          <CardMedia className={classes.media} image={imageSource} />
          {imageSource ? (
            <React.Fragment>
              <div
                className={classes.marker}
                style={{ backgroundColor: markerColor }}
              />
              <div
                className={classes.markerHalo}
                style={{ backgroundColor: markerColor }}
              />
            </React.Fragment>
          ) : null}
          <CardContent>
            <Typography variant="h5" component="h2">
              {pointName || <Skeleton />}
            </Typography>
            {result === 'failed' ? (
              <Typography
                variant="body2"
                component="p"
                paragraph
                className={classes.keepText}
              >
                {resultString}
              </Typography>
            ) : null}
            {result === 'succeeded' ? (
              <Typography
                variant="body2"
                component="p"
                paragraph
                className={classes.removeText}
              >
                {resultString}
              </Typography>
            ) : null}
            {result === null ? (
              <Typography variant="body2" component="p" paragraph>
                {stakeString && resultString ? resultString : <Skeleton />}
              </Typography>
            ) : null}
            <Typography
              variant="body2"
              component="p"
              className={classes.mediumEmphasis}
            >
              {stakeString || <Skeleton />}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.lowEmphasis}
            >
              {subtitle || <Skeleton />}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Divider light={true} />
        <CardActions>
          <Button
            color="primary"
            href={primaryHref}
            target="_blank"
            disabled={primaryHref ? false : true}
          >
            {result ? 'Map' : 'Finalize'}
            <OpenIcon
              className={classNames(classes.rightIcon, classes.iconSmall)}
            />
          </Button>
          <Button
            color="primary"
            component={Link}
            to={{ pathname: secondaryHref, state: { data: data } }}
            disabled={secondaryHref ? false : true}
          >
            More
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(ResultCard);
