// React
import React from 'react';
import MetaMaskUnlocked from 'components/MetaMaskUnlocked';
import EthereumAvatar from 'components/EthereumAvatar';
import { Grid } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { activeAddressSelector } from 'core/network/selectors';
import { activeAddressBalanceOfSelector } from 'core/contract/selectors';

// Utils
import { displayTokens } from 'utils/ui-helpers';

// Styles
import { styled } from '@material-ui/core/styles';

const OutlineGrid = styled(Grid)({
  border: '1px solid white',
  borderRadius: '50px',
});

const AccountTokenBalance = ({ activeAddress, activeAddressBalanceOf }) => (
  <Grid container alignItems="center" spacing={2} justify="flex-end">
    <OutlineGrid item>{`${displayTokens(activeAddressBalanceOf)}`}</OutlineGrid>
    <Grid item>
      <EthereumAvatar address={activeAddress} />
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  activeAddress: activeAddressSelector(state),
  activeAddressBalanceOf: activeAddressBalanceOfSelector(state),
});

export default MetaMaskUnlocked(connect(mapStateToProps)(AccountTokenBalance));
