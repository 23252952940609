import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import OpenIcon from '@material-ui/icons/OpenInNew';

import spamList from '../../utils/spam-registry.js';

var Geohash = require('latlon-geohash');

const styles = theme => ({
  card: {
    width: 240,
    marginRight: 16,
    marginLeft: 8,
    marginTop: 8,
    marginBottom: 24,
  },
  media: {
    height: 140,
    background: '#CAD2D3',
  },
  marker: {
    position: 'absolute',
    top: 65,
    left: 115,
    height: 10,
    width: 10,
    backgroundColor: '#f47f67',
    borderRadius: 999,
  },
  markerHalo: {
    position: 'absolute',
    top: 50, // height of media - height / 2
    left: 100, // width of media - width / 2
    height: 40,
    width: 40,
    backgroundColor: '#f47f67',
    opacity: 0.25,
    borderRadius: 999,
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
  },
  iconSmall: {
    fontSize: 16,
  },
});

class VotingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  isValidNumber(number) {
    if (
      isFinite(number) &&
      number !== null &&
      number !== undefined &&
      !isNaN(number)
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    const listingHash = this.props.listingHash;

    if (listingHash) {
      fetch('https://map-api-direct.foam.space/poi/details/' + listingHash)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            Promise.reject();
          }
        })
        .then(data => {
          if (data !== undefined) {
            this.setState({
              data: data,
            });
          }
        });
    }
  }

  render() {
    const { classes } = this.props;

    const listingHash = this.props.listingHash || '0x';
    const width = this.props.width || 240;

    const endTime = this.props.endTime || null;
    const endTimeDate = endTime ? new Date(endTime) : null;
    const subtitle = endTimeDate
      ? 'Voting ends ' +
        endTimeDate.toLocaleDateString([], { weekday: 'long' }) +
        ' at ' +
        endTimeDate.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : null;

    const stake = this.props.stake || null;
    const rewardPool = stake ? stake * 0.4 : null;
    const votes = this.props.votes;
    const voters = this.props.voters;
    const id = this.props.id || 0;

    const data = this.state.data;
    var title = data
      ? data['data']['name'].length > 44
        ? data['data']['name'].substring(0, 44) + '...'
        : data['data']['name']
      : null;

    if (spamList.includes(listingHash)) {
      title = <span style={{ fontStyle: 'oblique' }}>Marked as spam</span>;
    }

    const geohash = data ? data['data']['geohash'] : null;
    var lat,
      long_ = null;
    var imageSource = null;

    if (geohash !== null) {
      const decoded_geohash = Geohash.decode(geohash);
      lat = decoded_geohash['lat'];
      long_ = decoded_geohash['lon'];
      const zoom = 3;
      imageSource =
        'https://api.mapbox.com/styles/v1/mapbox/light-v9/static/' +
        long_ +
        ',' +
        lat +
        ',' +
        zoom +
        ',0,0/' +
        width +
        'x140/?access_token=pk.eyJ1IjoiY2FsZWJzaGVyaWRhbiIsImEiOiJjanVlaGhzdHIwM2N2NDNwZDk2ajl1aDFrIn0.m-l0wrUzRhWOxRnTkJYEwQ&attribution=false&logo=false';
    }

    const stakeString = rewardPool
      ? rewardPool.toLocaleString([], { maximumFractionDigits: 0 }) +
        ' FOAM reward pool'
      : null;

    var voteString =
      votes && voters
        ? votes.toLocaleString([], { maximumFractionDigits: 0 }) +
          ' votes from ' +
          voters.toLocaleString([], { maximumFractionDigits: 0 }) +
          ' voters'
        : null;
    if (votes && voters === 1) {
      voteString =
        votes.toLocaleString([], { maximumFractionDigits: 0 }) +
        ' votes from ' +
        voters.toLocaleString([], { maximumFractionDigits: 0 }) +
        ' voter';
    }
    if (votes === 0 && voters === 0) {
      voteString = 'No votes committed';
    }

    const primaryHref =
      listingHash && long_ && lat
        ? 'https://map.foam.space/#/dashboard/' +
          listingHash +
          '/?lng=' +
          long_ +
          '&lat=' +
          lat +
          '&zoom=15'
        : null;
    const secondaryHref = id ? '/challenges/' + id : null;

    return (
      <Card className={classes.card}>
        <CardActionArea
          component={Link}
          to={{ pathname: secondaryHref, state: { data: data } }}
          disabled={secondaryHref ? false : true}
        >
          <CardMedia className={classes.media} image={imageSource} />
          {imageSource ? (
            <React.Fragment>
              <div className={classes.marker} />
              <div className={classes.markerHalo} />
            </React.Fragment>
          ) : null}
          <CardContent>
            <Typography variant="h5" component="h2">
              {title || <Skeleton />}
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              {subtitle || <Skeleton />}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.mediumEmphasis}
            >
              {stakeString || <Skeleton />}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              className={classes.lowEmphasis}
              paragraph
            >
              {voteString || <Skeleton />}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Divider light={true} />
        <CardActions>
          <Button
            color="primary"
            href={primaryHref}
            target="_blank"
            disabled={primaryHref ? false : true}
          >
            Vote
            <OpenIcon
              className={classNames(classes.rightIcon, classes.iconSmall)}
            />
          </Button>
          <Button
            color="primary"
            component={Link}
            to={{ pathname: secondaryHref, state: { data: data } }}
            disabled={secondaryHref ? false : true}
          >
            More
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(VotingCard);
