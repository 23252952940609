// Redux
import * as blocklyticsApiActions from './actions';

// Utils
import { mergeWithTopLevelProp } from 'utils/reducer';

export const defaultCartographerRewards = Object.freeze({});
export const defaultCartographerBalances = Object.freeze({});

const initialState = Object.freeze({
  // cartographerRewards: { [ethAddress]: Array<Reward> }
  cartographerRewards: defaultCartographerRewards,
  cartographerBalances: defaultCartographerBalances,
});

export default function blocklyticsApiReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case blocklyticsApiActions.cartographerRewardsActionClear: {
      return mergeWithTopLevelProp(
        'cartographerRewards',
        {
          [action.payload.ethAddress]: null,
        },
        state
      );
    }

    case blocklyticsApiActions.cartographerRewardsActionSet: {
      return mergeWithTopLevelProp(
        'cartographerRewards',
        {
          [action.payload.ethAddress]: action.payload.rewardList,
        },
        state
      );
    }

    case blocklyticsApiActions.cartographerBalancesActionClear: {
      return mergeWithTopLevelProp(
        'cartographerBalances',
        {
          [action.payload.ethAddress]: null,
        },
        state
      );
    }

    case blocklyticsApiActions.cartographerBalancesActionSet: {
      return mergeWithTopLevelProp(
        'cartographerBalances',
        {
          [action.payload.ethAddress]: action.payload.balanceList,
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}
