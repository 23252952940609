// React
import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Components
import CartographerRewardCard from 'components/cards/CartographerRewardCard';

// Redux
import { connect } from 'react-redux';
import { activeAddressCartographerRewardsUnclaimedSelector } from 'core/blocklytics-api/selectors';

// Utils
import { isNil } from 'ramda';

const CartographerRewards = ({ unclaimedRewards }) => (
  <React.Fragment>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Voting rewards
      </Typography>
    </Grid>

    {isNil(unclaimedRewards) ? (
      <Grid item xs={12}>
        <CartographerRewardCard />
      </Grid>
    ) : unclaimedRewards.length === 0 ? (
      <Grid item xs={12}>
        No rewards to claim
      </Grid>
    ) : (
      unclaimedRewards.map(challengeData => (
        <Grid item xs={12} key={challengeData.challenge_id}>
          <CartographerRewardCard
            challengeData={challengeData}
            note={null} //"Wait? High gas costs"
          />
        </Grid>
      ))
    )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  unclaimedRewards: activeAddressCartographerRewardsUnclaimedSelector(state),
});

export default connect(mapStateToProps)(CartographerRewards);
