// Redux
import { defaultActiveAddressData, defaultClaimRewardsTxs } from './reducer';

// Utils
import { compose, pathOr, path } from 'ramda';
import { createSelector } from 'reselect';

// --- activeAddressData

export const activeAddressDataSelector = pathOr(defaultActiveAddressData, [
  'contract',
  'activeAddressData',
]);

export const activeAddressBalanceOfSelector = compose(
  path(['balanceOf']),
  activeAddressDataSelector
);

// --- claimRewardTxs

export const claimRewardTxsSelector = pathOr(defaultClaimRewardsTxs, [
  'contract',
  'claimRewardTxs',
]);

export const claimRewardTxForChallengeIdSelector = (state, challengeId) => {
  const claimRewardTxs = claimRewardTxsSelector(state);
  return claimRewardTxs[challengeId];
};
