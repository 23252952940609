const config = {
  baseUrl: 'https://api.blocklytics.org/foam/v0',
  //   baseUrl: 'http://127.0.0.1:8080/foam/v0',
  apiKey: 'AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ',
};

const buildApiRoute = path => `${config.baseUrl}/${path}?key=${config.apiKey}`;

export const getCartographerRewards = async ethAddress => {
  const res = await fetch(buildApiRoute(`cartographers/${ethAddress}/rewards`));

  return res.json();
};

export const getCartographerBalances = async ethAddress => {
  const res = await fetch(
    buildApiRoute(`cartographers/${ethAddress}/balances`)
  );

  return res.json();
};
