import React, { Component, useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import SearchIcon from '@material-ui/icons/Search';

import EthereumAvatar from '../components/EthereumAvatar';
import Drawer from '../components/Drawer';

const Box = require('3box');

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  toggleButtonGroup: {
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  textField: {
    width: `calc(100% - ${2 * theme.spacing(1)}px)`,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  toggleButton: {
    width: '100%',
    height: 56,
  },
  paper: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
});

const CartographerCell = ({ value: address, toggle, data, classes }) => {
  const [name, setName] = useState(null);

  useEffect(() => {
    Box.getProfile(address).then(profile => {
      if (profile && profile.name) setName(profile.name);
      else setName('Anonymous Account');
    });
  }, [address]);

  return (
    <Link
      to={{
        pathname: '/cartographers/' + address,
        state: {
          cartographersData: toggle === 'allTime' ? data : null,
        },
      }}
      style={{ textDecoration: 'none' }}
    >
      <Typography variant="body2" component="p" color="textPrimary" noWrap>
        {name || <Skeleton width={200} />}
      </Typography>
      <Typography
        className={classes.lowEmphasis}
        variant="body2"
        component="p"
        noWrap
      >
        {address}
      </Typography>
    </Link>
  );
};

class Cartographers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      retrievedNames: false,
      footer: null,
      loading: false,
      searchQuery: '',
      filtered: [],
      toggle: 'allTime',
      subtitle: null,
    };
  }

  footerForData(data) {
    var result = {
      text: 'Total',
      points: 0,
      challenges: 0,
      votes: 0,
    };

    data.forEach((item, index) => {
      result['points'] += item.points_on_map;
      result['challenges'] += item.challenges_started;
      result['votes'] += item.votes_revealed;
    });

    return result;
  }

  componentDidMount() {
    this.updateCartographersData();
  }

  // componentDidUpdate(_, prevState) {
  //   const { data, retrievedNames } = this.state;

  //   if (!retrievedNames && data.length !== 0 && prevState.data !== data) {
  //     setTimeout(this.getUserNames, 2000);
  //   }
  // }

  updateCartographersData(value = null) {
    this.setState({
      data: [],
      footer: null,
      loading: true,
      subtitle: null,
    });

    var url =
      'https://api.blocklytics.org/foam/v0/cartographers?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ';
    var subtitle = 'Ranked by all activity';

    if (value === 'currentMonth') {
      const now = new Date();
      url += '&from=' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-01';
      subtitle =
        'Ranked by ' +
        now.toLocaleString('default', { month: 'long', year: 'numeric' }) +
        ' activity';
    } else if (value === 'previousMonth') {
      const now = new Date();
      var lastDayOfMonth = new Date(
        now.getFullYear(),
        now.getMonth(),
        0
      ).getDate();
      var prev = now;
      prev.setMonth(prev.getMonth() - 1);
      url +=
        '&from=' + prev.getFullYear() + '-' + (prev.getMonth() + 1) + '-01';
      url +=
        '&to=' +
        prev.getFullYear() +
        '-' +
        (prev.getMonth() + 1) +
        '-' +
        lastDayOfMonth;
      subtitle =
        'Ranked by ' +
        prev.toLocaleString('default', { month: 'long', year: 'numeric' }) +
        ' activity';
    }

    fetch(url)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          Promise.reject();
        }
      })
      .then(respData => {
        const data = respData.map(row => ({
          ...row,
          searchString: row.user && row.user.toLowerCase(),
        }));

        if (data !== undefined) {
          this.setState({
            data: data,
            footer: this.footerForData(data),
            loading: false,
            subtitle: subtitle,
          });
        }
      });
  }

  getUserNames = async () => {
    const { data } = this.state;

    Box.getProfiles(data.map(row => row.user)).then(profiles => {
      console.log('profiles', profiles);
    });
    // const dataWithNames = data.map(row => {
    //   Box.getProfiles(row.user)
    //     .then(profile => {
    //       const name = (profile && profile.name) || 'Anonymous Account';
    //       console.log('Name', name);
    //       return {
    //         ...row,
    //         name,
    //         searchString: `${name}.${row.user}`.toLowerCase(),
    //       };
    //     })
    //     .catch(e => {
    //       console.log('Error', e);
    //     });
    // });
    // console.log('>> Done Getting names:', dataWithNames);
    // this.setState({ data: dataWithNames, retrievedNames: true });
  };

  handleChange = event => {
    this.setState({
      searchQuery: event.target.value,
      filtered: { accessor: 'account', value: event.target.value },
    });
  };

  handleToggle = (event, value) => {
    this.setState({
      toggle: value,
    });

    this.updateCartographersData(value);
  };

  render() {
    const { classes } = this.props;

    const loading = this.state.loading;
    var data = this.state.data;
    const footer = this.state.footer;
    const toggle = this.state.toggle;
    const subtitle = this.state.subtitle;

    const searchQuery = this.state.searchQuery;
    if (searchQuery) {
      // filter data
      data = data.filter(row => {
        return row.searchString.includes(searchQuery.toLowerCase());
      });
    }

    const columns = [
      {
        Header: '',
        accessor: 'user',
        sortable: false,
        width: 60,
        headerClassName: 'center',
        Cell: props => {
          return <EthereumAvatar address={props.value} />;
        },
      },
      {
        Header: 'Cartographer',
        accessor: 'user',
        sortable: false,
        minWidth: 180,
        headerClassName: 'center',
        Cell: props => {
          // console.log('props', props);
          return (
            <CartographerCell
              {...props}
              classes={classes}
              toggle={toggle}
              data={data}
            />
          );
        },
        Footer: (
          <Typography variant="body2" component="p">
            <strong>{footer ? footer['text'] : ''}</strong>
          </Typography>
        ),
      },
      {
        Header: 'Points',
        accessor: 'points_on_map',
        style: { textAlign: 'right' },
        width: 110,
        Cell: props => {
          return (
            <Typography variant="body2" component="p">
              {props.value
                ? props.value.toLocaleString([], { maximumFractionDigits: 0 })
                : '-'}
            </Typography>
          );
        },
        Footer: footer ? (
          <Typography variant="body2" component="p">
            <strong>
              {footer['points'].toLocaleString([], {
                maximumFractionDigits: 0,
              })}
            </strong>
          </Typography>
        ) : null,
      },
      {
        Header: 'Challenges',
        accessor: 'challenges_started',
        style: { textAlign: 'right' },
        width: 110,
        Cell: props => {
          return (
            <Typography variant="body2" component="p">
              {props.value
                ? props.value.toLocaleString([], { maximumFractionDigits: 0 })
                : '-'}
            </Typography>
          );
        },
        Footer: footer ? (
          <Typography variant="body2" component="p">
            <strong>
              {footer['challenges'].toLocaleString([], {
                maximumFractionDigits: 0,
              })}
            </strong>
          </Typography>
        ) : null,
      },
      {
        Header: 'Votes',
        accessor: 'votes_revealed',
        style: { textAlign: 'right' },
        width: 90,
        Cell: props => {
          return (
            <Typography variant="body2" component="p">
              {props.value
                ? props.value.toLocaleString([], { maximumFractionDigits: 0 })
                : '-'}
            </Typography>
          );
        },
        Footer: footer ? (
          <Typography variant="body2" component="p">
            <strong>
              {footer['votes'].toLocaleString([], { maximumFractionDigits: 0 })}
            </strong>
          </Typography>
        ) : null,
      },
      {
        Header: 'Map Rewards',
        accessor: 'map_rewards',
        style: { textAlign: 'right' },
        width: 140,
        Cell: props => {
          return (
            <Typography variant="body2" component="p">
              {props.value
                ? props.value.toLocaleString([], { maximumFractionDigits: 0 }) +
                  ' FOAM'
                : '-'}
            </Typography>
          );
        },
      },
    ];

    return (
      <Drawer pageTitle="Cartographers" selectedSection={1} selectedIndex={2}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {subtitle || '\u00A0'}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row-reverse"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={7} lg={5} xl={4}>
              <ToggleButtonGroup
                value={toggle}
                exclusive
                onChange={this.handleToggle}
                className={classes.toggleButtonGroup}
              >
                <ToggleButton value="allTime" className={classes.toggleButton}>
                  All Time
                </ToggleButton>
                <ToggleButton
                  value="currentMonth"
                  className={classes.toggleButton}
                >
                  This Month
                </ToggleButton>
                <ToggleButton
                  value="previousMonth"
                  className={classes.toggleButton}
                >
                  Last Month
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid item xs={12} sm={12} md={5} lg={7} xl={8}>
              <form noValidate autoComplete="off">
                <TextField
                  id="search-cartographers"
                  placeholder="Search Cartographers"
                  variant="outlined"
                  className={classes.textField}
                  value={this.state.searchQuery}
                  onChange={this.handleChange}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ReactTable
                columns={columns}
                data={data}
                resizable={false}
                defaultSorted={[{ id: 'points_on_map', desc: true }]}
                defaultSortDesc={true}
                showPageSizeOptions={false}
                defaultPageSize={10}
                loading={loading}
                className="-highlight"
              />
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(Cartographers));
