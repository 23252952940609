import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const FailedConnectionScreen = () => {
  return (
    <main>
      <h1>
        <span role="img" aria-label="danger">
          ⚠️
        </span>
      </h1>
      <p>
        This browser has no connection to the Ethereum network. Please use the
        Chrome/FireFox extension MetaMask, or dedicated Ethereum browsers Mist
        or Parity.
      </p>
    </main>
  );
};

const LoadingScreen = () => {
  return (
    <main style={{ display: 'flex', height: '100vh' }}>
      <div style={{ margin: 'auto' }}>
        <img
          src="https://raw.githubusercontent.com/blocklytics/brand-resources/master/emoji/cartographer.png"
          alt="Detective Logo"
          style={{ height: '25vh' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ marginLeft: 4 }}>Loading...</span>
        </div>
      </div>
    </main>
  );
};

const DrizzleLoader = ({ web3, drizzleStatus, children }) => {
  // Display a web3 warning.
  if (web3.status === 'failed') return <FailedConnectionScreen />;

  // Load the dapp.
  if (drizzleStatus.initialized && web3.status === 'initialized')
    return Children.only(children);

  // Display a loading indicator.
  return <LoadingScreen />;
};

DrizzleLoader.contextTypes = {
  drizzle: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    drizzleStatus: state.drizzleStatus,
    web3: state.web3,
  };
};

export default connect(mapStateToProps)(DrizzleLoader);
