import React, { useMemo, useState, useCallback, useEffect } from 'react';

// Components
import FullScreenDialog, {
  FullScreenDialogContent,
  StepIndicators,
  Step,
  dialogStyles,
  TxDetailsWrapper,
  TxDetailRow,
} from '../../../components/FullScreenDialog';

// Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

// Redux
import { connect } from 'react-redux';
import { activeAddressBalanceOfSelector } from 'core/contract/selectors';

// Utils
import { displayTokens } from 'utils/ui-helpers';

// Styles
import { makeStyles } from '@material-ui/styles';

// const BN = Web3.utils.BN;

const useStyles = makeStyles(dialogStyles);
const useAdditionalStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 90,
    textAlign: 'right',
  },
}));

const uploadChallengeReason = reason => {
  return new Promise((resolve, reject) => {
    const content = { reason };

    fetch('https://map-api-direct.foam.space:443/challenge/ipfs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(content),
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        resolve(resData);
      })
      .catch(e => reject(e));
  });
};

const isValidInput = input => /^([0-9]*)$/.test(input);
const isValidAmount = amount => {
  if (/^([0-9]+)$/.test(amount) && parseInt(amount) > 0) return true;
  return false;
};

const Step1 = ({
  activeAddressBalanceOf,
  currentStep,
  onClose,
  goToNextStep,
  pointStake,
  stakeAmount,
  setStakeAmount,
}) => {
  const classes = useStyles();
  const additionalClasses = useAdditionalStyles();

  const onChange = useCallback(
    e => {
      const input = e.target.value;
      if (isValidInput(input)) setStakeAmount(input);
    },
    [setStakeAmount]
  );

  const disableStakeButton = useMemo(() => {
    return parseInt(stakeAmount) > parseInt(pointStake);
  }, [stakeAmount, pointStake]);

  const validAmount = useMemo(() => {
    if (!isValidAmount(stakeAmount)) return false;

    const amount = parseInt(stakeAmount);
    if (amount < 50) return false;

    // NOTE: Having difficulties with comparing against BN
    // const amountBN = new BN(amount);
    // console.log('amountBN', amountBN);
    // console.log('activeAddressBalanceOf', activeAddressBalanceOf.gt(amountBN));
    return true;
  }, [stakeAmount /*, activeAddressBalanceOf*/]);

  return (
    <Step
      stepIndex={0}
      currentStep={currentStep}
      header="New challenge"
      subheader="Stake FOAM tokens to start a new challenge."
      actions={
        <React.Fragment>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="text"
              color="primary"
              size="large"
              onClick={() => onClose()}
            >
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                goToNextStep();
              }}
              disabled={!validAmount || disableStakeButton}
            >
              Stake {stakeAmount} FOAM
            </Button>
          </Grid>
        </React.Fragment>
      }
      noPadding
    >
      <TxDetailsWrapper>
        <TxDetailRow description="Minimum stake" value="50 FOAM" divider />
        <TxDetailRow
          description="Maximum stake"
          value={`${pointStake} FOAM`}
          divider
        />
        <TxDetailRow
          description="Your balance"
          value={displayTokens(activeAddressBalanceOf)}
          divider
        />
        <TxDetailRow
          description="Amount to stake"
          value={
            <React.Fragment>
              <Input
                id="stake-amount"
                classes={{ input: additionalClasses.textField }}
                autoFocus
                value={stakeAmount}
                onChange={onChange}
                margin="dense"
                // style={{ textAlign: 'right' }}
              />{' '}
              FOAM
            </React.Fragment>
          }
        />
      </TxDetailsWrapper>
    </Step>
  );
};

const Step2 = ({
  currentStep,
  goToNextStep,
  goToPrevStep,
  onClose,
  setIpfsHash,
  reason,
  setReason,
}) => {
  const classes = useStyles();

  const [continueDisabled, setContinueDisabled] = useState(false);

  const onStepComplete = useCallback(async () => {
    setContinueDisabled(true);
    const ipfsHash = await uploadChallengeReason(reason);
    setIpfsHash(ipfsHash);
    goToNextStep();
    setContinueDisabled(false);
  }, [goToNextStep, reason, setIpfsHash]);

  return (
    <Step
      stepIndex={1}
      currentStep={currentStep}
      header="Challenge Reason"
      subheader="Add a reason for the challenge."
      actions={
        <React.Fragment>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="text"
              color="primary"
              size="large"
              onClick={goToPrevStep}
            >
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="large"
              onClick={onStepComplete}
              disabled={reason === '' || continueDisabled}
            >
              Continue
            </Button>
          </Grid>
        </React.Fragment>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <p className={classes.txRowText}>
            Why are you challenging this point?
          </p>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            label="Reason"
            variant="outlined"
            multiline
            rows="3"
            value={reason}
            onChange={e => {
              e.preventDefault();
              setReason(e.target.value);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Step>
  );
};

const Step3 = ({
  account,
  currentStep,
  goToPrevStep,
  goToNextStep,
  reason,
  challenge,
  listingHash,
  ipfsHash,
  stakeAmount,
}) => {
  const classes = useStyles();
  const [lastTx, setLastTx] = useState(null);

  useEffect(() => {
    const unsubscribe = window.store.subscribe(() => {
      const { transactionStack, transactions } = window.store.getState();
      const key = transactionStack[transactionStack.length - 1];
      const tx = transactions[key];

      if (lastTx !== tx) setLastTx(tx);
    });

    return () => {
      unsubscribe();
    };
  });

  useEffect(() => {
    if (lastTx && lastTx.status === 'pending') goToNextStep();
  }, [lastTx, goToNextStep]);

  const challengeCall = useCallback(() => {
    // console.log('Calling...', challenge, listingHash, ipfsHash);
    const deposit = Number.parseInt(stakeAmount, 10) * 10 ** 18;
    challenge.cacheSend(listingHash, deposit.toString(), ipfsHash, {
      from: account,
    });
  }, [account, challenge, stakeAmount, listingHash, ipfsHash]);

  return (
    <Step
      stepIndex={2}
      currentStep={currentStep}
      header="Review challenge"
      subheader="Confirm your challenge details."
      actions={
        <React.Fragment>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="text"
              color="primary"
              size="large"
              onClick={goToPrevStep}
            >
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="large"
              target="_blank"
              onClick={challengeCall}
            >
              Continue
            </Button>
          </Grid>
        </React.Fragment>
      }
      noPadding
    >
      <TxDetailsWrapper>
        <TxDetailRow
          description="Challenge stake"
          value={`${stakeAmount} FOAM`}
          divider
        />
        <TxDetailRow description="Challenge reason" value={reason} />
      </TxDetailsWrapper>
    </Step>
  );
};

const Step4 = ({ currentStep, onClose }) => {
  const classes = useStyles();

  return (
    <Step
      stepIndex={3}
      currentStep={currentStep}
      header="Confirmation"
      subheader="You can close this dialog now"
      actions={
        <React.Fragment>
          <Grid item></Grid>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="large"
              target="_blank"
              onClick={onClose}
            >
              Close
            </Button>
          </Grid>
        </React.Fragment>
      }
      noPadding
    />
  );
};

const Steps = ({
  account,
  activeAddressBalanceOf,
  currentStep,
  goToNextStep,
  goToPrevStep,
  onClose,
  pointStake,
  challenge,
  listingHash,
  setIpfsHash,
  ipfsHash,
}) => {
  const [reason, setReason] = useState('');
  const [stakeAmount, setStakeAmount] = useState(50);

  return (
    <Grid item>
      {/* Step 1 */}
      <Step1
        activeAddressBalanceOf={activeAddressBalanceOf}
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        onClose={onClose}
        pointStake={pointStake}
        stakeAmount={stakeAmount}
        setStakeAmount={setStakeAmount}
      />

      {/* Step 2 */}
      <Step2
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        goToPrevStep={goToPrevStep}
        reason={reason}
        setReason={setReason}
        setIpfsHash={setIpfsHash}
        onClose={onClose}
      />

      {/* Step 3 */}
      <Step3
        account={account}
        currentStep={currentStep}
        goToNextStep={goToNextStep}
        goToPrevStep={goToPrevStep}
        reason={reason}
        challenge={challenge}
        listingHash={listingHash}
        ipfsHash={ipfsHash}
        stakeAmount={stakeAmount}
      />

      {/* Step 4 */}
      <Step4 currentStep={currentStep} onClose={onClose} />
    </Grid>
  );
};

const ChallengeDialog = ({ open, onClose: onCloseProp, ...props }) => {
  // Steps
  const steps = useMemo(() => {
    return ['Stake FOAM', 'Challenge reason', 'Review', 'Confirmation'];
  }, []);

  const [currentStep, setCurrentStep] = useState(0);
  const [ipfsHash, setIpfsHash] = useState(null);

  // Close dialog and reset current step
  const onClose = useCallback(() => {
    onCloseProp();
    setTimeout(() => {
      setCurrentStep(0);
      setIpfsHash(null);
    }, 200);
  }, [onCloseProp]);

  // Next Step
  const goToNextStep = useCallback(() => {
    const nextStepIndex = currentStep + 1;

    setCurrentStep(nextStepIndex);
  }, [currentStep]);

  const goToPrevStep = useCallback(() => {
    if (currentStep === 0) return onClose;

    const prevStepIndex = currentStep - 1;
    setCurrentStep(prevStepIndex);
  }, [onClose, currentStep]);

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      cancellable={currentStep < 3}
    >
      <FullScreenDialogContent>
        {/* Steps Wrapper */}
        <StepIndicators steps={steps} currentStep={currentStep} />

        {/* Steps Content */}
        <Steps
          currentStep={currentStep}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
          onClose={onClose}
          setIpfsHash={setIpfsHash}
          ipfsHash={ipfsHash}
          {...props}
        />
      </FullScreenDialogContent>
    </FullScreenDialog>
  );
};

const mapStateToProps = state => ({
  activeAddressBalanceOf: activeAddressBalanceOfSelector(state),
});

export default connect(mapStateToProps)(ChallengeDialog);
