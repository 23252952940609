import React from 'react';

// Material UI
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const InfoTooltip = ({ text }) => {
  return (
    <Tooltip title={text} placement="right">
      <InfoOutlinedIcon
        className="lowEmphasis"
        style={{ height: 18, width: 18, marginLeft: 4 }}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
