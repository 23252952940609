import React from 'react';

// Components
// import AccountWidget from './AccountWidget'

// Material UI
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import MUIStep from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// Styles
import { makeStyles } from '@material-ui/styles';
import InfoTooltip from './InfoTooltip';

export const dialogStyles = theme => ({
  dialogPaper: {
    backgroundColor: '#f9f9f9',
  },
  dialogContent: {
    margin: '0 auto',
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  stepperRoot: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  stepWrapper: {
    margin: `${theme.spacing(8)}px 0`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(5)}px 0`,
    },
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(3)}px 0`,
    },
  },
  stepPaper: {
    margin: `${theme.spacing(3)}px 0`,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    // padding: `6px ${theme.spacing(2)}px 12px`,
  },
  stepPaperNoPadding: {
    margin: `${theme.spacing(3)}px 0`,
  },
  actionGroup: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    padding: '12px 18px',
  },
  txDetails: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
  },
  txRowDivider: {
    borderBottom: '1px solid #dedede',
  },
  txRowText: {
    margin: 0,
  },
  accountBar: {
    display: 'flex',
    width: '100%',
    height: 100,
    marginTop: '0',
    marginRight: theme.spacing(2),
  },
  accountDetails: {
    marginLeft: 'auto',
    marginTop: 20,
    marginRight: 16,
  },
  accountAction: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500,
  },
});

const useStyles = makeStyles(dialogStyles);

export const StepIndicators = ({ steps, currentStep }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Stepper
        classes={{ root: classes.stepperRoot }}
        alternativeLabel
        activeStep={currentStep}
      >
        {steps.map((label, idx) => (
          <MUIStep key={label}>
            <StepLabel active={currentStep === idx}>{label}</StepLabel>
          </MUIStep>
        ))}
      </Stepper>
    </Grid>
  );
};

export const Step = ({
  stepIndex,
  currentStep,
  header,
  subheader,
  children,
  actions,
  noPadding,
  customLayout,
}) => {
  const classes = useStyles();

  return stepIndex !== currentStep ? null : (
    <Grid
      className={classes.stepWrapper}
      role="tabpanel"
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      {/* Step Header */}
      <Grid item>
        <Typography variant="h2" align="center">
          {header}
        </Typography>
        <Typography variant="body1" gutterBottom align="center" component="div">
          {subheader}
        </Typography>
      </Grid>

      {/* Step Content */}
      {children &&
        (!customLayout ? (
          <Grid item style={{ width: '100%' }}>
            <Paper
              className={
                noPadding ? classes.stepPaperNoPadding : classes.stepPaper
              }
              elevation={4}
            >
              {children}
            </Paper>
          </Grid>
        ) : (
          children
        ))}

      {/* Step Actions */}
      <Grid className={classes.actionGroup} item>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          {actions}
        </Grid>
      </Grid>
    </Grid>
  );
};

const AccountWidgetAction = ({ onClose, cancellable }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.accountAction}
      variant="caption"
      align="right"
      onClick={onClose}
    >
      {cancellable ? 'Cancel & Close' : 'Close'}
    </Typography>
  );
};

const FullScreenDialog = ({ open, onClose, children, cancellable = true }) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} fullScreen open={open}>
      <div className={classes.accountBar}>
        <div className={classes.accountDetails}>
          <AccountWidgetAction onClose={onClose} cancellable={cancellable} />
          {/* <AccountWidget
            ActionComponent={
              <AccountWidgetAction
                onClose={onClose}
                cancellable={cancellable}
              />
            }
          /> */}
        </div>
      </div>

      {children}
    </Dialog>
  );
};

export const FullScreenDialogContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.dialogContent}
      container
      direction="column"
      justify="center"
      wrap="nowrap"
    >
      {children}
    </Grid>
  );
};

export const TxDetailsWrapper = ({ children }) => {
  return <div>{children}</div>;
};

export const TxDetailRow = ({
  description,
  infoText,
  value,
  strikeValue,
  divider,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.txDetails} ${divider && classes.txRowDivider}`}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography className="lowEmphasis" variant="body1" component="span">
            {description}
          </Typography>
          {infoText && <InfoTooltip text={infoText} />}
        </div>
      </div>
      <Typography
        variant="body1"
        align="right"
        component="div"
        style={{ maxWidth: 400, wordBreak: 'break-word' }}
      >
        {strikeValue ? (
          <span style={{ textDecoration: 'line-through' }}>{strikeValue}</span>
        ) : null}{' '}
        {value}
      </Typography>
    </div>
  );
};

export default FullScreenDialog;
