import React from 'react';
import {
  Route,
  HashRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

// Pages

import Home from './pages/Home';
import Account from './pages/Account';
import Points from './pages/Points';
import Point from './pages/Point';
import Challenges from './pages/Challenges';
import Challenge from './pages/Challenge';
import Cartographers from './pages/Cartographers';
import Cartographer from './pages/Cartographer';
import Activity from './pages/Activity';
import Outcomes from './pages/Outcomes';
import Tokens from './pages/Tokens';

// Helper

import ScrollToTop from './components/ScrollTop';

export default props => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/points" component={Points} />
        <Route exact path="/points/:id" component={PointForListingHash} />
        <Route exact path="/challenges" component={Challenges} />
        <Route exact path="/challenges/:id" component={ChallengeForId} />
        <Route exact path="/cartographers" component={Cartographers} />
        <Route exact path="/cartographers/:id" component={CartographerForId} />
        <Route exact path="/stats/activity" component={Activity} />
        <Route exact path="/stats/outcomes" component={Outcomes} />
        <Route exact path="/stats/tokens" component={Tokens} />
        <Redirect to="/" />
      </Switch>
    </ScrollToTop>
  </Router>
);

function PointForListingHash({ match }) {
  return <Point listingHash={match.params.id} />;
}

function ChallengeForId({ match }) {
  return <Challenge challengeId={match.params.id} />;
}

function CartographerForId({ match }) {
  return <Cartographer address={match.params.id} />;
}
