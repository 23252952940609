// Utils
import contractConfigMap from 'core/contract/config';
import { utils as EtherUtils } from 'ethers';
import { isNil } from 'ramda';

export const displayTokensFromInt = tokenAmount =>
  tokenAmount.toLocaleString([], { maximumFractionDigits: 0 }) + ' FOAM';

// Displays formatted token balance with suffix
export const displayTokens = tokenAmount =>
  displayTokensInt(tokenAmount) + ' FOAM';

// Displayed formatted token balance with no suffix
export const displayTokensInt = tokenAmount =>
  isNil(tokenAmount)
    ? loadingValue
    : parseFloat(
        EtherUtils.formatUnits(
          tokenAmount, //.toString(),
          contractConfigMap.token.tokenDecimals
        )
      ).toLocaleString([], { maximumFractionDigits: 0 });

// placeholder to render when a value is nil
export const loadingValue = 'Loading...';
