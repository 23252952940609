// --- activeAddress

export const activeAddressChangedAction = 'network/active-address-changed';

// --- metamaskEnabled

export const metamaskEnabledActionRequest = 'network/metamask-enabled/request';
export const metamaskEnabledDispatchRequest = () => ({
  type: metamaskEnabledActionRequest,
});

export const metamaskEnabledActionStatus = 'network/metamask-enabled/status';

// --- metamaskInstalled

export const metamaskInstalledAction = 'network/metamask-installed';

// --- networkConnected

export const networkConnectedAction = 'network/connected';
