// React

import React, { Component, useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

// Material UI

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// Material UI Icons

import GlobeIcon from '@material-ui/icons/Public';

// Custom components

import EthereumAvatar from '../components/EthereumAvatar';
import Drawer from '../components/Drawer';
import ListCard from '../components/cards/ListCard';
import PointCard from '../components/cards/PointCard';
import StatCard from '../components/cards/StatCard';
import ChallengeStateCard from '../components/cards/ChallengeStateCard';

// Highcharts

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Annotations from 'highcharts/modules/annotations';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

Annotations(Highcharts);
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const Box = require('3box');

const FOAM_COLOR_VERIFY = '#28AF60';
const FOAM_COLOR_REMOVE = '#FF0000';
const FOAM_COLOR_UNREVEALED = '#AEAEAE';

function ListItemLink(props) {
  return <ListItem button component={Link} to={props.href} {...props} />;
}

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
      margin: '0 auto',
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
      margin: '0 auto',
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  chip: {
    paddingTop: 4,
    paddingBottom: 4,
    height: 26,
    alignItems: 'baseline',
  },
});

const HistoryRow = ({
  classes,
  index,
  item,
  pointOwner,
  challengeHistoryData,
  getStringForAction,
  getEtherscanForItem,
}) => {
  const [name, setName] = useState(`${item.user.slice(0, 6)}...`);

  useEffect(() => {
    if (!(item && item.user)) return;

    Box.getProfile(item.user).then(profile => {
      if (profile && profile.name) setName(profile.name);
    });
  }, [item]);

  return (
    <ListItemLink
      href={{ pathname: '/cartographers/' + item.user }}
      key={index}
    >
      <ListItemAvatar>
        <EthereumAvatar address={item.user} />
      </ListItemAvatar>
      <ListItemText
        secondary={new Date(item.timestamp).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      >
        <strong>{name}</strong>
        {' ' + getStringForAction(item.action, item.choice, item.result)}
        {item.user ===
        challengeHistoryData[challengeHistoryData.length - 1]['user'] ? (
          <Chip
            component="span"
            label="Challenger"
            className={classes.chip}
            style={{ marginLeft: 8 }}
          />
        ) : null}
        {item.user === pointOwner ? (
          <Chip
            component="span"
            label="Owner"
            className={classes.chip}
            style={{ marginLeft: 8 }}
          />
        ) : null}
      </ListItemText>

      <ListItemSecondaryAction
        style={{ marginRight: 16, textAlign: 'right' }}
        alignItems="flex-start"
      >
        {item.amount
          ? item.amount.toLocaleString([], {
              maximumFractionDigits: 0,
            }) + ' FOAM'
          : ''}
        <br />
        <IconButton
          color="default"
          size="small"
          aria-label="Etherscan"
          href={getEtherscanForItem(item)}
          target="_blank"
        >
          <GlobeIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemLink>
  );
};

class Challenge extends Component {
  constructor(props, { drizzle }) {
    super(props);
    this.state = {
      challengeData: [],
      challengeHistoryData: null,
      challengePlotData: [],
      pointData: [],
      listingHash: null,
      priceStat: null,
    };

    this.contracts = drizzle.contracts;
  }

  componentDidMount() {
    const passedState = this.props.location.state;

    if (passedState && passedState['data']) {
      this.setState({ pointData: passedState['data'] });
    }

    this.updateChallengeHistory();
    this.updateChallengeDetails();
    this.updatePriceData();
  }

  updateChallengeHistory() {
    const challengeId = this.props.challengeId;

    fetch(
      'https://api.blocklytics.org/foam/v0/challenges/' +
        challengeId +
        '/history?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          challengeHistoryData: data,
          challengePlotData: this.getPlotData(data),
        });
      });
  }

  updateChallengeDetails() {
    const challengeId = this.props.challengeId;
    const challengeHexId = `0x${parseInt(challengeId).toString(16)}`;

    this.pollMapDataKey = this.contracts.TCRVoting.methods.pollMap.cacheCall(
      challengeHexId
    );

    fetch(
      'https://map-api-direct.foam.space/challenge/' + challengeHexId + '/doc'
    )
      .then(response => response.json())
      .then(data => {
        const listingHash = data['metaData']
          ? data['metaData']['listingHash']
          : null;
        if (listingHash !== null) {
          this.updatePointDetails(listingHash);
        }
        this.setState({
          challengeData: data,
          listingHash: listingHash,
        });
      });
  }

  updatePointDetails(listingHash) {
    fetch('https://map-api-direct.foam.space/poi/details/' + listingHash)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          Promise.reject();
        }
      })
      .then(data => {
        if (data !== undefined) {
          this.setState({
            pointData: data,
          });
        }
      })
      .catch(
        fetch(
          'https://map-api-direct.foam.space/poi/removed/details/' + listingHash
        )
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            }
          })
          .then(data => {
            if (data !== undefined) {
              this.setState({
                pointData: data,
              });
            }
          })
      );
  }

  getPlotData(data) {
    const users = [
      ...new Set(
        data.map((item, index) => {
          return item.user;
        })
      ),
    ];

    return users.map((user, index) => {
      var userData = [0, 0, 0]; // revealFor, revealAgainst, Unrevealed

      data
        .filter(row => row.user === user)
        .some((item, index) => {
          if (item.action === 'reveal') {
            if (item.choice === 1) {
              userData = [item.amount, 0, 0];
              return true;
            } else {
              userData = [0, item.amount, 0];
              return true;
            }
          } else if (item.action === 'commit') {
            userData = [0, 0, item.amount];
            return true;
          }
          return false;
        });

      return { user: user, data: userData };
    });
  }

  getEtherscanForItem(item) {
    return 'https://etherscan.io/tx/' + item.transaction_hash;
  }

  getStringForAction(action, choice, result) {
    if (action === 'reveal') {
      if (choice === 1) {
        return 'voted to keep the point';
      } else {
        return 'voted to remove the point';
      }
    } else if (action === 'commit') {
      return 'committed vote';
    } else if (action === 'challenge') {
      return 'started challenge';
    } else if (action === 'finalized') {
      if (result === 'succeeded') {
        return 'Challenge against point succeeded';
      } else {
        return 'Challenge against point failed';
      }
    }
  }

  updatePriceData() {
    fetch(
      'https://api.blocklytics.org/foam/v0/price?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          priceStat: data,
        });
      });
  }

  componentDidUpdate = prevProps => {
    if (this.props.challengeId !== prevProps.challengeId) {
      // reset state
      this.setState({
        challengeData: [],
        challengeHistoryData: null,
        challengePlotData: [],
        pointData: [],
        listingHash: null,
        priceStat: null,
      });

      this.updateChallengeDetails();
      this.updateChallengeHistory();
      this.updatePriceData();
    }
  };

  render() {
    const { classes, accounts, contracts } = this.props;
    const {
      challengeData,
      challengeHistoryData,
      challengePlotData,
      listingHash,
      priceStat,
      pointData,
    } = this.state;

    const account = accounts && accounts[0];

    const pollMapData =
      contracts.TCRVoting.pollMap[this.pollMapDataKey] &&
      contracts.TCRVoting.pollMap[this.pollMapDataKey].value;

    const pointOwner = pointData['meta'] ? pointData['meta']['owner'] : null;

    const reason = challengeData['data']
      ? challengeData['data']['reason']
        ? challengeData['data']['reason']
        : '\u00a0'
      : null;
    const challengeStake = challengeData['contractData']
      ? parseInt(challengeData['contractData']['deposit'], 16) / 10 ** 18
      : null;
    const rewardPool = challengeData['contractData']
      ? (parseInt(challengeData['contractData']['deposit'], 16) / 10 ** 18) *
        0.4
      : null;

    const options = {
      lang: { decimalPoint: '.', thousandsSep: ',' },
      loading: {
        labelStyle: {
          cursor: 'default',
          fontSize: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
        },
        style: { backgroundColor: 'transparent' },
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      legend: { enabled: false },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        marginTop: 24, // Space for stackLabels
      },
      style: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' },
      title: { text: null },
      xAxis: {
        min: 0,
        max: 2,
        categories: ['Keep point', 'Remove point', 'Unrevealed'],
      },

      yAxis: {
        min: 0,
        title: null,

        stackLabels: {
          enabled: true,
          //           y: -24,
          formatter: function() {
            if (this.total === 0 || this.x > 1) {
              return '';
            }

            // 					  const votes = (this.total).toLocaleString([], { maximumFractionDigits: 0 })
            const revealedVotes = challengePlotData
              .map(row => row.data[0] + row.data[1])
              .reduce((a, b) => a + b, 0);
            const votePerc =
              ((100 * this.total) / revealedVotes).toLocaleString([], {
                maximumFractionDigits: 0,
              }) + '%';
            const quorum = 0.6;

            const winningString =
              '<span style="font-size: 32px; line-height: 32px;">👑</span><br/>' +
              votePerc;
            const losingString =
              '<span style="font-size: 18px; line-height: 32px;>' +
              votePerc +
              '</span><br/>(Below quorum)';

            if (this.x === 0) {
              if (this.total >= quorum * revealedVotes) {
                return winningString;
              } else {
                return losingString;
              }
            } else if (this.x === 1) {
              if (this.total > (1 - quorum) * revealedVotes) {
                return winningString;
              } else {
                return losingString;
              }
            }
          },
          style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray',
          },
        },
      },

      annotations: [
        {
          zIndex: 0,
          draggable: '',
          shapes: [
            {
              fill: 'none',
              stroke: '#dedede',
              strokeWidth: 2,
              type: 'path',
              points: [
                {
                  x: -0.3,
                  y:
                    0.6 *
                    challengePlotData
                      .map(row => row.data[0] + row.data[1] + row.data[2])
                      .reduce((a, b) => a + b, 0),
                  xAxis: 0,
                  yAxis: 0,
                },
                {
                  x: 0.3,
                  y:
                    0.6 *
                    challengePlotData
                      .map(row => row.data[0] + row.data[1] + row.data[2])
                      .reduce((a, b) => a + b, 0),
                  xAxis: 0,
                  yAxis: 0,
                },
              ],
            },
          ],
        },
      ],

      plotOptions: {
        column: {
          animation: {
            duration: 100,
          },
          borderWidth: 0,
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            formatter: function() {
              return this.total.toLocaleString([], {
                maximumFractionDigits: 0,
              });
            },
            color:
              (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          },
        },
      },
      series: challengePlotData.map((row, index) => {
        return {
          name: row.user,
          data: [
            { y: row.data[0], color: FOAM_COLOR_VERIFY },
            { y: row.data[1], color: FOAM_COLOR_REMOVE },
            { y: row.data[2], color: FOAM_COLOR_UNREVEALED },
          ],
        };
      }),
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        shadow: false,
        pointFormat: '{series.name}<br /><b>{point.y:.0f}</b><br/>',
      },

      // 	    {
      // 	    	data: [
      // 	        { y: challengePlotData[0], color: FOAM_COLOR_VERIFY },
      // 	        { y: challengePlotData[1], color: FOAM_COLOR_REMOVE },
      // 	        { y: challengePlotData[2], color: FOAM_COLOR_UNREVEALED },
      // 	    	]
      // 	    }
    };

    return (
      <Drawer pageTitle="Challenges" selectedSection={1} selectedIndex={1}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StatCard
                      label="Challenge stake"
                      note={
                        priceStat && challengeStake
                          ? 'Worth around $' +
                            (
                              Math.abs(challengeStake) * parseFloat(priceStat)
                            ).toLocaleString([], { maximumFractionDigits: 0 })
                          : '\u00A0'
                      }
                      stat={challengeStake}
                      suffix=" FOAM"
                      helpText="The challenger and point owner each have this amount of tokens at risk."
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StatCard
                      label="Reward pool"
                      note={
                        priceStat && rewardPool
                          ? 'Worth around $' +
                            (
                              Math.abs(rewardPool) * parseFloat(priceStat)
                            ).toLocaleString([], { maximumFractionDigits: 0 })
                          : '\u00A0'
                      }
                      stat={rewardPool}
                      suffix=" FOAM"
                      helpText="Voters on the winning side share these tokens. If there are no voters, the challenger receives this reward."
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ListCard label="Challenge reason">
                  <Typography variant="body1">
                    {reason || <Skeleton count={3} />}
                  </Typography>
                </ListCard>
              </Grid>

              <Grid item xs={12}>
                <ListCard label="Votes">
                  <Typography variant="body1">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      containerProps={{ className: 'chart' }}
                    />
                  </Typography>
                </ListCard>
              </Grid>

              <Grid item xs={12}>
                <ListCard label="History">
                  <List
                    style={{ marginLeft: -16, marginRight: -16 }}
                    alignItems="flex-start"
                  >
                    {challengeHistoryData ? (
                      challengeHistoryData.map((item, index) => (
                        <HistoryRow
                          classes={classes}
                          index={index}
                          item={item}
                          pointOwner={pointOwner}
                          challengeHistoryData={challengeHistoryData}
                          getStringForAction={this.getStringForAction}
                          getEtherscanForItem={this.getEtherscanForItem}
                        />
                      ))
                    ) : (
                      <ListItem>
                        <ListItemAvatar>
                          <EthereumAvatar />
                        </ListItemAvatar>
                        <ListItemText
                          primary=<Skeleton />
                          secondary=<Skeleton />
                        />
                      </ListItem>
                    )}
                  </List>
                </ListCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ChallengeStateCard
                  account={account}
                  listingHash={listingHash}
                  pollMapData={pollMapData}
                  helpText="The stake attests to the quality of information provided by the point owner. Anyone can challenge this point and try to remove it from the map."
                />
              </Grid>
              <Grid item xs={12}>
                <PointCard
                  variant="small"
                  listingHash={listingHash}
                  data={pointData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

Challenge.contextTypes = {
  drizzle: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    contracts: state.contracts,
    accounts: state.accounts,
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Challenge))
);
