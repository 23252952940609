import React from 'react';

// Material UI
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const HelpTooltip = ({ text, style }) => {
  return (
    <Tooltip title={text} placement="bottom" style={style}>
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );
};

export default HelpTooltip;
