// Redux
import * as networkActions from './actions';

// Utils
import { assocPath } from 'ramda';

export const initialState = Object.freeze({
  activeAddress: null,
  metamaskEnabled: null,
  metamaskInstalled: null,
  networkConnected: null,
});

export default function networkReducer(state = initialState, action) {
  switch (action.type) {
    case networkActions.networkConnectedAction: {
      return assocPath(['networkConnected'], action.payload, state);
    }

    case networkActions.activeAddressChangedAction: {
      return assocPath(['activeAddress'], action.payload, state);
    }

    case networkActions.metamaskEnabledActionStatus: {
      return assocPath(['metamaskEnabled'], action.payload, state);
    }

    case networkActions.metamaskInstalledAction: {
      return assocPath(['metamaskInstalled'], action.payload, state);
    }

    default:
      return state;
  }
}
