import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

// Material UI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Nav components

import Drawer from '../components/Drawer';

// Chart components

import PointsAddedRemoved from '../components/charts/PointsAddedRemoved';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  padded: {
    padding: '8px 32px 8px 16px',
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
});

class Activity extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      pointsAddedRemovedData: [],
      pointsAddedRemovedIsLoading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updatePointsAddedRemovedData();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  plotDataFor(data, key) {
    var plotData = [];
    Object.entries(data).forEach((d, i) => {
      var startValue = 0;
      var pushValue = startValue + parseFloat(d[1][key]);
      //       pushValue = pushValue === 0 ? null : pushValue
      const datetime = new Date(d[1]['date']).getTime();
      plotData.push([datetime, pushValue]);
    });
    return plotData;
  }

  plotDataForSeries(data, map) {
    var seriesData = [];
    if (data.length === 0) {
      return seriesData;
    }

    Object.keys(data[0]).forEach((key, index) => {
      if (map[key] && key !== 'date') {
        seriesData.push({
          name: map[key]['name'],
          type: 'area',
          data: this.plotDataFor(data, key),
          color: map[key]['color'],
        });
      }
    });
    return seriesData;
  }

  updatePointsAddedRemovedData() {
    this.setState({
      pointsAddedRemovedIsLoading: true,
    });

    fetch(
      'https://api.blocklytics.org/foam/v0/stats/activity/points-added-removed?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({
            pointsAddedRemovedData: data,
            pointsAddedRemovedIsLoading: false,
          });
        }
      });
  }

  render() {
    const { classes } = this.props;

    const pointsAdded = {
      isLoading: this.state.pointsAddedRemovedIsLoading,
      data: this.plotDataForSeries(this.state.pointsAddedRemovedData, {
        points_added: {
          name: 'Points Added',
          color: '#50b7f0',
        },
      }),
      exportingEnabled: false,
      chartTitle: 'Points Added',
      chartSubtitle: 'x',
    };

    const pointsRemoved = {
      isLoading: this.state.pointsAddedRemovedIsLoading,
      data: this.plotDataForSeries(this.state.pointsAddedRemovedData, {
        points_removed: {
          name: 'Points Removed',
          color: '#F37080',
        },
      }),
      exportingEnabled: false,
      chartTitle: 'Points Removed',
      chartSubtitle: 'x',
    };

    const challengesStarted = {
      isLoading: this.state.pointsAddedRemovedIsLoading,
      data: this.plotDataForSeries(this.state.pointsAddedRemovedData, {
        challenges_started: {
          name: 'Challenges Started',
          color: '#FEBE6C',
        },
      }),
      exportingEnabled: false,
      chartTitle: 'Challenges Started',
      chartSubtitle: 'x',
    };

    return (
      <Drawer pageTitle="Activity" selectedSection={2} selectedIndex={0}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <PointsAddedRemoved
                isLoading={pointsAdded.isLoading}
                data={pointsAdded.data}
                exportingEnabled={pointsAdded.exportingEnabled}
                chartTitle={pointsAdded.chartTitle}
                chartSubtitle={pointsAdded.chartSubtitle}
                creditsEnabled={false}
              />
              <PointsAddedRemoved
                isLoading={pointsRemoved.isLoading}
                data={pointsRemoved.data}
                exportingEnabled={pointsRemoved.exportingEnabled}
                chartTitle={pointsRemoved.chartTitle}
                chartSubtitle={pointsRemoved.chartSubtitle}
                creditsEnabled={false}
              />
              <PointsAddedRemoved
                isLoading={challengesStarted.isLoading}
                data={challengesStarted.data}
                exportingEnabled={challengesStarted.exportingEnabled}
                chartTitle={challengesStarted.chartTitle}
                chartSubtitle={challengesStarted.chartSubtitle}
                creditsEnabled={true}
              />
            </Paper>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(Activity));
