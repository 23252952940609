// Redux
import {
  call,
  fork,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { activeAddressSelector, metamaskEnabledSelector } from './selectors';
import * as networkActions from './actions';

// Utils
import logError from 'utils/log-error';
import { getDefaultProvider, providers as Providers } from 'ethers';
import Web3 from 'web3';
import { initOnboard, initNotify } from './services';
import { isNil, pathOr } from 'ramda';

import SwitchableProvider from './switchable-provider';

// --- Config

const accountPollingDelay = 3000; // 3 seconds

// --- State

// we will use this to get access to current account "signer"
export const networkState = {
  provider: null,
  onboard: null,
  notify: null,
  web3: null,
  address: null,
  network: null,
  balance: null,
};

// --- Utils

// - when Metamask is installed, then `window.ethereum` is available
// - when Metamask is unlocked & access has been granted to read addresses, its exposed here
const readActiveAddress = () => networkState.address;

// --- Sagas

function* watchActiveAddress() {
  while (true) {
    try {
      const activeAddress = yield call(readActiveAddress);
      const currentActiveAddress = yield select(activeAddressSelector);

      if (activeAddress !== currentActiveAddress) {
        yield put({
          type: networkActions.activeAddressChangedAction,
          payload: activeAddress,
        });
      }

      const metamaskUnlocked = yield select(metamaskEnabledSelector);

      // if we see an address, and were not unlocked, we are now unlocked
      if (!isNil(activeAddress) && !metamaskUnlocked) {
        yield put({
          type: networkActions.metamaskEnabledActionStatus,
          payload: true,
        });
      }

      // if we dont see an address, and were unlocked, we are now locked
      if (isNil(activeAddress) && metamaskUnlocked) {
        yield put({
          type: networkActions.metamaskEnabledActionStatus,
          payload: false,
        });
      }

      // if we dont see an address, and unlocked is nil, then we have just bootstrapped and dont
      // have access
      if (isNil(activeAddress) && isNil(metamaskUnlocked)) {
        yield put({
          type: networkActions.metamaskEnabledActionStatus,
          payload: false,
        });
      }
    } catch (error) {
      logError(error, 'watchActiveAddress::ERROR');
    }

    yield delay(accountPollingDelay);
  }
}

function* requestMetamaskAccess() {
  const select = yield call(networkState.onboard.walletSelect);

  if (select) {
    const check = yield call(networkState.onboard.walletCheck);

    if (check) {
      yield put({
        type: networkActions.metamaskEnabledActionStatus,
        payload: true,
      });
    } else {
      logError('Wallet check failed', 'requestMetamaskAccess::ERROR');

      yield put({
        type: networkActions.metamaskEnabledActionStatus,
        payload: false,
      });
    }
  } else {
    logError('Wallet select failed', 'requestMetamaskAccess::ERROR');

    yield put({
      type: networkActions.metamaskEnabledActionStatus,
      payload: false,
    });
  }

  /*
  if (isNil(window.ethereum)) return;

  try {
    // if access has been granted, then Metamask will return true without prompting user
    yield call(window.ethereum.enable);

    yield put({
      type: networkActions.metamaskEnabledActionStatus,
      payload: true,
    });
  } catch (error) {
    logError(error, 'requestMetamaskAccess::ERROR');

    yield put({
      type: networkActions.metamaskEnabledActionStatus,
      payload: false,
    });
  }
  */
}

function* monitorTransaction(tx) {
  if (networkState.notify) networkState.notify.hash(tx.txHash);
}

/*
function getProvider() {
  return new Promise(resolve => {
    // wait for document load event so Metamask, if present, has been able to inject web3
    window.addEventListener('load', async () => {
      let provider;

      if (isNil(window.web3)) {
        // in case there are "read-only" aspects of the web client, we will have the ability to
        // access data from mainnet, but never be able to sign transactions
        provider = getDefaultProvider('mainnet');
      } else {
        // use the same network settings as what Metamask has been configured with
        //
        // NOTE: Metamask refreshes the page when its network changed, meaning we bootstrap again
        // here and don't need to worry about "switching between networks"
        provider = new Providers.Web3Provider(window.web3.currentProvider);
        metamaskInstalled = true;
      }
      resolve({ provider });
    });
  });
}
*/

function* initializeNetwork() {
  const previouslySelectedWallet = window.localStorage.getItem(
    'selectedWallet'
  );

  if (previouslySelectedWallet && networkState.onboard) {
    const resp = yield call(
      networkState.onboard.walletSelect,
      previouslySelectedWallet
    );
  }

  yield put({
    type: networkActions.networkConnectedAction,
    payload: true,
  });

  // fake metamask installed action because wallets are handled by onboard and removing this breaks logic
  yield put({
    type: networkActions.metamaskInstalledAction,
    payload: true,
  });

  /*
  try {
    const { provider, metamaskInstalled } = yield call(getProvider);
    networkState.provider = provider;

    yield put({ type: networkActions.networkConnectedAction, payload: true });
    yield put({
      type: networkActions.metamaskInstalledAction,
      payload: metamaskInstalled,
    });
  } catch (error) {
    logError(error, 'initializeNetwork::ERROR');

    yield put({ type: networkActions.networkConnectedAction, payload: false });
  }
  */
}

function* initializeOnboard() {
  const onboard = yield call(initOnboard, {
    address: address => {
      networkState.address = address;
    },
    network: network => {
      networkState.network = network;
    },
    balance: balance => {
      networkState.balance = balance;
    },
    wallet: wallet => {
      if (wallet.provider) {
        networkState.provider = wallet.provider;
        networkState.web3 = new Web3(wallet.provider);

        SwitchableProvider.setExternalProvider(new Web3(wallet.provider));

        for (let i = 0; i < window.drizzle.contractList.length; i++) {
          window.drizzle.contractList[i].setProvider(wallet.provider);
        }

        // When the provider changes it automatically unsubscribes from block events, this is a hack to re-subscribe
        if (networkState.web3 && networkState.web3.eth)
          window.store.dispatch({
            type: 'BLOCKS_LISTENING',
            drizzle: window.drizzle,
            web3: networkState.web3,
            syncAlways: true,
          });

        window.localStorage.setItem('selectedWallet', wallet.name);
      }
    },
  });

  networkState.onboard = onboard;

  const notify = yield call(initNotify);

  networkState.notify = notify;
}

export default function* rootNetworkSaga() {
  yield takeLatest(
    networkActions.metamaskEnabledActionRequest,
    requestMetamaskAccess
  );

  yield takeEvery('TX_BROADCASTED', monitorTransaction);

  // NOTE: using `call` here to block sagas from starting until this function has finished
  yield call(initializeOnboard);
  yield call(initializeNetwork);

  yield fork(watchActiveAddress);
}
