import Web3 from 'web3';

export const readOnlyProviderProtocol = 'ws';
export const readOnlyProviderURL =
  'wss://mainnet.infura.io/ws/v3/8bd12c8360f246cb95dea5be6f34eaa2';

const createReadOnlyProvider = () => {
  const readOnlyProvider = new Web3.providers.WebsocketProvider(
    readOnlyProviderURL
  );

  return new Web3(readOnlyProvider);
};

class SwitchableProvider {
  static instance;
  provider = null;

  constructor() {
    if (SwitchableProvider.instance) {
      return SwitchableProvider.instance;
    }
    SwitchableProvider.instance = this;
    this.provider = createReadOnlyProvider();
  }

  async setExternalProvider(provider) {
    this.provider = provider;
  }

  create() {
    const web3RequestHandler = {
      get: (obj, prop) => {
        return this.provider[prop];
      },
      has: (obj, prop) => {
        return prop in this.provider;
      },
    };

    return new Proxy({}, web3RequestHandler);
  }
}

export default new SwitchableProvider();
