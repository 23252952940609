import React, { Component, useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import RemovePointIcon from '@material-ui/icons/LocationOff';
import GlobeIcon from '@material-ui/icons/Public';

import EthereumAvatar from '../../components/EthereumAvatar';
import Drawer from '../../components/Drawer';
import ListCard from '../../components/cards/ListCard';
import PointCard from '../../components/cards/PointCard';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { metamaskEnabledSelector } from 'core/network/selectors';
import { metamaskEnabledDispatchRequest } from 'core/network/actions';
import ChallengeDialog from './components/ChallengeDialog';
import SideListCards from './components/SideListCards';

const Box = require('3box');

var Geohash = require('latlon-geohash');

function ListItemLink(props) {
  return <ListItem button component={Link} to={props.href} {...props} />;
}

const FOAM_COLOR_REMOVE = '#FF0000';
const FOAM_COLOR_CHALLENGE = '#F47F67';

const styles = theme => ({
  content: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      // 600-960
      //       width: `calc(100% - 240px)`,
    },
    [theme.breakpoints.only('md')]: {
      // 960-1280
      maxWidth: 960 - 240,
    },
    [theme.breakpoints.only('lg')]: {
      // 1280-1920
      maxWidth: 1280 - 240,
    },
    [theme.breakpoints.up('xl')]: {
      // 1920+
      maxWidth: 1600, //1920 - 240,
    },
  },
  padded: {
    padding: '8px 32px 8px 16px',
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
  primaryActionButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  challenged: {
    color: FOAM_COLOR_CHALLENGE,
  },
  removePointAvatar: {
    color: '#FFFFFF',
    backgroundColor: FOAM_COLOR_REMOVE,
  },
});

const HistoryRow = ({
  classes,
  index,
  item,
  getHrefForAction,
  getStringForAction,
  getEtherscanForItem,
}) => {
  const [name, setName] = useState(
    item.user ? item.user.slice(0, 6) + '...' : ''
  );

  useEffect(() => {
    if (!(item && item.user)) return;

    Box.getProfile(item.user).then(profile => {
      if (profile && profile.name) setName(profile.name);
    });
  }, [item]);

  return (
    <ListItemLink
      href={getHrefForAction(item.action, item.user, item.challenge_id)}
      key={index}
    >
      <ListItemAvatar>
        {item.user ? (
          <EthereumAvatar address={item.user} />
        ) : (
          <Avatar className={classes.removePointAvatar}>
            <RemovePointIcon />
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        secondary={new Date(item.timestamp).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      >
        <strong>{name}</strong> {getStringForAction(item)}
      </ListItemText>
      <ListItemSecondaryAction style={{ marginRight: 16, textAlign: 'right' }}>
        {item.amount
          ? item.amount.toLocaleString([], {
              maximumFractionDigits: 0,
            }) + ' FOAM'
          : ''}
        <br />
        <IconButton
          color="default"
          size="small"
          aria-label="Etherscan"
          href={getEtherscanForItem(item)}
          target="_blank"
        >
          <GlobeIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemLink>
  );
};

class Point extends Component {
  _isMounted = false;

  constructor(props, { drizzle }) {
    super(props);
    this.state = {
      data: [],
      pointHistoryData: null,
      challengeData: null,
      challengeDialogOpen: false,
    };

    this.contracts = drizzle.contracts;

    this.canBeWhitelistedDataKey = this.contracts.Registry.methods.canBeWhitelisted.cacheCall(
      this.props.listingHash
    );

    this.listingDataKey = this.contracts.Registry.methods.listings.cacheCall(
      this.props.listingHash
    );

    this.challenge = this.contracts.Registry.methods.challenge;
  }

  componentDidMount() {
    this._isMounted = true;
    const passedState = this.props.location.state;

    if (passedState && passedState['data']) {
      this.setState({ data: passedState['data'] });
    } else {
      console.log('Updating point data');
      this.updatePointData();
    }

    this.updatePointHistoryData();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  updatePointHistoryData() {
    const listingHash = this.props.listingHash;

    fetch(
      'https://api.blocklytics.org/foam/v0/points/' +
        listingHash +
        '/history?key=AIzaSyDPB_eu04ayTVWnKPceehD2-H1BfAjkzBQ'
    )
      .then(response => response.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({
            pointHistoryData: data,
          });
        }
      });
  }

  updatePointData() {
    const listingHash = this.props.listingHash;

    fetch('https://map-api-direct.foam.space/poi/details/' + listingHash)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          throw new Error('Point not found');
        }
      })
      .then(data => {
        if (data !== undefined) {
          if (this._isMounted) {
            this.setState({ data: data });
          }
        }
      })
      .catch(error =>
        fetch(
          'https://map-api-direct.foam.space/poi/removed/details/' + listingHash
        )
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              return response.json();
            } else {
              Promise.reject();
            }
          })
          .then(data => {
            if (data !== undefined) {
              if (this._isMounted) {
                this.setState({ data: data });
              }
            }
          })
      );
  }

  updateChallengeData(challengeHexId) {
    this.pollMapDataKey = this.contracts.TCRVoting.methods.pollMap.cacheCall(
      challengeHexId
    );

    fetch(
      'https://map-api-direct.foam.space/challenge/' + challengeHexId + '/doc'
    )
      .then(response => response.json())
      .then(data => {
        this.setState({
          challengeData: data,
        });
      });
  }

  getStringForAction(item) {
    const action = item.action;
    const challenge_id = item.challenge_id;
    const result = item.result;
    const amount = item.amount;

    if (action === 'pointAdded') {
      return 'added point';
    } else if (action === 'challengeStarted') {
      return 'started challenge #' + challenge_id;
    } else if (action === 'challengeFinalized') {
      if (result === 'succeeded') {
        return 'Challenge #' + challenge_id + ' against point succeeded';
      } else if (result === 'failed') {
        return 'Challenge #' + challenge_id + ' against point failed';
      }
    } else if (action === 'pointRemoved') {
      return 'Point removed';
    } else if (action === 'pointDeposit') {
      if (amount > 0) {
        return 'boosted stake';
      } else if (amount < 0) {
        return 'withdrew stake';
      }
    }
  }

  getEtherscanForItem(item) {
    return 'https://etherscan.io/tx/' + item.transaction_hash;
  }

  getHrefForAction(action, user, challenge_id) {
    if (action === 'challengeStarted' || action === 'challengeFinalized') {
      return '/challenges/' + challenge_id;
    } else if (action === 'pointRemoved') {
      return null;
    } else {
      return '/cartographers/' + user;
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.listingHash !== prevProps.listingHash) {
      if (this._isMounted) {
        // reset state
        this.setState({
          data: [],
          pointHistoryData: null,
          challengeData: null,
        });

        this.updatePointData();
        this.updatePointHistoryData();
      }
    }

    var canBeWhitelisted;

    if (
      this.canBeWhitelistedDataKey in
      this.props.contracts.Registry.canBeWhitelisted
    )
      canBeWhitelisted = this.props.contracts.Registry.canBeWhitelisted[
        this.canBeWhitelistedDataKey
      ].value;

    var listing;

    if (this.listingDataKey in this.props.contracts.Registry.listings)
      listing = this.props.contracts.Registry.listings[this.listingDataKey]
        .value;

    if (
      canBeWhitelisted !== this.state.canBeWhitelisted ||
      listing !== this.state.listing
    )
      this.setState({
        canBeWhitelisted,
        listing,
      });
  };

  /** Calls Registry method updateStatus
   *
   * @dev Listing hash is provided from prop
   */
  handleVerify = () => {
    this.contracts.Registry.methods.updateStatus.cacheSend(
      this.props.listingHash,
      {
        from: this.props.accounts[0],
      }
    );
  };

  /** Calls Registry method exit
   *
   * @dev This method withdraws all tokens back to the owner and removes the point.
   * @dev Listing hash is provided from prop
   */
  handleRemove = () => {
    this.contracts.Registry.methods.exit.cacheSend(this.props.listingHash, {
      from: this.props.accounts[0],
    });
  };

  setChallengeDialogOpen = open => {
    this.setState({ challengeDialogOpen: open });
  };

  render() {
    const { classes, accounts, listingHash, contracts } = this.props;
    const {
      listing,
      data,
      pointHistoryData,
      challengeData,
      challengeDialogOpen,
    } = this.state;

    const account = accounts && accounts[0];

    const pollMapData =
      contracts.TCRVoting.pollMap[this.pollMapDataKey] &&
      contracts.TCRVoting.pollMap[this.pollMapDataKey].value;

    var challengeReason;
    if (challengeData && challengeData['data']) {
      challengeReason = challengeData['data']['reason'] || '\u00a0';
    }

    var pointStake, challengeStake;
    if (data['state']) {
      pointStake = data['state']['deposit']
        ? parseInt(data['state']['deposit']) / 10 ** 18
        : 0;

      if (data['state']['status']) {
        if (data['state']['status']['challenge'] && challengeData === null) {
          this.updateChallengeData(data['state']['status']['challenge']);
        }

        if (data['state']['status']['deposit']) {
          challengeStake = data['state']['status']['deposit']
            ? parseInt(data['state']['status']['deposit']) / 10 ** 18
            : 0;
          pointStake += challengeStake;
        }
      }
    }

    var primaryHref = 'https://map.foam.space/#/dashboard/';
    if (data['state'] && !data['state']['status']) primaryHref += '/removed';
    primaryHref += listingHash;

    var pointGeohash;
    if (data['data']) {
      pointGeohash = data['data']['geohash'];
    } else if (data['doc']) {
      pointGeohash = data['doc']['data']['geohash'];
    }

    if (pointGeohash) {
      const decoded_geohash = Geohash.decode(pointGeohash);
      const lat = decoded_geohash['lat'];
      const long_ = decoded_geohash['lon'];
      primaryHref += '/?lng=' + long_ + '&lat=' + lat + '&zoom=15';
    }

    return (
      <Drawer pageTitle="Points" selectedSection={1} selectedIndex={0}>
        <Grid
          container
          className={classes.content}
          spacing={2}
          direction="row-reverse"
        >
          <Grid item xs={12} sm={4}>
            <SideListCards
              classes={classes}
              account={account}
              listing={listing}
              handleVerify={this.handleVerify}
              handleChallenge={() => {
                this.setChallengeDialogOpen(true);
              }}
              handleRemove={this.handleRemove}
              primaryHref={primaryHref}
              pollMapData={pollMapData}
              pointStake={pointStake}
              challengeStake={challengeStake}
              challengeReason={challengeReason}
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PointCard data={data} listingHash={listingHash} />
              </Grid>
              <Grid item xs={12}>
                <ListCard label="History">
                  <List style={{ marginLeft: -16, marginRight: -16 }}>
                    {pointHistoryData ? (
                      pointHistoryData.map((item, index) => (
                        <HistoryRow
                          classes={classes}
                          index={index}
                          item={item}
                          getHrefForAction={this.getHrefForAction}
                          getStringForAction={this.getStringForAction}
                          getEtherscanForItem={this.getEtherscanForItem}
                        />
                      ))
                    ) : (
                      <ListItem>
                        <ListItemAvatar>
                          <EthereumAvatar />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Skeleton />}
                          secondary={<Skeleton />}
                        />
                      </ListItem>
                    )}
                  </List>
                </ListCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ChallengeDialog
          account={account}
          open={challengeDialogOpen}
          onClose={() => {
            this.setChallengeDialogOpen(false);
          }}
          challenge={this.challenge}
          listingHash={listingHash}
          pointStake={pointStake}
        />
      </Drawer>
    );
  }
}

Point.contextTypes = {
  drizzle: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    drizzleStatus: state.drizzleStatus,
    web3: state.web3,
    contracts: state.contracts,
    accounts: state.accounts,
    metamaskEnabled: metamaskEnabledSelector(state),
  };
};

const mapDispatchToProps = {
  metamaskEnabledDispatchRequest,
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Point)));
