import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import ipfsClient from 'ipfs-http-client';

import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import OpenIcon from '@material-ui/icons/OpenInNew';

import EthereumAvatar from '../EthereumAvatar';

// 3Box
const Box = require('3box');
// const { idUtils } = require('3box');
// const verifier = require('3box/');
const hideTwitterField = false;

const useStyles = makeStyles(theme => ({
  mediumEmphasis: {
    color: 'rgba(0,0,0,0.6)',
  },
  lowEmphasis: {
    color: 'rgba(0,0,0,0.38)',
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
  },
  iconSmall: {
    fontSize: 16,
  },
  statItem: {
    lineHeight: 1,
  },
  card: {
    marginTop: 50,
    overflow: 'visible',
    position: 'relative',
  },
  cardHeaderOverride: {
    paddingBottom: 0,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    position: 'absolute',
    top: 23,
    right: 20,
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 9px)',
    top: 9,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  stepper: {
    padding: '0 16px 16px 16px',
  },
  textCard: {
    maxWidth: 350,
    margin: `${theme.spacing(2)}px auto`,
    padding: theme.spacing(1),
    border: `1px ${theme.palette.primary.main} solid`,
    borderRadius: 12,
    color: theme.palette.primary.main,
  },
  textCardText: {
    overflowWrap: 'anywhere',
  },
  stepperText: {
    marginBottom: theme.spacing(1),
  },
  dialog: {
    width: 500,
    height: 330,
  },
}));

const ipfs = ipfsClient({
  host: 'ipfs.infura.io',
  port: '5001',
  protocol: 'https',
});

const EditProfileForm = ({
  did,
  profile,
  twitterHandler: propTwitterHandler,
  address,
  onSave,
  onCancel,
  actionInProgress,
}) => {
  const classes = useStyles();

  const twitterHandler = useMemo(() => {
    return propTwitterHandler || '';
  }, []);

  const [name, setName] = useState(profile['name']);
  const [image, setImage] = useState(profile['image']);
  const [description, setDescription] = useState(profile['description']);
  const [location, setLocation] = useState(profile['location']);
  const [website, setWebsite] = useState(profile['website']);
  const [twitter, setTwitter] = useState(twitterHandler);
  const [proofTwitter, setProofTwitter] = useState(null);
  const [proofTwitterHelperText, setProofTwitterHelperText] = useState(null);
  const [shouldVerifyTwitterHandler, setShouldVerifyTwitterHandler] = useState(
    false
  );
  const [verifyTwitterDialogOpen, setVerifyTwitterDialogOpen] = useState(false);
  const [twitterVerActiveStep, setTwitterVerActiveStep] = useState(0);

  const [uploadingPic, setUploadingPic] = useState(false);
  const fileInputRef = useRef();

  const uploadFile = useCallback(() => {
    if (!fileInputRef.current) return;

    setUploadingPic(true);
    const inputFile = fileInputRef.current.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(inputFile);
    reader.onloadend = async () => {
      const buffer = await Buffer.from(reader.result);
      ipfs.add(buffer, (err, result) => {
        if (err) {
          setUploadingPic(false);
          console.log(
            'There was an error while uploading profile picture',
            err
          );
        } else {
          const imgObj = [
            {
              '@type': 'ImageObject',
              contentUrl: {
                '/': result[0].hash,
              },
            },
          ];
          setImage(imgObj);
          setUploadingPic(false);
        }
      });
    };
  }, [setImage, fileInputRef]);

  useEffect(() => {
    setShouldVerifyTwitterHandler(twitterHandler !== twitter);
  }, [twitter, profile]);

  const verifyTwitterHandler = useCallback(() => {
    fetch('https://verifications.3box.io/twitter', {
      method: 'post',
      body: JSON.stringify({ did, twitter_handle: twitter }),
      mode: 'cors',
    })
      .then(response => response.json())
      .then(response => {
        if (response && response.status === 'success') {
          setProofTwitterHelperText(null);
          setProofTwitter(response.data.verification);
          setShouldVerifyTwitterHandler(false);
        } else {
          setProofTwitterHelperText('Verification failed');
        }
      });
  }, [did, twitter]);

  return (
    <React.Fragment>
      <CardHeader
        avatar={
          <EthereumAvatar
            address={address}
            profile={{ ...profile, image }}
            isBig
            edit
          />
        }
        title={
          <div>
            <input
              ref={ref => (fileInputRef.current = ref)}
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={uploadFile}
            />
            <Button
              color="primary"
              onClick={e => {
                fileInputRef.current && fileInputRef.current.click();
              }}
              disabled={actionInProgress || uploadingPic}
            >
              {uploadingPic ? 'Uploading...' : 'Upload File'}
            </Button>
          </div>
        }
        className={classes.cardHeaderOverride}
      />
      <CardContent className={classes.cardContent}>
        <TextField
          id="name-input"
          label="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          margin="normal"
        />
        <TextField
          id="description-input"
          label="Description"
          multiline
          value={description}
          onChange={e => setDescription(e.target.value)}
          margin="normal"
        />
        <TextField
          id="location-input"
          label="Location"
          value={location}
          onChange={e => setLocation(e.target.value)}
          margin="normal"
        />
        <TextField
          id="website-input"
          label="Website"
          value={website}
          onChange={e => setWebsite(e.target.value)}
          margin="normal"
        />
        {!hideTwitterField && (
          <div style={{ position: 'relative' }}>
            <TextField
              id="twitter-input"
              label="Twitter"
              value={twitter}
              onChange={e => setTwitter(e.target.value)}
              margin="normal"
              fullWidth
              helperText={
                shouldVerifyTwitterHandler ? 'Verification is required.' : null
              }
              error={shouldVerifyTwitterHandler}
              disabled={!!proofTwitter}
            />
            {shouldVerifyTwitterHandler && (
              <Button
                color="primary"
                style={{ position: 'absolute', right: 0, top: 28, padding: 3 }}
                onClick={e => setVerifyTwitterDialogOpen(true)}
              >
                Verify
              </Button>
            )}
          </div>
        )}
      </CardContent>
      <CardActions>
        <Button
          style={{ position: 'relative' }}
          color="primary"
          onClick={e => {
            e.preventDefault();
            onSave({
              name,
              description,
              location,
              website,
              proofTwitter,
              twitter,
              image,
            });
          }}
          disabled={
            actionInProgress || uploadingPic || shouldVerifyTwitterHandler
          }
        >
          {!actionInProgress ? 'Save' : 'Saving...'}
          {actionInProgress && (
            <CircularProgress className={classes.progress} size={18} />
          )}
        </Button>
        <Button
          color="primary"
          onClick={e => {
            e.preventDefault();
            onCancel();
          }}
          disabled={actionInProgress || uploadingPic}
        >
          Cancel
        </Button>
      </CardActions>

      <Dialog
        PaperProps={{ className: classes.dialog }}
        open={verifyTwitterDialogOpen}
        onClose={() => setVerifyTwitterDialogOpen(false)}
      >
        <DialogTitle>
          {'Verify your Twitter account'}{' '}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setVerifyTwitterDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper
            activeStep={twitterVerActiveStep}
            className={classes.stepper}
          >
            <Step key={0}>
              <StepLabel>Tweet</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>Verify</StepLabel>
            </Step>
          </Stepper>
          {twitterVerActiveStep === 0 ? (
            <React.Fragment>
              <Typography className={classes.stepperText}>
                Tweet a unique key from the account you want to connect
              </Typography>
              <div className={classes.textCard}>
                <Typography className={classes.textCardText}>{did}</Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=This%20Tweet%20links%20my%20Twitter%20account%20to%20my%203Box%20profile%20from%20foam.tools!%20%20%20%0D%0A%0D%0Ahttps://3box.io/${address}%20%20%0D%0A%20%0D%0A%E2%9C%85%20%20%0D%0A${did}%20%20%0D%0A%E2%9C%85`}
              >
                Tweet this
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography className={classes.stepperText}>
                Check if your Twitter account was successfully verified below!
              </Typography>
              <br />
              <Button
                variant="contained"
                onClick={e => verifyTwitterHandler()}
                disabled={proofTwitter}
                color="primary"
              >
                {proofTwitter ? 'Verified' : 'Verify'}
              </Button>
              {proofTwitterHelperText && (
                <FormHelperText error>{proofTwitterHelperText}</FormHelperText>
              )}
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {twitterVerActiveStep === 0 && (
            <Button
              onClick={e => setTwitterVerActiveStep(1)}
              color="primary"
              autoFocus
            >
              Next
            </Button>
          )}
          {twitterVerActiveStep === 1 && (
            <React.Fragment>
              <Button onClick={e => setTwitterVerActiveStep(0)} color="primary">
                Back
              </Button>
              <Button
                disabled={shouldVerifyTwitterHandler}
                onClick={() => setVerifyTwitterDialogOpen(false)}
                color="primary"
                autoFocus
              >
                Done
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const ProfileCard = ({
  address,
  profile,
  accounts,
  updateProfile,
  optimisticUpdateProfile,
  editable,
}) => {
  const classes = useStyles();
  const no3BoxProfile = useMemo(() => {
    return profile && Object.keys(profile).length === 0;
  }, [profile]);

  const { title, subheader, description, website } = useMemo(() => {
    if (!profile)
      return { title: null, subheader: null, description: null, website: null };
    else if (Object.keys(profile).length === 0)
      return {
        title: 'Anonymous Account',
        subheader: address,
        description: 'The owner of this account has not added a profile.',
        website: null,
      };
    else
      return {
        title: profile['name'] ? profile['name'] : 'Anonymous Account',
        subheader: profile['location'] ? profile['location'] : address,
        description: profile['description'] ? profile['description'] : '\u00A0',
        website: profile['website'] ? profile['website'] : null,
      };
  }, [profile, address]);

  const [twitter, setTwitter] = useState(
    accounts && accounts['twitter'] && accounts['twitter']['username']
      ? accounts['twitter']['username']
      : null
  );
  useEffect(() => {
    setTwitter(
      accounts && accounts['twitter'] && accounts['twitter']['username']
        ? accounts['twitter']['username']
        : null
    );
  }, [accounts]);

  const primaryHref = useMemo(
    () => (address ? 'https://3box.io/' + address : null),
    [address]
  );
  const secondaryHref = useMemo(
    () => (address ? 'https://etherscan.io/address/' + address : null),
    [address]
  );
  const twitterHref = useMemo(
    () => (twitter ? 'https://twitter.com/' + twitter : null),
    [twitter]
  );
  const websiteHref = useMemo(() => (website ? 'https://' + website : null), [
    website,
  ]);

  const [showEditButton, setShowEditButton] = useState(false);

  useEffect(() => setShowEditButton(editable && !!primaryHref), [
    primaryHref,
    editable,
  ]);

  const [actionInProgress, setActionInProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [box, setBox] = useState(null);

  const [snackbarState, setSnackbarState] = useState({ open: false });

  const switchToEditMode = () => {
    setActionInProgress(true);
    Box.openBox(address, window.ethereum)
      .then(box => {
        setActionInProgress(false);
        setBox(box);
        setEditMode(true);
      })
      .catch(e => {
        setActionInProgress(false);
        setSnackbarState({
          variant: 'error',
          message: `There was an error: ${e.message}`,
          open: true,
        });
        console.error(
          'Error while requesting permission to edit profile:',
          e.message
        );
      });
  };

  const saveProfile = ({ proofTwitter, twitter, ...newValues }) => {
    setActionInProgress(true);

    let fields = [];
    let values = [];
    let fieldsToRemove = [];

    for (let [key, value] of Object.entries(newValues)) {
      if (!profile || profile[key] !== value) {
        if (value) {
          fields.push(key);
          values.push(value);
        } else fieldsToRemove.push(key);
      }
    }

    if (proofTwitter) {
      fields.push('proof_twitter');
      values.push(proofTwitter);
    }

    box.public.setMultiple(fields, values).then(success => {
      fieldsToRemove.forEach(async field => {
        await box.public.remove(field);
      });

      if (!success) {
        setSnackbarState({
          variant: 'error',
          message: `There was an error while saving changes`,
          open: true,
        });
        setActionInProgress(false);

        return;
      }

      box.syncDone.then(r => {
        setSnackbarState({
          variant: 'info',
          message: `Changes saved`,
          open: true,
        });
        setActionInProgress(false);
        setEditMode(false);

        proofTwitter && setTwitter(twitter);
        optimisticUpdateProfile(newValues);

        box.close();
        setBox(null);
      });
    });
  };

  const cancelEdition = () => setEditMode(false);

  return (
    <Card className={classes.card}>
      {!editMode ? (
        <React.Fragment>
          <CardHeader
            avatar={
              <EthereumAvatar address={address} profile={profile} isBig />
            }
            className={classes.cardHeaderOverride}
          />
          {profile && showEditButton && (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={switchToEditMode}
                disabled={actionInProgress}
              >
                {no3BoxProfile ? 'Create a profile' : 'Edit profile'}
              </Button>
              {actionInProgress && (
                <CircularProgress className={classes.progress} size={18} />
              )}
            </div>
          )}
          <CardContent>
            <Typography variant="h4">
              {title || <Skeleton width={400} />}
            </Typography>
            <Typography
              variant="body1"
              className={classes.lowEmphasis}
              paragraph
            >
              {subheader || <Skeleton width={300} />}
            </Typography>
            <Typography variant="body1" paragraph>
              {description || <Skeleton count={3} />}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              href={twitterHref}
              target="_blank"
              disabled={twitterHref ? false : true}
            >
              Twitter
              <OpenIcon
                className={classNames(classes.rightIcon, classes.iconSmall)}
              />
            </Button>
            <Button
              color="primary"
              href={websiteHref}
              target="_blank"
              disabled={websiteHref ? false : true}
            >
              Web
              <OpenIcon
                className={classNames(classes.rightIcon, classes.iconSmall)}
              />
            </Button>
            {/*<Button
              color="primary"
              href={primaryHref}
              target="_blank"
              disabled={primaryHref ? false : true}
            >
              3Box
              <OpenIcon
                className={classNames(classes.rightIcon, classes.iconSmall)}
              />
            </Button>*/}
            <Button
              color="primary"
              href={secondaryHref}
              target="_blank"
              disabled={secondaryHref ? false : true}
            >
              Etherscan
              <OpenIcon
                className={classNames(classes.rightIcon, classes.iconSmall)}
              />
            </Button>
          </CardActions>
        </React.Fragment>
      ) : (
        <EditProfileForm
          did={box && box.DID}
          profile={profile}
          twitterHandler={twitter}
          address={address}
          onSave={saveProfile}
          onCancel={cancelEdition}
          actionInProgress={actionInProgress}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key="profile-snackbar"
        open={snackbarState.open}
        onClose={() => setSnackbarState({ open: false })}
        autoHideDuration={4000}
        variant="info"
        message={snackbarState.message}
      />
    </Card>
  );
};

export default ProfileCard;
